import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function DisclaimerCompo(props) {
    return (
        <div>
            <Modal
                size="lg"
                show={props.disclamerModal}
                onHide={() => props.setDisclamerModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton className='py-1'>
                    <Modal.Title id="example-modal-sizes-title-sm">Disclaimer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={props.disclamer}
                                onReady={ editor => {} }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    props.setDisclamer(data);
                                    // console.log( { event, editor, data } );
                                } }
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="p-1 justify-content-start">
                        <button onClick={()=>props.setDisclamerModal(false)} className="btn btn-light btn-sm">Close</button>
                        <button onClick={()=>props.disclaimerApiSave()} className="btn btn-primary btn-sm">Update</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default DisclaimerCompo;