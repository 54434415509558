import React, { useEffect, useState } from "react";
import { Outlet, Link, Route, Routes } from "react-router-dom";
import Page from './page';
import Category from './category';
import SubCategory from './subCategory';
import LastCategory from './lastCategory';
import Brand from './brand';
import Size from './size';

function Master() {  
  useEffect (() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* <Routes>   
        <Route exact index path="/*" element={<Page />} />
        <Route exact index path="/category" element={<Category />} />
        <Route exact index path="/sub_category" element={<SubCategory />} />
        <Route exact index path="/last_category" element={<LastCategory />} />
        <Route exact index path="/brands" element={<Brand />} />
        <Route exact index path="/sizes" element={<Size />} />
      </Routes> */}
      <Outlet />
    </div>

  );
}

export default Master;
