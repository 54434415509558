import React, { useEffect, useState } from "react";
import { Modal, Form, Button} from 'react-bootstrap';

function ChangeStatusModal({show,handleClose}) {
    console.log(show);

    return (
        <>
            <Modal size="sm" show={show} onHide={handleClose}>
                <Modal.Header style={{borderBottom:'none'}} closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Status</Form.Label>
                            <Form.Select aria-label="Default select example">
                                <option value="1">In-Transit</option>
                                <option value="2">Delivered</option>
                                <option value="3">Cancelled</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Status Information</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>handleClose()}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={()=>handleClose()}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal> 
        </>
    );
}

export default ChangeStatusModal;