import React, { useEffect, useState } from "react";
import { Outlet, Link , useLocation } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Loader from '../../component/loader';
import { BiCategory, BiFilterAlt } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import { decrpt } from "../../component/encryption";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Pagination from "react-js-pagination";

function FilterDetails() {  
  const [loader,setLoader] = useState(false);
  const [show,setShow] = useState(false);
  const [dshow,setDShow] = useState(false);
  const [Eshow,setEShow] = useState(false);
  const [paginate , setPaginate] = useState();
  const [ toastText,setToastText ] = useState('');
  const [ subAddFilter , setSubAddFilter ]  = useState();
  const [ subEditFilter , setSubEditFilter ]  = useState();
  const [editShowModal , setEditShowModal ] = useState();
  const [editSubList , setEditSubList] = useState();
  const location = useLocation();
  console.log(location , "location");

let itemID = location.state && location.state.item;
let subFilterlist = location.state && location.state.item.sub_filter;
  const handleOpen = (item) => {
    setShow(true);
    console.log(subAddFilter ,'item');
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleCloseDelete = () => {setDShow(false);};

  const editSub = (item) => {
    setEditShowModal(true);
    setEditSubList(item);
    console.log(editSubList , 'editSubList');
  };

  const handleCloseEdit = () => {
    setEShow(false);
    setEditShowModal(false);
  };
  const subFilterStatus = async(item) => {
    setLoader(true);
    try {
      let newstatus = '';
      if (item.status == 1) {
        newstatus = 2;
      } else {
        newstatus = 1;
      }
      const data = {
        id: item.id,
        status  : newstatus
      }
      console.log(data , 'data of api getc')
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
      const response = await axios.post(`admin/master/filter/details/status` , data)
      .then((response) => {
        // console.log(response.data , 'categoryStatus api fetch');
        let tmparray = location.state.item.sub_filter;
        let obj = tmparray.find((o, i) => {
            if (o.id === item.id) {
              tmparray[i] = {...tmparray[i] ,   status : newstatus  };
              console.log('true',tmparray[i])
                return true; 
            }
        });
      })
      setToastText("Status Updated");
      toast.success(toastText,{hideProgressBar:true})
    setLoader(false);
    }catch(error) {
        console.log(error, 'error');
        setLoader(false);
    }
  }

  const addSubFilter = async() => {
    setLoader(true);
    try {
    const data = {
      filter_master_id : itemID.id,
      name              : subAddFilter.name
    }
    console.log(data, 'data check');
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
    const response = await axios.post(`admin/master/filter/details/save` , data )
    .then((response) => {
      // setSubAddFilter(response.data.data);
      subFilterlist.unshift(response.data.data);
      console.log(response.data.data , 'error')
      setLoader(false);
      setShow(false);
    })
  }catch(error){
        console.log(error , 'error')
    }
  }

  const editSubFilter = async() => {
    setLoader(true);
    const data = {
      id : editSubList.id,
      filter_master_id : editSubList.filter_master_id ,
      name : subEditFilter.name
    }
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
    const response = await axios.post(`admin/master/filter/details/save` , data )
    .then((response) => {
      if(response.data.success){
        let  lastResponse = response.data.data;
        let tmparray = subFilterlist;
        let obj = tmparray.find((o, i) => {
          if (o.id) {
            tmparray[i] = {...subFilterlist[i] , ...lastResponse };
            console.log('true',tmparray[i])
              return true; 
          }
        });
        setLoader(false);
      }
    })
  }
  return (
    <div className="main-content">
      {loader ? 
        <Loader />
        :
        <>
      <ToastContainer position="top-center"  autoClose={3000}  hideProgressBar={false}  newestOnTop={false}  closeOnClick  rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'colored'}/>      
          <div className="section">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                  <h5><BiCategory className="me-1" />Sub Filter Details</h5>
                </div>
                <div className="card p-2 mt-3">
                  <div className="card-title">      
                    <h5>You have  Sub Filters</h5>            
                    <Button onClick={()=> handleOpen()} className="badge btn-xs pt-1">Add New sub Filter</Button>
                  </div>
                  <div>
                    <table className='table tabe-bordered'>
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Sub Filter Name</th>
                                <th className="text-center">Sub Filter Status</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                          {/* {location.state.item && location.state.item.sub_filter.length > 0 ?
                          <> */}
                          {location.state.item.sub_filter && location.state.item.sub_filter.map((item,index) => 
                          {
                            return (
                              <tr key={index}>
                                  <td>{index + 1 }</td>
                                  <td> {item && item.name}</td>
                                  <td className="text-center">
                                      {item && item.status == 1 ?
                                        <Badge bg="success">Enable</Badge>:
                                        <Badge bg="danger">Disable</Badge>}
                                  </td>
                                  <td className="text-center">
                                  <button onClick={() => editSub(item)}  className='btn btn-xs btn-primary text-white'>Edit</button> &nbsp;
                                  {item && item.status == 1 ?
                                  <button type="button" onClick={()=> subFilterStatus(item)} className='btn btn-xs btn-danger text-white'>Make Disable</button>:
                                  <button type="button" onClick={()=> subFilterStatus(item)} className='btn btn-xs btn-success text-white'>Make Enable</button>
                                  } 
                                  </td>
                              </tr>
                            );
                          }
                          
                          )}
                          {/* </>:
                              <tr>
                              <td colSpan={6} className="text-center fst-italic text-danger">no category added</td>
                              </tr>
                              } */}
                        </tbody>
                    </table>
                  </div>

                  {/* Add Cateory */}
                  <Modal size="md" show={show} onHide={handleClose}>
                      <Modal.Header className="py-1" closeButton>
                          <Modal.Title>Add Sub Filter</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <Form>
                          {/* <Form.Select aria-label="Default select example"
                           onChange={e =>  setSubAddFilter({...subAddFilter, filter_master_id:e.target.value})}>
                            <option selected disabled>----- select Filter -----</option>
                              {filterList && filterList.map((item,index) => {
                                return <option value={item.id}>{item.name}</option>
                              })}
                            </Form.Select> */}
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Filter Name </Form.Label>
                                <Form.Control type="text"  
                                    onChange={e => setSubAddFilter({...subAddFilter , name:e.target.value}) }
                                />
                            </Form.Group>
                          </Form>
                      </Modal.Body>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button onClick={()=> handleClose()} className="btn btn-light btn-sm">Close</button>
                          <button type="button"  onClick={()=> addSubFilter()} className="btn btn-primary btn-sm">Save Changes</button>
                      </Modal.Footer>
                  </Modal> 

                  <Modal size="md" show={Eshow}>
                      <Modal.Header>
                          <Modal.Title>Edit Brand</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                      </Modal.Body>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button  className="btn btn-light btn-sm">Close</button>
                          <button  className="btn btn-primary btn-sm">Save Changes</button>
                      </Modal.Footer>
                  </Modal> 

                  <Modal size="md" show={editShowModal} onHide={handleClose}>
                      <Modal.Header className="py-1" closeButton>
                          <Modal.Title>Edit Sub Filter</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Filter Name </Form.Label>
                                <Form.Control type="text"  
                                    onChange={e => setSubEditFilter({...subEditFilter , name:e.target.value}) }
                                    defaultValue={editSubList && editSubList.name}
                                />
                            </Form.Group>
                          </Form>
                      </Modal.Body>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button onClick={()=> handleCloseEdit()} className="btn btn-light btn-sm">Close</button>
                          <button type="button"  onClick={()=> editSubFilter()} className="btn btn-primary btn-sm">Save Changes</button>
                      </Modal.Footer>
                  </Modal> 

                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default FilterDetails;
