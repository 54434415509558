import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Loader from '../../component/loader';
import { BiCategory, BiFilterAlt } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import Cookies from 'js-cookie'
import { decrpt } from '../../component/encryption';
import Pagination from "react-js-pagination";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function District() {  
  const [ loader,setLoader ] = useState(false);
  const [error , setError ] = useState();
  const [ show,setShow ] = useState(false);
  const [ statusShow, setStatusShow ] = useState(false);
  const [ Eshow,setEShow ] = useState(false);
  const [editShow , setEditShow] = useState();
  const [ toastText,setToastText ] = useState();
  const [  paginate, setPaginate] = useState();
  const [districtList , setDistrictList]  = useState();
  const [stateList , setStateList]  = useState();
  const [addDistrictModal , setAddDistrictModal ] = useState();
  const [addNewDistrict , setAddNewDistrict ] = useState();
  const [editNewDistrict , setEditNewDistrict ] = useState();
  const [newStatus , setNewStatus ] = useState(); 
  const [stateSelect , setStateSelect ] = useState();
  const [districtSelected , setDistrictSelected ] = useState(); 

const handleState = (state_id)=> {
    setStateSelect({
        ...stateSelect,
        state_id : state_id
    })
    console.log(state_id , 'state_id')
}

  const handleEdit = (item) => {
    setEditShow(true);
    setDistrictSelected(item);
    // console.log( districtSelected , 'districtSelected');
   
  }
  const handleEditClose = () => {
    setEditShow(false);
  }

  const handleOpenStatus = (item) => {
    setStatusShow(true);
    setNewStatus(item);
  };
  const handleClosestatus = () => {
    setStatusShow(false);
  };
  const handleOpenAdd = () => {
    setAddDistrictModal(true);
  }
  const handleCloseAdd = () => {
    setAddDistrictModal(false);
  }
  const handleClose = () => {
    setShow(false);
  };
  const handleCloseEdit = () => {
    setEShow(false);
  };

  const districtListFetch = async() => {
    setLoader(true);
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      const response = await axios.post(`admin/master/district/list`)
      .then((response) => {
        setDistrictList(response.data.data.list);
        setPaginate(response.data.data.pagination);
        // console.log(response.data.data);
        setLoader(false);
      })
  }

  const addDistrict = async() => {
    try {
      setLoader(true);
      const data = {
          state_id : stateSelect.state_id,
          name : addNewDistrict.name
      }
        const token = Cookies.get('s_l');  
        const newToken = decrpt(token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
        const response = await axios.post(`admin/master/district/save` , data)
        .then((response) => {
              console.log(response.data)
              districtList.unshift(response.data.data);
              setAddDistrictModal(false);
        })
        setLoader(false);
        setToastText("District Name Added");
        toast.success(toastText,{hideProgressBar:true})
        }catch(err){
          console.log(err , 'err');
        setLoader(false);
        setToastText("Please fill All the input  Fields");
        toast.error(toastText,{hideProgressBar:true})
            setError("District  Name is Required");

        }
  }
  const editDistrict = async() => {
    setLoader(true);
        const data = {
            id : districtSelected.id,
            state_id : stateSelect.state_id,
            name : editNewDistrict.name
        }
        console.log(data , 'response data');
    try{
        const token = Cookies.get('s_l');  
        const newToken = decrpt(token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
        const response = await axios.post(`admin/master/district/save`, data )
        .then((response) => {
            if(response.data.success){
                console.log(response.data.data);
              let lastResponse = response.data.data;
                let tmparray = districtList;
              let obj = tmparray.find((o, i) => {
                  if (o.id === lastResponse.id) {
                    tmparray[i] = {...districtList[i] , ...lastResponse };
                    console.log('true',tmparray[i])
                      return true; 
                  }
                });
            }
            setEditShow(false);
            setLoader(false);
        })
    }catch(error) {
        console.log(error , 'error');
    }
  }


  const stateListfetch = async() => {
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
    const response = await axios.post(`admin/master/state/dropdown/list` )
    .then((response) => {
        setStateList(response.data.data)
    })
  }
 
  const districtStatus = async() => {
    setLoader(true);
    try {
      let newstatus = '';
      if (newStatus.status == 1) {
        newstatus = 2;
      } else {
        newstatus = 1;
      }
      const data = {
        id: newStatus.id,
        status  : newstatus
      }
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
      const response = await axios.post(`admin/master/district/status` , data)
      .then((response) => {
        let tmparray = districtList;
        let obj = tmparray.find((o, i) => {
            if (o.id === newStatus.id) {
              tmparray[i] = {...districtList[i] ,   status : newstatus  };
              console.log('true',tmparray[i])
                return true; 
            }
        });
      })
      setStatusShow(false);
      setToastText("District Status Updated");
      toast.success(toastText,{hideProgressBar:true})
    setLoader(false);
    }catch(error) {
        console.log(error, 'error');
        setToastText("Something went wrong")
        toast.success(toastText,{hideProgressBar:true})
        setLoader(false);
    }
  }

  const handlePageChange = async (pageNumber) => {
    setLoader(true);
    // console.log(`active page is ${pageNumber}`);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`admin/master/district/list?page=${pageNumber}`)
      if(response.data.success) {
        setDistrictList(response.data.data.list);
        console.log(response.data.data);
        setLoader(false);
      }else{
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }



  useEffect (() => {
    window.scrollTo(0, 0);
    districtListFetch();
    stateListfetch();
  }, []); 

  return (
    <div className="main-content">
      {loader && loader ? 
      <Loader /> :
      <>
      <ToastContainer position="top-center"  autoClose={3000}  hideProgressBar={false}  newestOnTop={false}  closeOnClick  rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'colored'}/>
      {/* New User Registration */}
      <div className="section">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <h5><BiCategory className="me-1" />District Mater List</h5>
            </div>
            <div className="card p-2 mt-3">
              <div className="card-title">
                <h5>You have  Added {districtList && districtList.length} nos Districts</h5>
                <Button onClick={()=> handleOpenAdd()} className="badge btn-xs pt-1">Add Dictrict name</Button>
              </div>
              <div>
                <table className='table tabe-bordered'>
                    <thead>
                        <tr>
                            <th>SL</th>
                            <th>District Name </th>
                            <th>State Name </th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {districtList &&  districtList.map((item,index)=> {
                          return(
                          <tr key={index}>
                            <td>{index + 1}. </td>
                            <td>{item.name}</td>
                            <td>{item.state_name}</td>
                            <td className="text-center">
                              { item &&  item.status == 1 ?
                              <Badge bg="success">Enable</Badge>:
                              <Badge bg="danger">Disable</Badge>}
                            </td>
                            <td className="text-center">
                              <button onClick={()=> handleEdit(item)} className='btn btn-xs btn-primary text-white'>Edit</button> &nbsp;
                              {item && item.status == 1 ? 
                                <button onClick={()=> handleOpenStatus(item)} className='btn btn-xs btn-danger text-white'>Make Disable</button>:
                                <button  onClick={()=> handleOpenStatus(item)} className='btn btn-xs btn-success text-white'>Make Enable</button>}                                  
                            </td>
                          </tr>
                          )
                        })}
                    </tbody>
                </table>
                {
                      districtList && districtList.length > 0 ?
                       <>
                    <div className="col-md-12">
                        <Pagination
                        totalItemsCount={ paginate && paginate.total_data}
                        onChange={handlePageChange}
                        activePage={  paginate && paginate.current_page}
                        itemsCountPerPage={paginate && paginate.per_page}
                        pageRangeDisplayed={10}
                        />
                    </div>  </>
                        : <></>
                    
                    }
              </div>
              <Modal size="md" show={statusShow} centered>
                  <Modal.Header className="py-1">
                      <Modal.Title className="h6">Do you want to  {
                          newStatus && newStatus.status == 1 ?  'Disable' : 'Enable'
                        } <br /><span className="text-danger text-capitalize fw-bold"></span></Modal.Title>
                  </Modal.Header>
                  <Modal.Footer className="p-1 justify-content-start">
                      <button  onClick={()=> handleClosestatus()} className="btn btn-light btn-sm">Close</button>
                      <button  onClick={() => districtStatus()} className="btn btn-primary btn-sm"  >
                      {newStatus && newStatus.status == 1 ?  'Make Disable' : 'Make Enable'}
                      </button>
                  </Modal.Footer>
              </Modal> 
              {/*   */}
              <Modal size="md" show={addDistrictModal} centered>
                  <Modal.Header className="py-1">
                      <Modal.Title className="h6">New District Name <br /><span className="text-danger text-capitalize fw-bold"></span></Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group>
                        <Form.Label>State List</Form.Label>
                        <Form.Select
                            onChange = {state_id => handleState(state_id.target.value)}
                        >
                            {stateList && stateList.length > 0 ?
                            <>
                                    <option selected disabled>----- select State -----</option>
                            {stateList && stateList.map((item,index) =>{
                                return (
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                            })}
                            </>    :
                            <>
                            <tr>
                                <td>--- no state fetched ----</td>
                            </tr>
                            </>
                        }
                        </Form.Select>
                    </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>District Add</Form.Label>
                            <Form.Control size="sm" type="text" 
                            placeholder="Enter District Name"
                            onChange={e=> setAddNewDistrict({...addNewDistrict , name:e.target.value })}
                              />
                              {error && error ? <p style={{color : 'red' , fontSize : '12px'}}> {error}</p> : ''}
                        </Form.Group>
                  </Modal.Body>
                  <Modal.Footer className="p-1 justify-content-start">
                      <button  onClick={()=> handleCloseAdd()} className="btn btn-light btn-sm">Close</button>
                      <button  onClick={() => addDistrict()} className="btn btn-primary btn-sm"  >Submit</button>
                  </Modal.Footer>
              </Modal> 
              <Modal size="md" show={editShow} centered>
                  <Modal.Header className="py-1">
                      <Modal.Title className="h6">Edit District  Name <br /><span className="text-danger text-capitalize fw-bold"></span></Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                        <Form.Group>
                            <Form.Label>Edit State List</Form.Label>
                            <Form.Select
                                onChange = {state_id => handleState(state_id.target.value)}
                            >
                                {stateList && stateList.length > 0 ?
                                <>
                                        <option selected disabled>----- select State -----</option>
                                {stateList && stateList.map((item,index) =>{
                                    return (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    )
                                })}
                                </>    :
                                <>
                                <tr>
                                    <td>--- no state fetched ----</td>
                                </tr>
                                </>
                            }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Edit District Name</Form.Label>
                            <Form.Control size="sm" type="text" 
                            placeholder="Enter District Name"
                            defaultValue={ districtSelected && districtSelected.name}
                            onChange={e=> setEditNewDistrict({...editNewDistrict , name:e.target.value })}
                            />
                        </Form.Group>
                  </Modal.Body>
                  <Modal.Footer className="p-1 justify-content-start">
                      <button  onClick={()=> handleEditClose()} className="btn btn-light btn-sm">Close</button>
                      <button  onClick={() => editDistrict()} className="btn btn-primary btn-sm"  >Save</button>
                  </Modal.Footer>
              </Modal> 
            </div>
          </div>
        </div>
      </div>
      </>
      }
    </div>
  );
}

export default District;
