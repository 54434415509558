import React, { useEffect, useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import { BsPlusCircleDotted } from "react-icons/bs";
import { BiCategory, BiFilterAlt } from "react-icons/bi";
import Cookies from "js-cookie";
import Loader from "../../component/loader";
import { Modal } from "react-bootstrap";
import { decrpt } from "../../component/encryption";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Pagination from "react-js-pagination";

function Filter () {
  const [ addFilter , setAddFilter ]  = useState();
  const [ filterList , setFilterList ]  = useState();
  const [loader,setLoader] = useState(false);
  const [show,setShow] = useState(false);
  const navigate = useNavigate();

  console.log(addFilter , 'addFilter');
  const handleClose = () => {
    setShow(false);
  };
  const handleOpen = () => {setShow(true)};


  const filterMasterList = async () => {
    setLoader(true);
    setLoader(true);
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`admin/master/filter/list`)
    .then((response) => {
        setFilterList(response.data.data.list);
        setLoader(false);
      console.log('seller list error' , response.data)
    }).catch((err) => {
      console.log('seller list error' , err)
      setLoader(false);
    })
  }

  
  const addNewFilter = async () => {
   const  data ={
    name : addFilter.name
   }
    setLoader(true);
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`admin/master/filter/save` , data)
    .then((response) => {
        setAddFilter(response.data.data);

        setLoader(false);
    }).catch((err) => {
      console.log('seller list error' , err)
     
    })
  }

  const filterDetails =(item) => {
    navigate('./filterDetails' ,{state : {item}});
  }
  useEffect(() => {
    filterMasterList();
  },[]);

  return(
    <div className="main-content"> 
    {loader && loader ? 
      <Loader /> 
    :
    <>
      <ToastContainer position="top-center"  autoClose={3000}  hideProgressBar={false}  newestOnTop={false}  closeOnClick  rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'colored'}/> 
    <div className="section">
        <div className="row">
            <div className="col-md-12">
                <div className="section-title">
                <h5><BiCategory className="me-1" />All Sub Filter</h5>
                </div>
                <div className="card p-2 mt-3">
              <div className="card-title">      
                <h5>You have  numbers of 2nd Level Category</h5>                            
                <Button onClick={()=> handleOpen()} className="badge btn-xs pt-1">Add New Sub Category</Button>
              </div>
              <div>
                <table className='table tabe-bordered'>
                    <thead>
                        <tr>
                            <th>SL</th>
                            <th>Sub Category</th>
                            <th>Category</th>
                            <th>Image</th>
                            <th>Banner Image</th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                            <tr>
                              <td>index + 1 </td>
                            </tr>
                    </tbody>
                </table>
               

              </div>
            </div>
            </div>
        </div>
    </div>
    </>
    } 
      
    </div>
  )
}

export default Filter;
