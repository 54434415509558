import React, { useEffect, useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import { BsPlusCircleDotted ,BsFillChatSquareTextFill } from "react-icons/bs";
import { BiCategory, BiFilterAlt } from "react-icons/bi";
import { BsFillPinMapFill } from "react-icons/bs";

import Cookies from "js-cookie";
import Loader from "../../component/loader";
import { Modal } from "react-bootstrap";
import { decrpt } from "../../component/encryption";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Pagination from "react-js-pagination";

function Page () {
  const [ addFilter , setAddFilter ]  = useState();
  const [ filterList , setFilterList ]  = useState();
  const [loader,setLoader] = useState(false);
  const [show,setShow] = useState(false);
  const [errors , setErrors ] = useState(false);
  const [dashmaster , setDashmaster ] = useState();
  const navigate = useNavigate();

  console.log(filterList , 'addFilter');
  const handleClose = () => {
    setShow(false);
  };
  const handleOpen = () => {setShow(true)};

  const fetchMatser = async () => {
    setLoader(true);
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.get(`/admin/master/index`)
    .then((response) => {
      setDashmaster(response.data.data);
      console.log(response.data.data);
      setLoader(false);
    })
  }

  const filterMasterList = async () => {
    setLoader(true);
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`admin/master/filter/list`)
    .then((response) => {
        setFilterList(response.data.data.list);
        setLoader(false);
      console.log('seller list error' , response.data)
    }).catch((err) => {
      console.log('seller list error' , err)
      setLoader(false);
    })
  }


  const addNewFilter = async () => {
    setLoader(true);
    try {
   const  data ={
    name : addFilter.name
   }
    setLoader(true);
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`admin/master/filter/save` , data)
    .then((response) => {
        // setAddFilter(response.data.data);
        console.log(response.data.data);
        let newData = response.data.data;
        filterList.push(newData);
        setLoader(false);
        setShow(false);
    }).catch((err) => {
      console.log('seller list error' , err)
    })
  }catch(err){
        console.log(err , 'err');
        setErrors('Filter Name is Required')
  }
  }

  const filterDetails =(item ) => {
    navigate('./filterDetails' ,{state : {item }});
  }
  useEffect(() => {
    filterMasterList();
    fetchMatser();
  },[]);

  return(
    <div className="main-content"> 
    {loader && loader ? 
      <Loader /> 
    :
    <>
    <div className="section">
        <div className="row">

          {/* Category & Sub category */}
          <div className="col-md-12 mb-4">
            <div className="section-title">
              <h5><BiCategory className="me-1" />Category and Sub category</h5>
            </div>
            <div className="count-section d-flex gap-3 mt-2">
              {/* count 1 */}
              <div className="count-block d-flex align-items-center justify-content-between no-divider">
                <div  className="count-figure"><h3>{dashmaster && dashmaster.FirstLevelCategory}</h3></div>
                <div className="count-content">
                  <h5>1st Level Category</h5>
                  <Link to="category" className="link-btn">view</Link>
                </div>
              </div>
              {/* count 2 */}
              <div className="count-block d-flex align-items-center justify-content-between no-divider">
                <div  className="count-figure"><h3>{dashmaster && dashmaster.SecondLevelCategory}</h3></div>
                <div className="count-content">
                  <h5>2nd Level Category</h5>
                  <Link to="sub_category" className="link-btn">view</Link>
                </div>
              </div>
              {/* count 3 */}
              <div className="count-block d-flex align-items-center justify-content-between no-divider">
                <div  className="count-figure"><h3>{dashmaster && dashmaster.ThirdLevelCategory}</h3></div>
                <div className="count-content">
                  <h5>3rd Level Category</h5>
                  <Link to="last_category" className="link-btn">view</Link>
                </div>
              </div>
            </div>
          </div>

          {/* Filters */}
          <div className="col-md-12 mb-4">
            <div className="section-title">
              <h5><BiFilterAlt className="me-1" />Filters</h5>
            </div>
            <div className="count-section d-flex gap-3 mt-2">
              {/* count 1 */}
              <div className="count-block d-flex align-items-center justify-content-between no-divider">
                <div  className="count-figure"><h3>{dashmaster && dashmaster.Brand}</h3></div>
                <div className="count-content">
                  <h5>Brand Filter</h5>
                  <Link to="brands" className="link-btn">view</Link>
                </div>
              </div>
              {/* <div className="count-block d-flex align-items-center justify-content-between no-divider">
                <div  className="count-figure"><h3>16</h3></div>
                <div className="count-content">
                  <h5>Brand Map with Categopry</h5>
                  <Link to="brandMap" className="link-btn">view</Link>
                </div>
              </div> */}
              {/* count 2 */}
              <div className="count-block d-flex align-items-center justify-content-between no-divider">
                <div  className="count-figure"><h3>{dashmaster && dashmaster.Size}</h3></div>
                <div className="count-content">
                  <h5>Size Filter</h5>
                  <Link to="sizes" className="link-btn">view</Link>
                </div>
              </div>
              {/*  */}
              {/* <div className="count-block d-flex align-items-center justify-content-between no-divider">
                <div  className="count-figure"><h3>16</h3></div>
                <div className="count-content">
                  <h5>Size Map with Categopry</h5>
                  <Link to="sizeMap" className="link-btn">view</Link>

                </div>
              </div> */}
            </div>
          </div>

          {/* Custom Filters */}
          <div className="col-md-12 mb-4">
            <div className="section-title">
              <h5><BiFilterAlt className="me-1" />Custom Filters</h5>
            </div>
            <div className="mt-2">
              {filterList && filterList.length > 0 ? <p style={{color : 'green'}}>You have {filterList.length}   filters</p> :
              <p className="text-danger fst-italic mb-0">* no custom filters added</p>
              }
              <div className="count-section d-flex gap-3 " style={{flexWrap : "wrap"}}>
                {/* count 1 */}
                <a onClick={() =>handleOpen()}  className="count-block d-flex align-items-center justify-content-center no-divider" style={{cursor : 'pointer'}}>
                  <div className="px-3">
                    <BsPlusCircleDotted className="filtersAddIcon" />
                  </div>
                  <div className="count-content">
                    <h5 className="h5 mb-0">Add Custom Filters</h5>
                  </div>
                </a>
                {/* <div className="count-block d-flex align-items-center justify-content-between no-divider">
                  <div  className="count-figure"><h3>15</h3></div>
                  <div className="count-content">
                    <h5> Filter List</h5>
                    <Link to="filter" className="link-btn">view</Link>
                  </div>
                </div> */}
                { filterList && filterList.length > 0 ? filterList.map((item,index) => {
                  return (
                      <div key={index} className="count-block d-flex align-items-center justify-content-between no-divider">
                          <div  className="count-figure"> <h3> {item.sub_filter && item.sub_filter.length}</h3>Sub Filters</div>
                          <div className="count-content">
                            <h5 style={{overflowWrap : "anywhere" , width : '190px'}}>{item.name}</h5>
                            <button onClick={() => filterDetails(item)} className="border-0 bg-warning link-btn">view</button>
                          </div>
                      </div>
                  )
                }) :
                <>
                  <div className="count-block d-flex align-items-center justify-content-center no-divider">
                    <div className="count-content text-center">
                      <h5>No Filters </h5>
                  </div>
                </div>
                </>
                }
              </div>
            </div>
          </div>
          <div className="col-md-12">
                <div className="section-title">
                  <h5><BsFillPinMapFill className="me-1" />Address Master</h5>
                </div>
                <div className="mt-4">
                  <div className="count-section d-flex gap-3">
                    <div className="count-block d-flex align-items-center justify-content-between no-divider">
                        <div  className="count-figure"><h3>{dashmaster && dashmaster.State}</h3></div>
                        <div className="count-content">
                          <h5>State List</h5>
                          <Link to="state" className="link-btn">view</Link>
                        </div>
                    </div>
                    <div className="count-block d-flex align-items-center justify-content-between no-divider">
                        <div  className="count-figure"><h3>{dashmaster && dashmaster.District}</h3></div>
                        <div className="count-content">
                          <h5>District List</h5>
                          <Link to="district" className="link-btn">view</Link>
                        </div>
                    </div>
                    <div className="count-block d-flex align-items-center justify-content-between no-divider">
                        <div  className="count-figure"><h3>{dashmaster && dashmaster.City}</h3></div>
                        <div className="count-content">
                          <h5>City List</h5>
                          <Link to="city" className="link-btn">view</Link>
                        </div>
                    </div>
                  </div>
                </div>
          </div>
          <div className="col-md-12">
                <div className="section-title">
                  <h5><BsFillChatSquareTextFill className="me-1" />Policy Master</h5>
                </div>
                <div className="mt-4">
                  <div className="count-section d-flex gap-3">
                    <div className="count-block d-flex align-items-center justify-content-between no-divider">
                        <div  className="count-figure"><h3>{dashmaster && dashmaster.returnPolicy}</h3></div>
                        <div className="count-content">
                          <h5>Return Policy</h5>
                          <Link to="return_policy" className="link-btn">view</Link>
                        </div>
                    </div>
                    <div className="count-block d-flex align-items-center justify-content-between no-divider">
                        <div  className="count-figure"><h3>{dashmaster && dashmaster.orderCancelPolicy}</h3></div>
                        <div className="count-content">
                          <h5>Cancellation Policy </h5>
                          <Link to="cancel_policy" className="link-btn">view</Link>
                        </div>
                    </div>
                  </div>
                </div>
          </div>
          <div className="col-md-12">
                <div className="section-title">
                  <h5><BsFillChatSquareTextFill className="me-1" />Tax and Commission</h5>
                </div>
                <div className="mt-4">
                  <div className="count-section d-flex gap-3">
                    <div className="count-block d-flex align-items-center justify-content-between no-divider">
                        <div  className="count-figure"><h3>
                          {dashmaster && dashmaster.taxData}
                          </h3></div>
                        <div className="count-content">
                          <h5>Tax Master</h5>
                          <Link to="tax_list" className="link-btn">view</Link>
                        </div>
                    </div>
                  </div>
                </div>
          </div>
          <Modal size="md" show={show} onHide={handleClose}>
              <Modal.Header className="py-1" closeButton>
                  <Modal.Title>Add Filter</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Form>
                    {/* {errors && errors ? <p style={{color : 'red' , fontSize : '12px'}}>{errors}</p> : ''} */}
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Filter Name  </Form.Label>
                        <Form.Control type="text"  
                            defaultValue={filterList && filterList.name}
                            onChange={e => setAddFilter({...addFilter , name:e.target.value})}
                        />
                        {errors && errors ? <p style={{color : 'red' , fontSize : '12px'}}>{errors}</p> : '' }
                    </Form.Group>
                  </Form>
              </Modal.Body>
              <Modal.Footer className="p-1 justify-content-start">
                  <button  className="btn btn-light btn-sm" onClick={() => handleClose()}>Close</button>
                  <button type="button" onClick={() =>addNewFilter()}  className="btn btn-primary btn-sm">Save Changes</button>
              </Modal.Footer>
          </Modal> 
        </div>
      </div>
    </>
    } 
      
    </div>
  )
}

export default Page;
