import React, { useEffect, useState } from "react";
import { BrowserRouter , Route, Routes,Outlet } from "react-router-dom";
import OrderList from '../order/list';
import OrderSingle from '../order/single';

function User() {  
  useEffect (() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* <Routes>   
        <Route exact index path="/*" element={<OrderList />} />
        <Route exact path="/single/*" element={<OrderSingle />} />
      </Routes> */}
      <Outlet />
    </div>

  );
}

export default User;
