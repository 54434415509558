import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaPhoneAlt, FaRegEnvelopeOpen } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import ImageThumb from '../../../component/imageThumb';

function ProfileDetail(props) {
    console.log(props);
    const location = useLocation();
    const [lgShow, setLgShow] = useState(false);

    return (
        <div>
            <Button 
                style={{
                    fontSize:14,
                    backgroundColor:"#ffc001" ,
                    color:"#000",
                    textDecoration:"none",
                    fontWeight:700
                }}
                variant="link"
                onClick={() => setLgShow(true)}
            > 
                View Profile Detail
            </Button>
            
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                // style={{marginTop:10}}
            >
                <Modal.Header closeButton className='py-1'>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Seller Profile Detail
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h5 className='mb-0 text-dark' style={{fontWeight:'700',fontSize:14,textTransform:'uppercase'}}>Business Detail</h5>
                            <hr className='mt-0' />
                            <div className='address-box mb-3'>
                                <h6 className='mb-1'>{props.location.state.item.business_details.business_name}</h6>
                                <p className='mb-1'>{props.location.state.item.business_details.address}</p>
                                <div className='d-flex flex-wrap'>
                                    <p className='mb-2' style={{width:'33.3%'}}> 
                                        <strong>Trade Lisence :</strong> <br/> {props.location.state.item.trade_lic_no} <br />
                                        <ImageThumb image={props.location.state.item && props.location.state.item.trade_lic_image} />
                                    </p>
                                    <p className='mb-2' style={{width:'33.3%'}}> 
                                        <strong>Pan No. :</strong> <br/> {props.location.state.item.pan} <br />
                                        <ImageThumb image={props.location.state.item && props.location.state.item.pan_image} />
                                    </p>
                                    <p className='mb-2' style={{width:'33.3%'}}> <strong>GST Number :</strong> <br/>  {props.location.state.item.gst} <br />
                                        <ImageThumb image={props.location.state.item && props.location.state.item.gst_image} />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <h5 className='mb-0 text-dark' style={{fontWeight:'700',fontSize:14,textTransform:'uppercase'}}>Bank Detail</h5>
                            <hr className='mt-0' />
                            <div className='address-box mb-3'>                                
                                <div className='d-flex flex-wrap'>
                                    <p className='mb-2' style={{width:'33.3%'}}> <strong>A/c Holder Name :</strong> <br/> {props.location.state.item.bank_detail.name}</p>
                                    <p className='mb-2' style={{width:'33.3%'}}> <strong>A/c No. :</strong> <br/>  {props.location.state.item.bank_detail.ac_no}</p>
                                    <p className='mb-2' style={{width:'33.3%'}}> <strong>IFSC :</strong> <br/>  {props.location.state.item.bank_detail.ifsc}</p>
                                    <p className='mb-2' style={{width:'33.3%'}}> <strong>Bank Name :</strong> <br/> {props.location.state.item.bank_detail.bank_name}</p>
                                    <p className='mb-2' style={{width:'33.3%'}}> <strong>Bank Branch :</strong> <br/>  {props.location.state.item.bank_detail.branch}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ProfileDetail;