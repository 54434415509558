import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Loader from '../../component/loader';
import { BiCategory, BiFilterAlt } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import Cookies from 'js-cookie'
import { decrpt } from '../../component/encryption';
import Pagination from "react-js-pagination";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function City() {  
  const [ loader,setLoader ] = useState(false);
  const [ statusShow, setStatusShow ] = useState(false);
  const [ Eshow,setEShow ] = useState(false);
  const [error , setError] = useState();
  const [ toastText,setToastText ] = useState('');
  const [ paginate, setPaginate] = useState();
  const [cityList , setCityList]  = useState();
  const [addCityModal , setAddCityModal ] = useState();
  const [editCityModal , setEditCityModal ] = useState();
  const [addNewCity , setAddNewCity ] = useState(false);
  const [stateList , setStateList]  = useState();
  const [districtList , setDistrictList]  = useState();
  const [stateSelect , setStateSelect] = useState();
  const [districtSelect , setDistrictSelect] = useState();
  const [newStatus , setNewStatus] = useState();

  const [ editCityName , setEditCityName ] = useState();

  const handleOpenStatus = (item) => {
    setStatusShow(true);
    setNewStatus(item);
  };
  const handleClosestatus = () => {
    setStatusShow(false);
  };
  const handleOpenAdd = () => {
    setAddCityModal(true);
  }
  const handleOpenClose = () => {
    setAddCityModal(false);
  }
  const handleEdit = (item) => {
    setEditCityModal(true);
    setEditCityName(item);
  };
  const handleCloseEdit = () => {
    setEditCityModal(false);
  };

  const handleState = (state_id)=> {
    setStateSelect({
        ...stateSelect,
        state_id : state_id
    })
    districtListFetch(state_id);
}
  const handleDistrict = (district_id)=> {
    setDistrictSelect({
        ...districtSelect,
        district_id : district_id
    })
}
// edit
  const handleStateEdit = (state_id)=> {
    setEditCityName({
        ...editCityName,
        state_id : state_id
    })
    districtListFetch(state_id);
    console.log(state_id , 'state_id')
}
  const handleDistrictEdit = (district_id)=> {
    setEditCityName({
        ...editCityName,
        district_id : district_id
    })
}
// edit
  const cityListFetch = async() => {
    setLoader(true);
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      const response = await axios.post(`admin/master/city/list`)
      .then((response) => {
        setCityList(response.data.data.list);
        setPaginate(response.data.data.pagination)
        // console.log(response.data.data);
        setLoader(false);
      })
  }
// state dropdown list
const stateListfetch = async() => {
  const token = Cookies.get('s_l');  
  const newToken = decrpt(token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
  const response = await axios.post(`admin/master/state/dropdown/list` )
  .then((response) => {
      setStateList(response.data.data);
  })
}
// state dropdown list
// district fetch
const districtListFetch = async(state_id) => {
  const data = {
    state_id : state_id
  }
  const token = Cookies.get('s_l');  
  const newToken = decrpt(token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
  const response = await axios.post(`admin/master/district/dropdown/list`, data)
  .then((response) => {
    setDistrictList(response.data.data);
    console.log(response.data.data);
  })
}
// district fetch

  const addCity = async() => {
    try{
      setLoader(true);
      const data = {
        name : addNewCity.name,
        state_id : stateSelect.state_id,
        district_id : districtSelect.district_id,
      }
      console.log(data , 'data');
        const token = Cookies.get('s_l');  
        const newToken = decrpt(token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
        const response = await axios.post(`admin/master/city/save` , data)
        .then((response) => {
              console.log(response.data.data)
              cityList.unshift(response.data.data);
        })
      setLoader(false);
      setAddCityModal(false);
      setToastText("City Added");
      toast.success(toastText,{hideProgressBar:true})
    }catch(error){
      console.log(error , 'error');
      setLoader(false);
      setToastText("Please fill All the input  Fields");
      toast.error(toastText,{hideProgressBar:true})
      setError('City Name Field is Required')
    }
  }

  const cityStatus = async() => {
    setLoader(true);
    try {
      let newstatus = '';
      if (newStatus.status == 1) {
        newstatus = 2;
      } else {
        newstatus = 1;
      }
      const data = {
        id: newStatus.id,
        status  : newstatus
      }
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
      const response = await axios.post(`admin/master/city/status`, data )
      .then((response) => {
        let tmparray = cityList;
        let obj = tmparray.find((o, i) => {
            if (o.id === newStatus.id) {
              tmparray[i] = {...cityList[i] ,   status : newstatus  };
              console.log('true',tmparray[i])
                return true; 
            }
        });
      })
      setStatusShow(false);
      setToastText("City status  Updated");
      toast.success(toastText,{hideProgressBar:true})
    setLoader(false);
    }catch(error) {
      setLoader(false);
        console.log(error, 'error');
        setToastText("Something went wrong")
        toast.error(toastText,{hideProgressBar:true})
    }
  }

  const editCity = async() => {
    setLoader(true);
    const data = {
      id : editCityName.id,
      name : editCityName.name,
      state_id : editCityName.state_id,
      district_id : editCityName.district_id,
    }
    try {
        const token = Cookies.get('s_l');  
        const newToken = decrpt(token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        const response = await axios.post(`admin/master/city/save`, data )
        .then((response) => {
          if(response.data.success){
            console.log(response.data);
            let lastResponse = response.data.data;
            let tmparray = cityList;
          let obj = tmparray.find((o, i) => {
              if (o.id === lastResponse.id) {
                tmparray[i] = {...cityList[i] , ...lastResponse };
                console.log('true',tmparray[i])
                  return true; 
              }
            });
          }
        })
        setLoader(false);
        setEditCityModal(false);
        setToastText("City data Changed");
        toast.success(toastText,{hideProgressBar:true})
    }catch(error){
      console.log(error , 'error');
    }
  }

  const handlePageChange = async (pageNumber) => {
    setLoader(true);
    // console.log(`active page is ${pageNumber}`);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`admin/master/city/list?page=${pageNumber}`)
      console.log(response.data.data,'response active');
      if(response.data.success) {
        setCityList(response.data.data.list);
        setLoader(false);
      }else{
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }



  useEffect (() => {
    window.scrollTo(0, 0);
    cityListFetch();
    stateListfetch();
  }, []); 

  return (
    <div className="main-content">
      {loader && loader ?
      <Loader /> :
    <>
      <ToastContainer position="top-center"  autoClose={3000}  hideProgressBar={false}  newestOnTop={false}  closeOnClick  rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'colored'}/>
      {/* New User Registration */}
      <div className="section">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <h5><BiCategory className="me-1" />City Mater List</h5>
            </div>
            <div className="card p-2 mt-3">
              <div className="card-title">
                <h5>You have  Added {cityList && cityList.length} nos Cities </h5>
                <Button onClick={()=> handleOpenAdd()} className="badge btn-xs pt-1">Add New City Name</Button>
                {/* <Button className="badge btn-md m-auto pt-1">Back</Button> */}
              </div>
              <div>
                <table className='table tabe-bordered'>
                    <thead>
                        <tr>
                            <th>SL</th>
                            <th>City Name </th>
                            <th>District Name </th>
                            <th>State Name </th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {cityList &&  cityList.map((item,index)=> {
                          return(
                          <tr key={index}>
                            <td>{index + 1}. </td>
                            <td>{item.name}</td>
                            <td>{item.district_name}</td>
                            <td>{item.state_name}</td>
                            <td className="text-center">
                              { item &&  item.status == 1 ?
                              <Badge bg="success">Enable</Badge>:
                              <Badge bg="danger">Disable</Badge>}
                            </td>
                            <td className="text-center">
                              <button onClick={()=> handleEdit(item)} className='btn btn-xs btn-primary text-white'>Edit</button> &nbsp;
                              {item && item.status == 1 ? 
                                <button onClick={()=> handleOpenStatus(item)} className='btn btn-xs btn-danger text-white'>Make Disable</button>:
                                <button  onClick={()=> handleOpenStatus(item)} className='btn btn-xs btn-success text-white'>Make Enable</button>}                                  
                            </td>
                          </tr>
                          )
                        })}
                    </tbody>
                </table>
                {
                      cityList && cityList.length > 0 ?
                       <>
                    <div className="col-md-12">
                        <Pagination
                        totalItemsCount={ paginate && paginate.total_data}
                        onChange={handlePageChange}
                        activePage={  paginate && paginate.current_page}
                        itemsCountPerPage={paginate && paginate.per_page}
                        pageRangeDisplayed={10}
                        />
                    </div>  </>
                        : <></>
                    
                    }
              </div>
              <Modal size="md" show={statusShow} centered>
                  <Modal.Header className="py-1">
                      <Modal.Title className="h6">Do you want to  {
                          newStatus && newStatus.status == 1 ?  'Disable' : 'Enable'
                        }<br /><span className="text-danger text-capitalize fw-bold"></span></Modal.Title>
                  </Modal.Header>
                  <Modal.Footer className="p-1 justify-content-start">
                      <button  onClick={()=> handleClosestatus()} className="btn btn-light btn-sm">Close</button>
                      <button  onClick={()=> cityStatus()} className="btn btn-primary btn-sm"  >
                      {newStatus && newStatus.status == 1 ?  'Make Disable' : 'Make Enable'}
                      </button>
                  </Modal.Footer>
              </Modal> 
              {/*  */}
              <Modal size="md" show={addCityModal} centered>
                  <Modal.Header className="py-1">
                      <Modal.Title className="h6">New City Name <br /><span className="text-danger text-capitalize fw-bold"></span></Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                          <Form.Group>
                              <Form.Label>Edit State List</Form.Label>
                                <Form.Select onChange = {state_id => handleState(state_id.target.value)}>
                                    {stateList && stateList.length > 0 ?
                                    <>
                                            <option selected disabled>----- select State -----</option>
                                    {stateList && stateList.map((item,index) =>{
                                        return (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        )
                                    })}
                                    </>    :
                                    <>
                                     <option selected disabled>------ No valid state ------</option>
                                    </>
                                }
                            </Form.Select>
                        </Form.Group>
                          <Form.Group>
                                <Form.Label>Edit District List</Form.Label>
                                <Form.Select onChange = {district_id => handleDistrict(district_id.target.value)}>
                                    {districtList && districtList.length > 0 ?
                                    <>
                                            <option selected disabled>----- select State -----</option>
                                    {districtList && districtList.map((item,index) =>{
                                        return (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        )
                                    })}
                                    </>    :
                                    <>
                                   <option selected disabled>------ Select a valid state ------</option>
                                    </>
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>City Add</Form.Label>
                            <Form.Control size="sm" type="text" 
                            placeholder="Enter City Name"
                            onChange={e=> setAddNewCity({...addNewCity , name:e.target.value })}
                              />
                              {error && error ? <p style={{color : 'red' , fontSize : '12px'}}>{error}</p> : ''}
                        </Form.Group>
                  </Modal.Body>
                  <Modal.Footer className="p-1 justify-content-start">
                      <button  onClick={()=> handleOpenClose()} className="btn btn-light btn-sm">Close</button>
                      <button  onClick={() => addCity()} className="btn btn-primary btn-sm"  >Submit</button>
                  </Modal.Footer>
              </Modal> 
              <Modal size="md" show={editCityModal} centered>
                  <Modal.Header className="py-1">
                      <Modal.Title className="h6">Edit City Name <br /><span className="text-danger text-capitalize fw-bold"></span></Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                          <Form.Group>
                              <Form.Label>Edit State List</Form.Label>
                                <Form.Select onChange = {state_id => handleStateEdit(state_id.target.value)}>
                                    {stateList && stateList.length > 0 ?
                                    <>
                                            <option selected disabled>----- select State -----</option>
                                    {stateList && stateList.map((item,index) =>{
                                        return (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        )
                                    })}
                                    </>    :
                                    <>
                                     <option selected disabled>------ No valid state ------</option>
                                    </>
                                }
                            </Form.Select>
                        </Form.Group>
                          <Form.Group>
                                <Form.Label>Edit District List</Form.Label>
                                <Form.Select onChange = {district_id => handleDistrictEdit(district_id.target.value)}>
                                    {districtList && districtList.length > 0 ?
                                    <>
                                            <option selected disabled>----- select State -----</option>
                                    {districtList && districtList.map((item,index) =>{
                                        return (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        )
                                    })}
                                    </>    :
                                    <>
                                   <option selected disabled>------ Select a valid state ------</option>
                                    </>
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>City Add</Form.Label>
                            <Form.Control size="sm" type="text" 
                            placeholder="Enter City Name"
                            onChange={e=> setEditCityName({...editCityName , name:e.target.value })}
                            defaultValue={editCityName && editCityName.name}
                              />
                        </Form.Group>
                  </Modal.Body>
                  <Modal.Footer className="p-1 justify-content-start">
                      <button  onClick={()=> handleCloseEdit()} className="btn btn-light btn-sm">Close</button>
                      <button  onClick={() => editCity()} className="btn btn-primary btn-sm"  >Submit</button>
                  </Modal.Footer>
              </Modal> 
            </div>
          </div>
        </div>
      </div>
    </>
    }
    </div>
  );
}

export default City;
