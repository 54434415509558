import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Loader from "../../component/loader";
import { BiCategory, BiFilterAlt } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import Cookies from 'js-cookie'
import { decrpt } from '../../component/encryption';
import Pagination from "react-js-pagination";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function State() {  
  const [ loader,setLoader ] = useState();
  const [ statusShow, setStatusShow ] = useState();
  const [ addStateModal , setAddStateModal ] = useState();
  const [ editStateModal , setEditStateModal ] = useState();
  const [ newStatus , setNewStatus ] = useState();
  const [ toastText,setToastText ] = useState('');
  const [  paginate, setPaginate] = useState();
  const [errors , setErrors ] = useState(false);
  const [stateList , setStateList]  = useState();
  const [newState , setNewState ] = useState();
  const [newEditState , setNewEditState ] = useState();
  const [ editNewState , setEditNewState] = useState();

  const handleAddOpen = () => {
    setAddStateModal(true);
  };
  const handleAddClose = () => {
    setAddStateModal(false);
  };
  const handleClose = () => {
    setEditStateModal(false)
  };
  const handleEdit = (item) => {
    setEditNewState(item);
    setEditStateModal(true);
  }
 
  const handleStatus = (item) => {
    setStatusShow(true);
    setNewStatus(item);
  }
  const handleStatusClose = () => {
    setNewStatus(false)
    // alert('dksjdfl')
  }
console.log(errors , 'errors');


  const stateListFetch = async() => {
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      const response = await axios.post(`admin/master/state/list`)
      .then((response) => {
        setStateList(response.data.data.list.data);
        setPaginate(response.data.data.pagination);
        console.log(response);
      })
  }

  const addState = async() => {
    try {
        setLoader(true);
          const data = {
            name : newState.name
          }
        const token = Cookies.get('s_l');  
        const newToken = decrpt(token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
        const response = await axios.post(`admin/master/state/save` , data)
        .then((response) => {
          console.log(response.data);
          let resState = response.data.data;
          stateList.unshift(resState);
          })
          setLoader(false);
          setAddStateModal(false)
          setToastText(" State Name Added")
          toast.success(toastText,{hideProgressBar:true})
      }catch(err){
        console.log(err , 'error');
        setLoader(false);
        setAddStateModal(true);
        setErrors("State Name is Required");
        console.log(errors);
      }
  }

  const editState = async() => {
    try {
      setLoader(true);
      const data = {
        id    : editNewState.id,
        name : newEditState.name
      }
      const token = Cookies.get('s_l');  
        const newToken = decrpt(token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
        const response = await axios.post(`admin/master/state/save` , data)
        .then((response) => {
          console.log(response.data);
          let newData = response.data.data;
          let tmparray = stateList;
          let obj = tmparray.find((o, i) => {
            if (o.id === newData.id) {
                tmparray[i] = {...stateList[i] , ...newData };
                console.log('true',tmparray[i])
                  return true; 
              }
            });
        })
        setEditStateModal(false);
        setLoader(false);
        setToastText("Edited State Name")
        toast.success(toastText,{hideProgressBar:true})
    }catch(error) {
      setLoader(false);
      setEditStateModal(true);
      console.log(error , 'error');
      setErrors(error.response.data.errors);
    }
  }

  const statusUpdate = async()=> {
    setLoader(true);
    let newstatus = '';
    if (newStatus.status == 1) {
      newstatus = 2;
    } else  {
      newstatus = 1;
    }
    const data = {
      id: newStatus.id,
      status : newstatus
    }
    try {
        const token = Cookies.get('s_l');
        const newToken = decrpt(token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
        const response = await axios.post(`admin/master/state/status`, data )
        .then((response) => {
          console.log('Response',response);
          let resStatus = response.data.data ;
          let tmparray = stateList;
          let obj = tmparray.find((o, i) => {
            if (o.id === newStatus.id) {
                tmparray[i] = {...stateList[i] ,   ...resStatus  };
                return true; 
              }
            });
            setNewStatus(false)
            setToastText("State Status Updated")
            toast.success(toastText,{hideProgressBar:true})
            setLoader(false)
        })
    }catch(error) {
        console.log(error);
        setToastText("Something went Wrong")
        toast.danger(toastText,{hideProgressBar:true})
        setLoader(false);
    }
  }

  const handlePageChange = async (pageNumber) => {
    setLoader(true);
    console.log(`active page is ${pageNumber}`);
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`admin/master/state/list?page=${pageNumber}` )
    .then((response) => {
      setStateList(response.data.data.list.data);
      setLoader(false);
    }).catch((err) => {
      console.log('seller list error' , err.response)
      setLoader(false);
    })
  }


  useEffect (() => {
    window.scrollTo(0, 0);
    stateListFetch();
  }, []); 

  return (
    <div className="main-content">
      {loader && loader ?
       <Loader />
      : 
      <>
      <ToastContainer position="top-center"  autoClose={3000}  hideProgressBar={false}  newestOnTop={false}  closeOnClick  rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'colored'}/>
      {/* New User Registration */}
      <div className="section">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <h5><BiCategory className="me-1" />State Master List</h5>
            </div>
            <div className="card p-2 mt-3">
              <div className="card-title">
                <h5>You have  Added {stateList && stateList.length} nos States</h5>
                <Button onClick={()=> handleAddOpen()} className="badge btn-xs pt-1">Add New State</Button>
              </div>
              <div>
                <table className='table tabe-bordered'>
                    <thead>
                        <tr>
                            <th>SL</th>
                            <th>State Name </th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {stateList &&  stateList.map((item,index)=> {
                          return(
                          <tr key={index}>
                            <td>{index + 1}. </td>
                            <td>{item.name}</td>
                            <td className="text-center">
                              { item &&  item.status == 1 ?
                              <Badge bg="success">Enable</Badge>:
                              <Badge bg="danger">Disable</Badge>}
                            </td>
                            <td className="text-center">
                              <button onClick={()=>handleEdit(item) }  className='btn btn-xs btn-primary text-white'>Edit</button> &nbsp;
                              {/* <button onClick={()=>deleteCall(item)} className='btn btn-xs btn-danger text-white'>Delete</button> &nbsp; */}
                              {item && item.status == 1 ? 
                                <button onClick={()=>handleStatus(item) }  className='btn btn-xs btn-danger text-white'>Make Disable</button>:
                                <button onClick={()=>handleStatus(item) }  className='btn btn-xs btn-success text-white'>Make Enable</button>}                                  
                            </td>
                          </tr>
                          )
                        })}
                    </tbody>
                </table>
                { stateList && stateList  ?
                    <div className="col-md-12">
                    <Pagination
                    totalItemsCount={ paginate.total_data}
                    onChange={handlePageChange}
                    activePage={  paginate.current_page}
                    itemsCountPerPage={paginate.per_page}
                    // pageRangeDisplayed={10}
                    />
                </div>
                : <></>
                }
              </div>
              {/* Delete Cateory */}
              <Modal size="md" show={addStateModal} centered>
                  <Modal.Header className="py-1">
                      <Modal.Title className="h6">State Add  <br /><span className="text-danger text-capitalize fw-bold"></span></Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>New State Name</Form.Label>
                        <Form.Control size="sm" type="text" placeholder="Enter State Name"
                          onChange={e=> setNewState({newState , name:e.target.value})}
                        />
                        {errors && errors ? <p style={{color : 'red' , fontSize : '12px'}}>{errors}</p> : '' }
                          </Form.Group>
                  </Modal.Body>
                  <Modal.Footer className="p-1 justify-content-start">
                      <button onClick={()=> handleAddClose()} className="btn btn-light btn-sm">Close</button>
                      <button onClick={()=> addState()} className="btn btn-primary btn-sm"  >
                          Submit
                      </button>
                  </Modal.Footer>
              </Modal> 
              <Modal size="md" show={newStatus} centered>
                  <Modal.Header className="py-1">
                      <Modal.Title className="h6">Do you want to  {
                          newStatus && newStatus.status == 1 ?  'Disable' : 'Enable'
                        }  <br /><span className="text-danger text-capitalize fw-bold"></span></Modal.Title>
                  </Modal.Header>
                  <Modal.Footer className="p-1 justify-content-start">
                      <button type="button" onClick={()=> handleStatusClose()} className="btn btn-light btn-sm">Close</button>
                      <button onClick={()=> statusUpdate()} className="btn btn-primary btn-sm"  >
                      {newStatus && newStatus.status == 1 ?  'Make Disable' : 'Make Enable'}
                      </button>
                  </Modal.Footer>
              </Modal> 
              {/*  */}
              <Modal size="md" show={editStateModal} centered>
                  <Modal.Header className="py-1">
                      <Modal.Title className="h6">State Edit  <br /><span className="text-danger text-capitalize fw-bold"></span></Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>New State Name</Form.Label>
                        <Form.Control size="sm" type="text" placeholder="Enter State Name"
                          onChange={e=> setNewEditState({newEditState , name:e.target.value})}
                          defaultValue={editNewState && editNewState.name}
                        />
                        {errors && errors ? <p style={{fontSize : '12px' , color : 'red'}}>{errors.name}</p>: '' }
                          </Form.Group>
                  </Modal.Body>
                  <Modal.Footer className="p-1 justify-content-start">
                      <button onClick={()=> handleClose()} className="btn btn-light btn-sm">Close</button>
                      <button onClick={()=> editState()} className="btn btn-primary btn-sm"  >
                          Submit
                      </button>
                  </Modal.Footer>
              </Modal> 

            </div>
          </div>
        </div>
      </div>
      </>
      }
    </div>

  );
}

export default State;
