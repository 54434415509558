import React, { useEffect, useState } from "react";
import { Outlet, Link , useNavigate } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import SideBar from "../../component/sidebar";
import { FaBars, FaAngleDown } from "react-icons/fa";
import { AiOutlinePoweroff, AiOutlineKey } from "react-icons/ai";
import Badge from 'react-bootstrap/Badge';
import Loader from '../../component/loader';
import Cookies from "js-cookie";
import { decrpt } from "../../component/encryption";
import axios from "axios";
import Pagination from "react-js-pagination";



function OrderList() {  
  const navigate = useNavigate();
  const [sidebarClose,setSidebarClose] = useState(false)
  const [loader,setLoader] = useState(false);
  const [orderList , setOrderList] = useState('');
  const [paginate , setPaginate] = useState();
  


  const filterDetails =(item ) => {
    // console.log(item, 'cndd')
    navigate('./single' ,{state : {item}});
  }

  const orderListFetch = async() => {
    try {
    setLoader(true);
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      const response = await axios.post(`/admin/orders/list`)
      console.log(response , 'category list');
      setOrderList(response.data.data.list);
      setPaginate(response.data.data.pagination)
      setLoader(false);
    } catch (error) {
      console.error(error);
    }
  }
  
  const handlePageChange = async (pageNumber) => {
    setLoader(true);
    // console.log(`active page is ${pageNumber}`);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/admin/orders/list?page=${pageNumber}`)
      console.log(response,'response active');
      if(response.data.success) {
        setOrderList(response.data.data.list);
        setLoader(false);
      }else{
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }



  useEffect (() => {
    window.scrollTo(0, 0);
    orderListFetch();
  }, []);


  return (
    <div className="main-content">
      {loader ? 
        <Loader />
        :
        <>
          {/* New User Registration */}
          <div className="section">
            <div className="row">
              {/* Past Order */}
              <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <h5>All Order Items</h5>
                  </div>
                  <div>
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th style={{maxWidth:350}}>User Name</th>
                                <th>Order UUID</th>
                                <th className="text-center">Date of Order</th>
                                <th className="text-center">Order Status</th>
                                <th className="text-center">Order Amount</th>
                                <th className="text-center">Total Items</th>
                                <th className="text-center">Payment Type</th>
                                <th className="text-center">Payment Status</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                          { orderList && orderList.map((item,index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.id}</td>
                                        <td>{item.user_name}</td>
                                        <td>{item.order_uuid}</td>
                                        <td className="text-center">{item.order_date}</td>
                                        <td className="text-center">
                                        { item.order_status == 1 ? <Badge bg="primary"> New</Badge> : 
                                        (item.order_status == 2)  ? <Badge bg="success"> Accepted </Badge> : 
                                        (item.order_status == 6)  ? <Badge bg="danger"> Canceled </Badge> : 
                                        (item.order_status == 3) ? <Badge bg="warning"> Dispatch </Badge> : 
                                        (item.order_status == 5) ? <Badge bg="warning"> Delivered </Badge> : 
                                        (item.order_status == 4) ? <Badge bg="secondary"> Out For Delivered</Badge> : 
                                        <Badge bg="success"> Cancelled By admin</Badge> }
                                        </td>
                                        <td className="text-center">₹ {item.overall_price}.00</td>
                                        <td className="text-center"> {item.total_items}</td>
                                        <td className="text-center ">
                                          {item.payment_type == 'COD' ? <Badge className="text-dark" bg="warning"> Cash On delivery</Badge> : <Badge className="text-light" bg="primary"> Online Payment</Badge>}
                                        </td>
                                        <td className="text-center ">
                                          {item.payment_status == 1 ? <Badge className="text-light" bg="success"> Pending</Badge> : (item.payment_status == 2) ? <Badge className="text-light" bg="primary"> Paid</Badge> 
                                          :   <Badge className="text-light" bg="danger"> Failed</Badge>
                                        }
                                        </td>
                                        <td className="text-center">
                                           <button onClick={() => filterDetails(item.id)} className="btn btn-xs btn-primary text-white">View Order Detail</button>
                                        </td>
                                    </tr>
                                );
                              })
                          }
                        </tbody>
                    </table>
                    {
                      orderList && orderList.length > 0 ?
                       <>
                    <div className="col-md-12">
                        <Pagination
                          totalItemsCount={ paginate && paginate.total_data}
                          onChange={handlePageChange}
                          activePage={  paginate && paginate.current_page}
                          itemsCountPerPage={paginate && paginate.per_page}
                          pageRangeDisplayed={10}
                        />
                    </div>  </>
                        : <></>
                    
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default OrderList;
