import React, { useContext, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { AiOutlineDashboard, AiOutlineUser, AiOutlineSetting, AiOutlinePoweroff } from "react-icons/ai";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BiBorderAll  } from "react-icons/bi";
import { BsLink45Deg } from "react-icons/bs";
import { RiProductHuntLine, RiListSettingsFill } from "react-icons/ri";
import { MdSettingsCell } from "react-icons/md";
import AuthUser from "../context/auth/AuthUser";
import Cookies from 'js-cookie'


function SideBar() {  
    const Auth = React.useContext(AuthUser);
    const [sidebarClose,setSidebarClose] = useState(false)
    const [ menuList, setMenuList ] = useState({
        activeMenu:1,
        menu:[
            {
                key:1,
                link:'./',
                icon:<AiOutlineDashboard className="menu-icon" /> ,
                name: 'Dashboard'
            },
            {
                key:2,
                link:'user',
                icon:<AiOutlineUser className="menu-icon" /> ,
                name: 'User'
            },
            {
                key:3,
                link:'seller',
                icon:<HiOutlineUserGroup className="menu-icon" /> ,
                name: 'Seller'
            },
            {
                key:4,
                link:'order',
                icon:<BiBorderAll className="menu-icon" /> ,
                name: 'Order'
            },
            {
                key:5,
                link:'product',
                icon:<RiProductHuntLine className="menu-icon" /> ,
                name: 'Products'
            },
            {
                key:6,
                link:'master',
                icon:<RiListSettingsFill className="menu-icon" /> ,
                name: 'Master'
            },
            {
                key:7,
                link:'maping',
                icon:<BsLink45Deg className="menu-icon" /> ,
                name: 'Mapping'
            },
            {
                key:8,
                link:'setting',
                icon:<AiOutlineSetting className="menu-icon" /> ,
                name: 'Settings'
            },
            {
                key:9,
                link:'user',
                icon:<MdSettingsCell className="menu-icon" /> ,
                name: 'App Configuration'
            },
            // i will have to check app configurtation
        ]
    })

    const linkHandler = (key) => {
        console.log('Key',key);
        setMenuList({...menuList,activeMenu:key});
    }

    const logoutHandler = () => {
        Auth.setAuth(false);
        Cookies.remove('s_l');
    }
  
  return (
    <aside className="sidebar">
        <div className="side-bottom">
        <div className="logo-header">
            <img src={require('../assets/logo23.webp')} />
        </div>
            <div className="admin-logo d-flex">
                <img src={require('../assets/man.png')} />
                <div>
                    <h5>
                        Welcome, <br/>
                        {/* <span style={{color:"#fff",fontS}}>Admin</span> */}
                    </h5>
                    <h3  style={{marginTop:15,color:"#fff",}}>Admin</h3>
                </div>
            </div>
            <div className="scrollable-block">
                <div className="main-menu">
                    <ul>
                        {menuList.menu.map((elements) => {
                            return(
                                <li key={elements.key} className={(menuList.activeMenu === elements.key) ? "active": ''} onClick={()=>linkHandler(elements.key)}>
                                    <Link to={elements.link}>{elements.icon} {elements.name}</Link>
                                </li>
                            )
                        })}
                        <li style={{display:'block',cursor:'pointer'}} onClick={()=>logoutHandler()}>
                            <AiOutlinePoweroff className="menu-icon" /> Logout
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </aside>

  );
}

export default SideBar;
