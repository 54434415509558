import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Loader from '../../component/loader';
import { BiCategory, BiFilterAlt } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import Pagination from "react-js-pagination";
import { decrpt } from "../../component/encryption";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Size() {  
  const [loader,setLoader] = useState(false)
  const [show,setShow] = useState(false)
  const [dshow,setDShow] = useState(false)
  const [ errors, setErrors ] = useState(false);
  const [Eshow,setEShow] = useState(false);
  const [ toastText,setToastText ] = useState('');
  const [paginate , setPaginate] = useState();
  const [sizeList , setSizeList] = useState();
  const [statusShow , setStatusShow] = useState();
  const [newUpdateStatus , setNewUpdateStatus] = useState();
  const [newSize , setNewSize ] = useState({
    id : '',
    name : ''
  });
  const [editSize , setEditSize] = useState({
    id    : '',
    name : ''
  });

  console.log(editSize , 'editSize.name')
// console.log(newSize  , 'newSize ');
  const handleOpen = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleCloseDelete = () => {
    setDShow(false);
  };

  const editCall = (item) => {
    console.log(item , 'check itme');
    setEShow(true);
    setEditSize({
      ...editSize,
      id    : item.id,
      name : item.name
    })
    console.log(editSize , 'check itme');

  };

  const handleCloseEdit = () => {
    setEShow(false);
  };

  const handleStatus = (item)=> {
    setStatusShow(true);
    setNewUpdateStatus(item);
  }
  const handleStatusClose = () =>{
    setStatusShow(false);
  }


// add size

const addSize = async() => {
  setLoader(true);
  const data = {
    name  : newSize.name
  }
  try {
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`/admin/master/size/save` , data)
    .then((response)=>{
      if(response.data.success){
        setLoader(true);
        handleClose();
        let newData = response.data.data;
        sizeList.unshift(newData);
        setEShow(false);
        setLoader(false);
        setToastText("Size Added")
        toast.success(toastText,{hideProgressBar:true})
      }
    }).catch((err)=>{
      console.error('Error',err);
      setErrors(err.response.data.errors);
      setToastText("Something went wrong")
      toast.danger(toastText,{hideProgressBar:true})
      setEShow(false);
      setLoader(false);
      setShow(true)
    })
  } catch (error) {
    console.error(error);
    setEShow(false);
    setShow(true);
    setShow(true);
    setLoader(false);
   
}
}

// add size

  const fetchSizeList = async () => {
    setLoader(true);
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`/admin/master/size/list` )
    .then((response) => {
      setSizeList(response.data.data.list);
      setPaginate(response.data.data.pagination);
      console.log(response.data.data);
      setLoader(false);
    }).catch((err) => {
      console.log('seller list error' , err.response)
      setLoader(false);
    })
  }
// pagination
const handlePageChange = async (pageNumber) => {
  setLoader(true);
  console.log(`active page is ${pageNumber}`);
  const token = Cookies.get('s_l');  
  const newToken = decrpt(token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
  const response = await axios.post(`/admin/master/size/list?page=${pageNumber}`, {type: 1} )
  .then((response) => {
    setSizeList(response.data.data.list);
    setLoader(false);
    console.log(response.data);
  }).catch((err) => {
    console.log('seller list error' , err.response)
    setLoader(false);
  })
}

const statusUpdate = async()=> {
  setLoader(true);
  let newstatus = '';
  if (newUpdateStatus.status == 1) {
    newstatus = 2;
  } else  {
    newstatus = 1;
  }
  const data = {
    id: newUpdateStatus.id,
    status : newstatus
  }
  try {
      const token = Cookies.get('s_l');
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      const response = await axios.post(`/admin/master/size/status`, data ).then((response) => {
        console.log('Response',response);
        let tmparray = sizeList;
        let obj = tmparray.find((o, i) => {
          if (o.id === newUpdateStatus.id) {
              tmparray[i] = {...sizeList[i] ,   status : newstatus  };
              return true; 
            }
          });
          setStatusShow(false);
          setToastText("Category Status Updated")
          toast.success(toastText,{hideProgressBar:true})
          setLoader(false)
      })
  }catch(error) {
      console.log(error);
      setToastText("Category Status Updated")
      toast.danger(toastText,{hideProgressBar:true})
      setLoader(false);
  }
}

const updateSize = async() => {
  setLoader(true);
  const data = {
    id    : editSize.id,
    name  : editSize.name
  }
  console.log(data , 'check data');
  try {
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`/admin/master/size/save` , data)
    .then((response)=>{
      if(response.data.success){
        setLoader(true);
        handleClose();
        let newData = response.data.data;
        console.log(newData , 'check update api response');
        console.log(sizeList , 'check update api response');
        let tmparray = sizeList;
        let obj = tmparray.find((o, i) => {
          if (o.id === newData.id) {
              tmparray[i] = {...sizeList[i] , ...newData };
              console.log('true',tmparray[i])
                return true; 
            }
          });
        setEShow(false);
        setLoader(false);
        setToastText("Size Added")
        toast.success(toastText,{hideProgressBar:true})
      }
    }).catch((err)=>{
      console.error('Error',err);
      setToastText("Something went wrong")
      toast.danger(toastText,{hideProgressBar:true})
      setEShow(false);
      setLoader(false);

    })
  } catch (error) {
    console.error(error);
    setEShow(false);
  }
}
// pagination
  useEffect (() => {
    window.scrollTo(0, 0);
    fetchSizeList();
  }, []);

  return (
    <div className="main-content">
      {loader ? 
        <Loader />
        :
        <>
      <ToastContainer position="top-center"  autoClose={3000}  hideProgressBar={false}  newestOnTop={false}  closeOnClick  rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'colored'}/>      

          {/* New User Registration */}
          <div className="section">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                  <h5><BiCategory className="me-1" />All Sizes</h5>
                </div>
                <div className="card p-2 mt-3">
                  <div className="card-title">      
                    <h5>You have {sizeList && sizeList.length} Sizes</h5>            
                    <Button onClick={()=> handleOpen()} className="badge btn-xs pt-1">Add New Sizes</Button>
                  </div>
                  <div>
                    <table className='table tabe-bordered'>
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Size</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                          {
                            sizeList && sizeList.length > 0 ? sizeList.sort((a, b) => a.id > b.id ? 1:-1).map((item,index) => {
                              return (
                                <>
                                <tr key={index}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td className="text-center">
                                      { item &&  item.status == 1 ?
                                      <Badge bg="success">Enable</Badge>:
                                      <Badge bg="danger">Disable</Badge>}
                                    </td>
                                    <td className="text-center">
                                      <button onClick={()=>editCall(item)} className='btn btn-xs btn-primary text-white'>Edit</button> &nbsp;
                                      {item && item.status == 1 ? 
                                <button onClick={()=>handleStatus(item)} className='btn btn-xs btn-danger text-white'>Make Disable</button>:
                                <button onClick={()=>handleStatus(item)} className='btn btn-xs btn-success text-white'>Make Enable</button>}                                  
                          
                                    </td>
                                </tr>
                                </>
                              )
                            }) 
                            :
                            <>
                            <tr>
                                <td>No data found </td>
                            </tr>
                            </>
                          }
                        </tbody>
                    </table>
                    { sizeList && sizeList  ?
                    <div className="col-md-12">
                    <Pagination
                    totalItemsCount={ paginate.total_data}
                    onChange={handlePageChange}
                    activePage={  paginate.current_page}
                    itemsCountPerPage={paginate.per_page}
                    // pageRangeDisplayed={10}
                    
                    />
                </div>
                : <></>
                }
                  </div>

                  {/* Add Cateory */}
                  <Modal size="md" show={show} onHide={handleClose}>
                      <Modal.Header className="py-1" closeButton>
                          <Modal.Title>Add Size</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <Form>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Add size Name</Form.Label>
                                <Form.Control size="sm" type="text" placeholder="Enter Category Name" 
                                defualtValue={newSize && newSize}
                                onChange={e =>setNewSize({...newSize , name:e.target.value}) }
                                  />
                                  {errors && errors ? <p style={{fontSize : '12px' , color : 'red'}}>{errors.name}</p> : ''}
                              </Form.Group>
                          </Form>
                      </Modal.Body>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button onClick={()=>handleClose()} className="btn btn-light btn-sm">
                              Close
                          </button>
                          <button onClick={()=>addSize()} className="btn btn-primary btn-sm">
                              Save Changes
                          </button>
                      </Modal.Footer>
                  </Modal> 

                  {/* Delete Cateory */}
                  <Modal size="md" show={statusShow} onHide={handleCloseDelete} centered>
                      <Modal.Header>
                          <Modal.Title className="h6 fw-bolder">Do you want to {
                          newUpdateStatus && newUpdateStatus.status == 1 ?  'Disable' : 'Enable'
                        }  this size ?</Modal.Title>
                      </Modal.Header>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button onClick={()=>handleStatusClose()} className="btn btn-light btn-sm">
                              Close
                          </button>
                          <button onClick={()=>statusUpdate()} className="btn btn-primary btn-sm">
                          {newUpdateStatus && newUpdateStatus.status == 1 ?  'Make Disable' : 'Make Enable'}
                          </button>
                      </Modal.Footer>
                  </Modal> 

                  {/* Edit Cateory */}
                  <Modal size="md" show={Eshow}>
                      <Modal.Header>
                          <Modal.Title>Edit Size</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <Form>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Edit size Name</Form.Label>
                                <Form.Control size="sm" type="text" placeholder="Enter Category Name" 
                                defaultValue={editSize && editSize.name}
                                onChange={e =>setEditSize({...editSize , name:e.target.value}) }
                                  />
                                  {errors && errors ? <p style={{fontSize : '12px' , color : 'red'}}>{errors.name}</p> : ''}
                              </Form.Group>
                          </Form>
                      </Modal.Body>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button onClick={()=>handleCloseEdit()} className="btn btn-light btn-sm">
                              Close
                          </button>
                          <button onClick={()=>updateSize()} className="btn btn-primary btn-sm">
                              Save Changes
                          </button>
                      </Modal.Footer>
                  </Modal> 

                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default Size;
