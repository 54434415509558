import React from 'react';
import Badge from 'react-bootstrap/Badge';

function datatable(props) {
    console.log(props , 'props new seller');
    return (
        <div>
            <table className='table tabe-bordered'>
                <thead>
                    <tr>
                        <th>SL</th>
                        <th>Name</th>
                        <th>Date of Joining</th>
                        {/* <th>Action</th> */}
                    </tr>
                </thead>
                <tbody>
                    {props.newSeller && props.newSeller.map((item,index) => {
                        return (
                    <tr key={index}>
                        <td>{index + 1} </td>
                        <td>{item.name}</td>
                        <td>    { item &&  item.status == 1 ?
                              <Badge bg="success">Enable</Badge>:
                              <Badge bg="danger">Disable</Badge>}</td>
                        {/* <td><a className='btn btn-xs btn-success text-white'>View</a></td> */}
                    </tr>
                        );
                    }) }
                </tbody>
            </table>
        </div>
    );
}

export default datatable;