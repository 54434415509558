import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Loader from '../../component/loader';
import { BiCategory, BiFilterAlt } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import Cookies from 'js-cookie'
import { decrpt } from '../../component/encryption';
import Pagination from "react-js-pagination";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SubCategory() {  
  const  [loader,setLoader ] = useState(false);
  const [disabled , setDisabled ] = useState(true);
  const [ show,setShow ] = useState(false);
  const [ errors , setErrors ] = useState();
  const [ Eshow,setEShow] = useState(false);
  const [ toastText,setToastText ] = useState('');
  const [ category, setCategory ] = useState();
  const [ subCategoryPreview, setSubCategoryPreview ] = useState({
    image : '',
    banner_image : ''
  });
  const [paginate , setPaginate] = useState();
  const [ subCategory, setSubCategory ] = useState();
  const [ editAddCategory, setAddEditCategory ] = useState();
  const [ editSubCategory, setEditSubCategory ] = useState();
  const [updateStatus , setUpdateStatus] = useState();
  const [statusShow , setStatusShow] = useState();
  const [ newSubCategory, setNewSubCategory ] = useState({
    id:'',
    name:'',
    category_id:'',
    category_name:'',
    level:'2',
    image:'',
    banner_image:'',
  });
  console.log(errors , 'newSubCategory');
  const [subCategorySelected , setSubCategorySelected ] = useState({
    id:'',
    name:'',
    category_id:'',
    level:'2',
    image:'',
    banner_image:'',
    });
  const handleOpen = (item) => {
    setNewSubCategory(item);
    setDisabled(true);
    setShow(true);
    setSubCategoryPreview({
      image : '',
      banner_image : ''
    });
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleStatusClose = () =>{
    setStatusShow(false);
  }
  const editCall = (item) => {
    setEditSubCategory(item);
    setNewSubCategory(item);
    setSubCategorySelected(item);
    setEShow(true);
    console.table(editSubCategory , 'editSubCategory');
  };

  const handleCloseEdit = () => {setEShow(false);};

  const textHandler = (e) => {
    setDisabled(false);
    setNewSubCategory({...newSubCategory, name:e.target.value})
  };

  const subCategoryHandler = (e) => {
    setNewSubCategory({...newSubCategory, category_id:e.target.value})
    console.log(editAddCategory , 'newSubCategory');
    setDisabled(false);
  };

  function handlePreview(e) {
    setSubCategoryPreview({...subCategoryPreview , image:URL.createObjectURL(e.target.files[0])});
    setNewSubCategory({...newSubCategory, image:e.target.files[0]});
    console.log(newSubCategory , 'categoryPreview');
    }
  function handleBannerPreview(e) {
    setSubCategoryPreview({...subCategoryPreview , banner_image:URL.createObjectURL(e.target.files[0])});
    setNewSubCategory({...newSubCategory, banner_image:e.target.files[0]});
    console.log(newSubCategory , 'categoryPreview');
    }
  const handleStatus = (item) => {
    setStatusShow(true);
    setUpdateStatus(item);
  }

  // Get Category List
  const getCategoryList = async() => {
    try {
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      // console.log(newToken);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      const response = await axios.post(`admin/category/list`, {type:1})
      // console.log(response.data);
      setCategory(response.data.data.list)
    } catch (error) {
      console.error(error);
    }
  }

  // Get Category List
  const getSubCategoryList = async() => {
    try {
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      // console.log(newToken);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      const response = await axios.post(`admin/category/list`, {type:2})
      console.log(response.data , 'subcat list');
      setSubCategory(response.data.data.list)
      setPaginate(response.data.data.pagination);
      console.log( response.data.data.list , 'response.data.data.list');
    } catch (error) {
      console.error(error);      
    }
  }

  // Add Category
  const addSubCategory = async() => {
    setLoader(true);
    try {
      const formData = new FormData();
      formData.append("level", 2);
      formData.append("name", newSubCategory.name);
      formData.append("category_id", newSubCategory.category_id);
      formData.append("image", newSubCategory.image);
      formData.append("banner_image", newSubCategory.banner_image);
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      const response = await axios.post(`admin/category/save`, formData)
      .then((response)=>{
        // console.log(newSubCategory , 'newSubCategory');
        // setSubCategory(response.data.data.list);
        if(response.data.success){
          handleClose();
          let newData = response.data.data;
          subCategory.unshift(newData);
          getSubCategoryList();
          setToastText("Sub Category Added")
          toast.success(toastText,{hideProgressBar:true})
          setSubCategoryPreview('');
          setLoader(false);
        }
      }).catch((err)=>{
        console.error('Error',err);
        setErrors(err.response.data.errors)
        setLoader(false);
        setToastText("Something went wrong")
        toast.error(toastText,{hideProgressBar:true})
      })
      // setcategory(response.data.data.list)
    } catch (error) {
      console.error(error);    
      setErrors('sub category required')
      setLoader(false);
    }
  }
  const handlePageChange = async (pageNumber) => {
    setLoader(true);
    console.log(`active page is ${pageNumber}`);
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`admin/category/list?page=${pageNumber}`, {type: 2} )
    .then((response) => {
      setSubCategory(response.data.data.list);
      setLoader(false);
    }).catch((err) => {
      console.log('seller list error' , err.response)
      setLoader(false);
    })
  }

  const categoryStatus = async() => {
    setLoader(true);
    try {
      let newstatus = '';
      if (updateStatus.status == 1) {
        newstatus = 2;
      } else {
        newstatus = 1;
      }
      const data = {
        id: updateStatus.id,
        status  : newstatus
      }
      console.log(data , 'data of api getc')
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
      const response = await axios.post(`admin/category/status` , data)
      .then((response) => {
        // console.log(response.data , 'categoryStatus api fetch');
        let tmparray = subCategory;
        let obj = tmparray.find((o, i) => {
            if (o.id === updateStatus.id) {
              tmparray[i] = {...subCategory[i] ,   status : newstatus  };
              console.log('true',tmparray[i])
                return true; 
            }
        });
      })
      setStatusShow(false);
      setToastText("Status Updated");
      toast.success(toastText,{hideProgressBar:true})
    setLoader(false);
    }catch(error) {
        console.log(error, 'error');
        setLoader(false);
    }
  }

  const updateSubCategory = async() => {
    setLoader(true);
    const formData = new FormData();
    formData.append("id", newSubCategory.id);
    formData.append("level", 2);
    formData.append("category_id", newSubCategory.category_id);
    formData.append("name", editSubCategory.name);
    formData.append("image", newSubCategory.image);
    formData.append("banner_image", newSubCategory.banner_image);
    try {
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
      const response = await axios.post(`admin/category/save` , formData )
      .then((response) => {
        let newvar = response.data.data;
        console.log(newvar , 'updateSubCategory data')
          console.log(subCategory , 'subCategory');
          let tmparray = subCategory;
          let obj = tmparray.find((o, i) => {
              if (o.id === newvar.id) {
                tmparray[i] = {...subCategory[i] , ...newvar};
                console.log('true',tmparray[i])
              }
          });
          getSubCategoryList();
          setToastText("Category Name Updated");
          toast.success(toastText,{hideProgressBar:true})
          setLoader(false);
          setEShow(false);
      })
    }catch(error) {
        console.log(error);
        setLoader(false);
        setEShow(true);
        setErrors(error.response.data.errors)
        setToastText("Something Went wrong");
        toast.error(toastText,{hideProgressBar:true})
    }
  }

  useEffect (() => {
    window.scrollTo(0, 0);
    setLoader(true);
    setTimeout(async() => {  
    await getSubCategoryList();      
    await getCategoryList();    
      setLoader(false);
    }, 500);
  }, []);
  return (
    <div className="main-content">
      {loader && loader ?
       <Loader />
      : 
      <>
      <ToastContainer position="top-center"  autoClose={3000}  hideProgressBar={false}  newestOnTop={false}  closeOnClick  rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'colored'}/>      
      {/* New User Registration */}
      <div className="section">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <h5><BiCategory className="me-1" />All Sub Category</h5>
            </div>
            <div className="card p-2 mt-3">
              <div className="card-title">      
                <h5>You have {subCategory && subCategory.length > 0 ? subCategory.length : 'No'} numbers of 2nd Level Category</h5>                            
                <Button onClick={()=> handleOpen()} className="badge btn-xs pt-1">Add New Sub Category</Button>
              </div>
              <div>
                <table className='table tabe-bordered'>
                    <thead>
                        <tr>
                            <th>SL</th>
                            <th>Sub Category</th>
                            <th>Category</th>
                            <th>Image</th>
                            <th>Banner Image</th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subCategory && subCategory.length > 0 ? subCategory.map((item,index)=> {
                          return(
                            <tr>
                              <td>{index + 1}. </td>
                              <td>{item && item.name}</td>
                              <td>{item && item.category_name}</td>
                              <td> <img src={item.image} style={{ width: 100,height: 100 }} /> </td>
                              <td> <img src={item.banner_image} style={{ width: 100,height: 100 }} /> </td>
                              <td className="text-center">
                              {item && item.status == 1 ?
                                <Badge bg="success">Enable</Badge>:
                                <Badge bg="danger">Disable</Badge>}
                              </td>
                              <td className="text-center">
                                <button onClick={()=>editCall(item)} className='btn btn-xs btn-primary text-white'>Edit</button> &nbsp;
                                {/* <button onClick={()=>deleteCall()} className='btn btn-xs btn-danger text-white'>Delete</button> &nbsp; */}
                                {item && item.status == 1 ?
                                  <button type="button" onClick={()=>handleStatus(item)} className='btn btn-xs btn-danger text-white'>Make Disable</button>:
                                  <button type="button" onClick={()=>handleStatus(item)} className='btn btn-xs btn-success text-white'>Make Enable</button>}  
                              </td>
                            </tr>
                            )
                          }):
                            <tr>
                              <td colSpan={6} className="text-center fst-italic text-danger">no category added</td>
                            </tr>
                          }
                    </tbody>
                </table>
                { subCategory && subCategory  ?
                    <div className="col-md-12">
                    <Pagination
                    totalItemsCount={ paginate.total_data}
                    onChange={handlePageChange}
                    activePage={  paginate.current_page}
                    itemsCountPerPage={paginate.per_page}
                    />
                </div>
                : <></>
                }
              </div>
              {/* Add Cateory */}
              <Modal size="md" show={show} onHide={handleClose}>
                  <Modal.Header className="py-1" closeButton>
                      <Modal.Title>Add Sub Category</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Sub Category Image </Form.Label>
                          <Form.Control type="file" onChange={handlePreview} />
                        {errors && errors ? <p style={{fontSize : 12 , color : 'red'}}> {errors.image} </p> : '' }
                      </Form.Group>
                            <img src={subCategoryPreview && subCategoryPreview.image  ? subCategoryPreview.image : '/folder.png' } style={{ width: 100,height: 100 }} />
                        {/*  */}
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Sub Category banner Image </Form.Label>
                            <Form.Control type="file" onChange={handleBannerPreview} />
                        {errors && errors ? <p style={{fontSize : 12 , color : 'red'}}> {errors.banner_image} </p> : '' }
                          </Form.Group>
                            <img src={subCategoryPreview && subCategoryPreview.banner_image !== '' ? subCategoryPreview && subCategoryPreview.banner_image: '/folder.png'} style={{ width: 100,height: 100 }} />
                         
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Cartegory name</Form.Label>
                            {/* <Form.Select aria-label="Default select example"  onChange={e =>  setNewSubCategory({...newSubCategory, category_id:e.target.value , setDisabled(false)})}> */}
                            <Form.Select aria-label="Default select example"  onChange={subCategoryHandler}>
                              <option selected disabled >----- Select From Below -----</option>
                              {category && category.map((item,index) => {
                                return <option key={index}  value={item.id}>{item.name}</option>
                              })}
                            </Form.Select>
                          </Form.Group>
                        {errors && errors.category_id ? <p style={{fontSize : 12 , color : 'red'}}> The category Field is required </p> : '' }
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>Sub Category Name</Form.Label>
                              <Form.Control required size="sm" type="text" placeholder="Enter Category Name" onChange={ e => textHandler(e)}  disabled={disabled} />
                        {errors && errors ? <p style={{fontSize : 12 , color : 'red'}}> {errors} </p> : '' }
                          </Form.Group>
                      </Form>
                  </Modal.Body>
                  <Modal.Footer className="p-1 justify-content-start">
                      <button onClick={()=>handleClose()} className="btn btn-light btn-sm">
                          Close
                      </button>
                      <button onClick={()=>addSubCategory()} className="btn btn-primary btn-sm">
                          Save Changes
                      </button>
                  </Modal.Footer>
              </Modal> 
              {/* Edit Cateory */}
              <Modal size="md" show={Eshow}>
                  <Modal.Header>
                      <Modal.Title>Edit Sub Category</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Sub Category Image </Form.Label>
                          <Form.Control type="file" onChange={handlePreview} />
                            {errors && errors ? <p style={{fontSize : 12 , color : 'red'}}> {errors.image} </p> : '' }
                            </Form.Group>
                            <img src={subCategoryPreview && subCategoryPreview.image !== '' ? subCategoryPreview.image: subCategorySelected.image} style={{ width: 100,height: 100 }} />
                        {/*  */}
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Sub Category banner Image </Form.Label>
                            <Form.Control type="file" onChange={handleBannerPreview} />
                            {errors && errors ? <p style={{fontSize : 12 , color : 'red'}}> {errors.banner_image} </p> : '' }
                              </Form.Group>
                            <img src={subCategoryPreview && subCategoryPreview.banner_image !== '' ? subCategoryPreview.banner_image: subCategorySelected.banner_image} style={{ width: 100,height: 100 }} />
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Category</Form.Label>
                            <Form.Select   value={editSubCategory && editSubCategory.category_id }  aria-label="Default select example" onChange={e =>  setNewSubCategory({...newSubCategory, category_id:e.target.value})}>
                            <option  >----- Select From Below -----</option>
                              {category && category.map((item,index) => {
                                return <option key={index}  value={item.id}>{item.name}</option>
                              })}
                            </Form.Select>
                          {errors && errors.category_id ? <p style={{fontSize : 12 , color : 'red'}}> Please select category </p> : '' }
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>Sub Category Name</Form.Label>
                              <Form.Control size="sm" type="text" 
                              placeholder="Enter Sub Category Name"
                               defaultValue={editSubCategory && editSubCategory.name } 
                               onChange={ e => setEditSubCategory({...editSubCategory , name:e.target.value})}
                               />
                          {errors && errors ? <p style={{fontSize : 12 , color : 'red'}}> {errors.name} </p> : '' }
                          </Form.Group>
                      </Form>
                  </Modal.Body>
                  <Modal.Footer className="p-1 justify-content-start">
                      <button onClick={()=>handleCloseEdit()} className="btn btn-light btn-sm">
                          Close
                      </button>
                      <button onClick={()=>updateSubCategory()} className="btn btn-primary btn-sm">
                          Save Changes
                      </button>
                  </Modal.Footer>
              </Modal> 

              <Modal size="md" show={statusShow}  centered>
                  <Modal.Header className="py-1">
                      <Modal.Title className="h6">Do you want to  {
                          updateStatus && updateStatus.status == 1 ?  'Disable' : 'Enable'
                        } Sub category ? <br /><span className="text-danger text-capitalize fw-bold">{updateStatus && updateStatus.name}</span></Modal.Title>
                  </Modal.Header>
                  <Modal.Footer className="p-1 justify-content-start">
                      <button  onClick={()=> handleStatusClose()} className="btn btn-light btn-sm">
                          Close
                      </button>
                      <button  className="btn btn-primary btn-sm" onClick={() => categoryStatus()} >
                        {updateStatus && updateStatus.status == 1 ?  'Make Disable' : 'Make Enable'}
                      </button>
                  </Modal.Footer>
              </Modal> 

            </div>
          </div>
        </div>
      </div> 
      </>
      }     
    </div>
  );
}

export default SubCategory;
