import React, { useEffect, useState } from "react";
import {  Link ,useLocation } from "react-router-dom";
import { FaPhoneAlt, FaRegEnvelopeOpen, FaRegPaperPlane } from "react-icons/fa";
import Badge from 'react-bootstrap/Badge';
import Loader from '../../component/loader';
// import { Chart } from "react-google-charts";
import ProfileDetail from './component/profileDetail';
import { decrpt } from "../../component/encryption";
import Cookies from "js-cookie";
import axios from "axios";
import Pagination from "react-js-pagination";


// const data = [
//   ["Month", "User"],
//   ["Jan-Mar", 300],
//   ["Aprl-Jun", 500],
//   ["Jul-Sep", 450],
//   ["Oct-Dec", 837],
// ];

// const options = {
//   hAxis: { title: "Year", titleTextStyle: { color: "#333" } },
//   vAxis: { minValue: 0 },
//   chartArea: { width: "60%", height: "75%" },
// };

function SellerSingle() {  
  const [loader,setLoader] = useState(false);
  const [loaderInner , setLoaderInner] = useState(false);
  const [orderDetails , setOrderDetails] = useState();
  const [ dashData , setDashData] = useState();
  const [paginate , setPaginate] = useState();
  const location = useLocation();
 


  const fetchSellerDetails = async(registration_status) => {
    setLoader(true);
    const data = {
     id : location && location.state.item.id,
   }
   console.log(data, 'chekc data sjdsajd')
   let token = decrpt(Cookies.get('s_l'));
   axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
   const response = await axios.post(`/admin/seller/details`,data)
   .then((response) => {
     console.log(response.data , 'seller list');
     setOrderDetails(response.data.orders.list)
     setPaginate(response.data.orders.pagination)
     setDashData(response.data)
    setLoader(false);
    }).catch((err) => {
      console.log(err.response , 'seller list');
   })
 }

  useEffect (() => {
    window.scrollTo(0, 0);
    fetchSellerDetails();
  }, []);
  return (

    <div className="main-content">
      {loader ? 
        <Loader />
        :
        <>
          {/* New User Registration */}
          <div className="section">
            <div className="row">
        { location.state.item.business_details && location.state.item.business_details.id > 0 ?

        <>
              <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <h3 className="h1 fw-bold" style={styles.header}> {location.state.item.business_details.business_name}</h3>
                  {/* <div className="mb-2"><Badge bg="success">Enable</Badge></div> */}
                  <p className="mb-1">Contact Person : {location.state.item.name}  {location.state && location.state.item.gender ? (location.state.item.gender && location.state.item.gender == 'M' ?  '(Male)' : '(Female)') : null  }</p>
                  {/* chekck */}
                  <div className="d-flex">
                    <p className="mb-1"> <FaPhoneAlt /> {location.state.item.mobile}</p> &nbsp;  | &nbsp; 
                    <p className="mb-1"> <FaRegEnvelopeOpen />  {location.state.item.email}</p>
                  </div>
                  <p className="mb-0"><FaRegPaperPlane />  {location.state.item.address}</p>
                  <ProfileDetail 
                   location={location}
                  />
                  <div className="d-flex justify-content-evenly mt-1" style={{gap:10}}>
                    <div className="text-center" style={styles.box}>
                      <h3 style={styles.boxNumber}>{dashData && dashData.orders_delivered}</h3>
                      <h4 style={styles.boxHeading}>Order Delivered</h4>
                    </div>
                    <div className="text-center" style={styles.box}>
                      <h3 style={styles.boxNumber}>{dashData && dashData.cancelled_delivered}</h3>  
                      <h4 style={styles.boxHeading}>Cancelled Order </h4>
                    </div>
                    <div className="text-center" style={styles.box}>
                      <h3 style={styles.boxNumber}>{dashData && dashData.orders_delivered}</h3>  
                      <h4 style={styles.boxHeading}>Orders Delivered</h4>
                      {/* deliveredorder show   */}
                    </div>
                    <div className="text-center" style={styles.box}>
                      <h3 style={styles.boxNumber}>{dashData && dashData.total_orders}</h3>  
                      <h4 style={styles.boxHeading}>Total Orders</h4>
                    </div>
                    <div className="text-center" style={styles.box}>
                      <h3 style={styles.boxNumber}>{dashData && dashData.sum_amount}</h3>  
                      <h4 style={styles.boxHeading}>Total Delivered Amount</h4>
                    </div>
                  </div>

                </div>
              </div>
 
              {/* <div className="col-md-4">
                <div className="card mb-3">
                  <Chart
                    chartType="AreaChart"
                    width="100%"
                    height="300px"
                    data={data}
                    options={options}
                  />
                </div>
              </div> */}

              {/* Past Order */}
              <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <h5>All Order Items</h5>
                  </div>
                  <div>
                    {
                      loaderInner ?  <div className="loader-inner"><Loader /></div> :
                      <>
                        <table className='table '>
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th >Product Image</th>
                                    <th>User Name</th>
                                    <th className="text-center">Date of Order</th>
                                    <th className="text-center">Price</th>
                                    <th className="text-center">Items</th>
                                    <th className="text-center">Shipping Charge</th>
                                    <th className="text-center">Overall Price</th>
                                    <th className="text-center">Order Status</th>
                                    <th className="text-center">Delivered Date</th>
                                </tr>
                            </thead>
                            <tbody>
                              {
                                orderDetails && orderDetails.map((item,index) => {
                                  return (
                                        <tr key={index}>
                                            <td>{item.order_uuid} </td>
                                            <td> <img style={{height : 80 , width : 80 , backgroundColor : 'grey'}} src={item.product_image} /> </td>
                                            <td>{item.user_name}</td>
                                            <td className="text-center">{item.order_date}</td>
                                            <td className="text-center">₹ {item.total_price}</td>
                                            <td className="text-center">{item.total_items}</td>
                                            <td className="text-center">{item.shipping_charge}</td>
                                            <td className="text-center">{item.overall_price}</td>
                                            <td className="text-center">
                                            { item.order_status == 1 ? <Badge bg="primary"> New</Badge> : 
                                            (item.order_status == 2)  ? <Badge bg="success"> Accepted </Badge> : 
                                            (item.order_status == 6)  ? <Badge bg="danger"> Canceled </Badge> : 
                                            (item.order_status == 5)  ? <Badge bg="danger"> Delivered </Badge> : 
                                            (item.order_status == 3) ? <Badge bg="warning"> Dispatch </Badge> : 
                                            (item.order_status == 4) ? <Badge bg="secondary"> Out For Delivered</Badge> : 
                                            <Badge bg="success"> Cancelled By Admin</Badge> }
                                            </td>
                                            <td className="text-center">
                                              {item.order_status == 5 ?
                                                item.delivered_date : <Badge bg="primary"> In Proccess</Badge>  
                                            }
                                            </td>
                                        </tr>
                                      );
                                })
                              }
                            </tbody>
                        </table>
                        {
                      orderDetails && orderDetails.length > 0 ?
                       <>
                    <div className="col-md-12">
                        <Pagination
                        totalItemsCount={ paginate && paginate.total_data}
                        // onChange={handlePageChange}
                        activePage={  paginate && paginate.current_page}
                        itemsCountPerPage={paginate && paginate.per_page}
                        pageRangeDisplayed={10}
                        />
                    </div>  </>
                        : <></>
                    
                    }
                      </>
                    }
                  </div>
                </div>
              </div>
              </> 
              : 
                <div className="col-lg-6 m-auto">
                  <div className="card">
                      <div className="text-center p-5" >
                      <p>User Hasn't filled his Business Details</p>
                      <Link  to='..' className='btn  btn-secondary text-white' >back</Link> 
                      </div>
                  </div>
                </div>

        }
            </div>
          </div>
        </>
      }
    </div>

  );
}

export default SellerSingle;
const styles = {
  header: {textTransform: 'uppercase',color:'#149348',marginBottom:0},
  box : {background:'#e1e1e1',borderRadius:10, paddingLeft:10,paddingRight:10,padding:10,flex:1,boxShadow: '0 0 5px 0 #868686',border: '2px dotted #fff'},
  boxHeading : {color:'#333',fontWeight:'900',fontSize:15,marginBottom:0},
  boxNumber : {color:'green',fontWeight:'900',fontSize:25,marginBottom:0},
}
