import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import AuthUser from "../../context/auth/AuthUser";
import 'animate.css';
import Cookies from 'js-cookie'
import axios from 'axios'
import { encrpt } from '../../component/encryption';

function Login() {
  const Auth = React.useContext(AuthUser);
  const [errors, setErrors] = useState(false);
  const [data, setData] = useState({
    email : '',
    password : ''
  });

  const readCookie = () => {
    const token = Cookies.get('s_l');   
    if(token && token.length > 15){
      Auth.setAuth(true);
    }
  }

  const submitForm = async(event) => {
    event.preventDefault();
    setErrors(false);
     const response = await axios.post(`/admin/login`, data)
    .then((response)=>{
      console.log('Response',response.data);
      if(response.data.success){
        Auth.setAuth(true);
        let token = encrpt(response.data.token)
      console.log('Response',response.data.token);
        Cookies.set('s_l', token, { expires: 1 })
      }
    }).catch((err)=>{
      console.error('Error',err);
      setErrors(true);    
    })
  }

  useEffect (() => {
    window.scrollTo(0, 0);
    readCookie();
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        <div className="login-wrapper">
            <div className="form-header text-center">
              <img src={require('../../assets/logo2.png')} style={{width:'80%',marginBottom:-15}} />
              <h4>ADMIN PANEL</h4>
              <p>Login with your creandiatials</p>
            </div>
            <Form onSubmit={submitForm} className="position-relative">
              <div></div>
              {errors && 
              <p className="fw-bold text-danger m-0 text-center position-absolute w-100 animate__animated animate__headShake" style={{top:-15,fontSize:13}}>Username & Password doesn't match</p>
              }
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="align-self-start">Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" onChange={e=>setData({...data, email:e.target.value})} required />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label className="align-self-start">Password</Form.Label>
                <Form.Control type="password" placeholder="Password" onChange={e=>setData({...data, password:e.target.value})} required />
              </Form.Group>
              <hr/>
              <div >
                <Button variant="warning" type="submit" style={{backgroundColor:"red",width:"100%",color:"#fff",borderColor:"#fff"}}>
                  Login
                </Button>
              </div>
            </Form>
        </div>
      </header>
    </div>

  );
}

export default Login;
