import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';


function HelplineCompo(props) {
    console.log(props , 'propps')
    return (
        <div>
            <Modal
                size="md"
                show={props.helplineModal}
                onHide={() => props.setHelplineModal(false)}
                aria-labelledby="example-modal-sizes-title-lg" centered>
                <Modal.Header closeButton className='py-1'>
                    <Modal.Title id="example-modal-sizes-title-sm">Helpline Number</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <input type="number" onChange={ (e) => props.setHelpline(e.target.value)} className='form-control' 
                                defaultValue={props.helpline}
                            />
                            <small style={{color : 'red' , fontSize : 12}}>{props.errors && props.errors.number }</small>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="p-1 justify-content-start">
                        <button onClick={()=>props.setHelplineModal(false)} className="btn btn-light btn-sm">Close</button>
                        <button onClick={()=>props.helplineSave()} className="btn btn-primary btn-sm">Update</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default HelplineCompo;