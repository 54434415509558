import React, { useEffect, useState } from "react";
import { BrowserRouter , Route, Routes,Outlet } from "react-router-dom";
import SettingList from './list';
import SettingSlider from './slider';
import SettingBanner from './banner';

function Settings() {  
  const [loader,setLoader] = useState(false)
  useEffect (() => {
    window.scrollTo(0, 0);
    setLoader(true);
    setTimeout(() => {      
      setLoader(false);
    }, 500);
  }, []);
  return (
    <div>
      {/* <Routes>   
        <Route exact index path="/*" element={<SettingList />} />
        <Route exact path="/slider" element={<SettingSlider />} />
        <Route exact path="/banner" element={<SettingBanner />} />
      </Routes> */}
      <Outlet />
    </div>

  );
}

export default Settings;

