import React, {useState, useEffect, useContext} from "react";
import { BrowserRouter , Route, Routes, } from "react-router-dom";
import Home from '../screen/templete/';
// import User from '../screen/user/';
import '../App.css';
import '../Responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// auth context
import AuthUser from "../context/auth/AuthUser";
import InitialRoute from "../context/auth/InitialRoute";
// dashboard
import Dashboard from "../screen/dashboard";
// login 
import Login from "../screen/login";
//master
import Master from "../screen/master";
import Brand from "../screen/master/brand";
import Category from "../screen/master/category";
import SubCategory from "../screen/master/subCategory";
import LastCategory from "../screen/master/lastCategory";
import Page from "../screen/master/page";
import Size from "../screen/master/size";
import Filter from "../screen/master/filter";
import FilterDetails from "../screen/master/filterDetails";
import City from "../screen/master/city";
import State from "../screen/master/state";
import District from "../screen/master/district";
// policy ------------------
import Return from "../screen/master/returnPolicy";
import Cancellation from "../screen/master/cancellationPolicy";
import TaxMaster from "../screen/master/taxMaster";
// maping
import Maping from "../screen/maping";
import MapingList from "../screen/maping/maping";
import BrandMap from "../screen/maping/brandMap";
import SizeMap from "../screen/maping/sizeMap";
import FilterMap from "../screen/maping/filterMap";
// import List from "../screen/master/list";
// order 
import Order from "../screen/order";
import OrderList from "../screen/order/list";
import OrderSingle from "../screen/order/single";
 // products
 import Product from "../screen/product";
 import ProductList from "../screen/product/list";
 import ProductSingle from "../screen/product/single";
// seller
import Seller from "../screen/seller";
import SellerList from "../screen/seller/list";
import SellerSingle from "../screen/seller/single";
// settings
import Settings from "../screen/settings";
import SettingBanner from "../screen/settings/banner";
import SettingList from "../screen/settings/list";
import SettingSlider from "../screen/settings/slider";
// user 
import User from "../screen/user";
import UserList from "../screen/user/list";
import UserSingle from "../screen/user/single";
// stuff
import Cookies from "js-cookie";
// import SizeMap from "../screen/master/sizeMap";



function MainNavigation() {
const [auth,setAuth] = useState(false);
const [initialRoute, setInitialRoute] = useState(null);

const readCookie = () => {
  const initialRouteNew = Cookies.get('i_r');
  const token = Cookies.get('s_l');

  if(token && token.length > 15){
    setAuth(true);
  }
  if(initialRouteNew){
    setInitialRoute(initialRouteNew);
  }else{
    setInitialRoute(initialRouteNew);
  }
}
console.log(auth && auth , 'check auth')
useEffect(() => {
  readCookie();
},[]);
  return (
    <BrowserRouter>
      {/* <Routes>   
            <Route path=":index" >
                <Route path=":user">
                  <Route index path="list" element={<User />} />
                </Route>
            </Route>
      </Routes> */}
      <AuthUser.Provider value={{auth,setAuth}}>
        <InitialRoute.Provider value={{initialRoute,setInitialRoute}}>
            <Routes>
                <Route exact path="/" element={auth && auth == true ? <Home /> : <Login /> }>
                    {/* dashboard */}
                    <Route exact index path="/" element={<Dashboard /> } />
                    {/* user */}
                    <Route path="user" element={<User />}>
                        <Route index path="" element={<UserList />} />
                        <Route path="single" element={<UserSingle />} />
                    </Route>
                    {/* seller */}
                    <Route path="seller" element={<Seller />} >
                        <Route index path="" element={<SellerList /> } />
                        <Route path="single" element={<SellerSingle />} />
                    </Route>
                    {/* products */}
                    <Route path="product"  element={<Product />} >
                        <Route  index path="" element={<ProductList />} />
                        <Route path="single" element={<ProductSingle />} />
                    </Route>
                    {/* Master */}
                      <Route path="master" element={<Master />} >
                          <Route index path="" element={<Page />} />
                          <Route path="category" element={<Category />} />
                          <Route path="city" element={<City />} />
                          <Route path="state" element={<State />} />
                          <Route path="district" element={<District />} />
                          <Route path="sub_category" element={<SubCategory />} />
                          <Route path="last_category" element={<LastCategory />} />
                          <Route path="brands" element={<Brand />} />
                          <Route path="sizes" element={<Size />} />
                          <Route path="filter" element={<Filter />} />
                          <Route path="filterDetails" element={<FilterDetails />} />
                          <Route path="return_policy" element={<Return />} />
                          <Route path="cancel_policy" element={<Cancellation />} />
                          <Route path="tax_list" element={<TaxMaster />} />
                      </Route>
                      <Route path="maping" element={<Maping />} >
                          <Route exact index path=""   element={<MapingList />} />
                          <Route path="brandMap" element={<BrandMap />} />
                          <Route path="sizeMap"  element={<SizeMap />} />
                          <Route path="filterMap"  element={<FilterMap />} />
                      </Route>
                      {/* settings */}
                      <Route path="setting" element={<Settings />}>
                            <Route exact index path="" element={<SettingList />} />
                            <Route path="slider" element={<SettingSlider />} />
                            <Route path="banner" element={<SettingBanner />} />
                      </Route>
                      {/* order */}
                      <Route path="order" element={<Order />} >
                          <Route exact index path="" element={<OrderList />} />
                          <Route path="single" element={<OrderSingle />} />
                      </Route> 
                </Route>
            </Routes>
        </InitialRoute.Provider>
      </AuthUser.Provider>
    </BrowserRouter>
  );
}

export default MainNavigation;
