import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../../component/sidebar";
import { FaBars, FaAngleDown } from "react-icons/fa";
import { AiOutlinePoweroff, AiOutlineKey } from "react-icons/ai";
import AuthUser from "../../context/auth/AuthUser";
import Cookies from 'js-cookie'



function Home() {  
  const [sidebarClose,setSidebarClose] = useState(false);
  const Auth = React.useContext(AuthUser);

  // const handleEntailmentRequest = (e) => {
  //   e.preventDefault();  
  //   console.log("handle request ");
  // }

  const logoutHandler = () => {
    Auth.setAuth(false);
    Cookies.remove('s_l');
}

  useEffect (() => {
    window.scrollTo(0, 0);
    // handleEntailmentRequest(e);
  }, []);
  return (
    <div className="main-panel">
      <SideBar />
      <main>
        {/* Top Bar Header */}
        <div className="main-header d-flex justify-content-between">
          <div className="title-area d-flex align-items-center">
            <FaBars className="icon-item" />
            <h5>Admin Panel</h5>
          </div>
          <div className="right-side">
            <div className="sidebar-img">
              <img src={require('../../assets/man.png')} />
              <FaAngleDown className="icon" />
            </div>
            <div className="right-side-dropdown">
              <ul>
                <li style={{cursor : "pointer"}} onClick={()=>logoutHandler()} ><a><AiOutlinePoweroff className="menu-icon" /> Logout</a></li>
                {/* <li><a><AiOutlineKey className="menu-icon" /> Change Password</a></li> */}
              </ul>
            </div>
          </div>
        </div>

        {/* Main Area */}
          {/* <Routes>   
            <Route exact index path="/" element={<Dashboard />} />
            <Route exact path="user" element={<User />} />
            <Route exact path="seller/" element={<Seller />} />
            <Route exact path="order/" element={<Order />} />
            <Route exact path="master/" element={<Master />} />
            <Route exact path="product/" element={<Product />} />
            <Route exact path="setting/" element={<Settings />} />
          </Routes> */}
          <Outlet />
        <div>
        </div>
      </main>
    </div>

  );
}

export default Home;
