import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter , Route, Routes, Navigate } from "react-router-dom";
import Login from './screen/login';
import './App.css';
import './Responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./screen/templete";
import AuthUser from "./context/auth/AuthUser";
import axios from "axios";
import MainNavigation from "../src/navigation/MainNavigation";
// axios.defaults.baseURL = 'https://super.jivimart.com/public/api';
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api';
// axios.defaults.baseURL = 'https://avmart.backend.3decolutions.com/public/api';
axios.defaults.baseURL ="https://backend.aivimart.com/public/api";

// let baseURL = axios.defaults.baseURL;
function App() {
  const [auth, setAuth ] = useState(false);

  const pageUrl = () => {
    const queryString = window.location.href;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code')
    // console.log('URL',queryString)    
  };
  useEffect(()=>{
    pageUrl();
  },[])
  return (
    // <AuthUser.Provider value={{auth,setAuth}}>
    //     <BrowserRouter>
    //       <Links />
    //     </BrowserRouter>
    // </AuthUser.Provider>
    <MainNavigation />
  );
}

function Links() {
  const Auth = React.useContext(AuthUser);
  console.log('Auth',Auth);
  return (
    <Routes>   
      {/* <Route element={<Login />} /> */}
      <Route index path="/" element={<ProtectedLogin auth={Auth.auth} component={Login} />} />
      <Route path="/home" element={<ProtectedRoute auth={Auth.auth} component={Home} />} />
    </Routes>
  );
}

const ProtectedLogin = ({auth,component:Component,...rest}) => {
  return !auth ? <Component/> : <Navigate to="/home" />
}

const ProtectedRoute = ({auth,component:Component,...rest}) => {
  return auth ? <Component/> : <Navigate to="/" />
}

export default App;
