import React from 'react';
import { Link } from "react-router-dom";
import { FaExpandArrowsAlt } from "react-icons/fa";

function ImageThumb({image}) {
    console.log('Hello',image);
    return (
        <span style={styles.thumbnail}>
            <img src={image} style={styles.thumbnailImage} />
            <a className="link-item" href={image} target="_blank" style={styles.thumbnailLink}><FaExpandArrowsAlt /></a>
        </span>
    );
}

export default ImageThumb;
const styles = {
    thumbnail: {position:'relative',width:100,height:80,marginTop:5,border:'2px solid #777',display: 'block'},
    thumbnailImage: {width:'100%',height:'100%'},
    thumbnailLink: {color: '#333333cf',position: 'absolute',top: '35%',left: '35%',background: '#ffffff9e',padding: 5,borderRadius: '50%',fontSize: 16,},
    
}