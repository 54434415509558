import React, { useEffect, useState } from "react";
import { BrowserRouter , Route, Routes,Outlet } from "react-router-dom";
import ProductList from '../product/list';
import ProductSingle from '../product/single';

function Product() {  
  const [sidebarClose,setSidebarClose] = useState(false)
  useEffect (() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* <Routes>   
        <Route exact index path="/*" element={<ProductList />} />
        <Route exact path="/single/*" element={<ProductSingle />} />
      </Routes> */}

      <Outlet />
    </div>
  );
}

export default Product;
