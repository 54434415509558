import React, { useEffect, useState } from "react";
import {  Link ,useNavigate } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import Loader from '../../component/loader';
import axios from "axios";
import Cookies from "js-cookie";
import { decrpt } from "../../component/encryption";
import Pagination from "react-js-pagination";



function UserList() {  
  const navigate = useNavigate();
  const [loader,setLoader] = useState(false);
  const [users , setUsers] = useState();
  const [paginate , setPaginate] = useState();



  const fetchUsers = async() => {
    setLoader(true);
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.get(`/admin/user/list`)
    .then((response) => {
      console.log(response.data , 'fetchuserlidt');
      setUsers(response.data.data.list)
      setPaginate(response.data.data.pagination)
      setLoader(false);
    })
  }

  const userIdSend =(item)=> {
    navigate('./single' , {state : {item}});
  }

  const handlePageChange = async (pageNumber) => {
    setLoader(true);
    // console.log(`active page is ${pageNumber}`);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`/admin/user/list?page=${pageNumber}`)
      console.log(paginate,'paginate active');
      if(response.data.success) {
        setUsers(response.data.data.list);
        setLoader(false);
      }else{
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }


  
  useEffect (() => {
    window.scrollTo(0, 0);
    fetchUsers();
    // setLoader(true);
    // setTimeout(() => {      
    //   setLoader(false);
    // }, 500);
  }, []);
  return (
    <div className="main-content">
      {loader ? 
        <Loader />
        :
        <>
          {/* New User Registration */}
          <div className="section">
            <div className="row">
              <div className="col-md-12">
                <div className="card p-2">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <h5>All Users</h5>
                  </div>
                  <div>
                    <table className='table tabe-bordered'>
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>User Name</th>
                                <th className="text-center">Mobile Number</th>
                                <th className="text-center">Email Id</th>
                                <th className="text-center">Date of Registration</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                          {
                            users && users.map((item,index) => {
                              return (
                                <tr key={index}>
                                <td>{index + 1} </td>
                                <td>{item.user_name}</td>
                                <td className="text-center">{item.mobile}</td>
                                <td className="text-center">{item.email}</td>
                                <td className="text-center">
                                  {item.created_at}
                                </td>
                                <td className="text-center">
                              {item && item.status == 1 ?
                                <Badge bg="success">Enable</Badge>:
                                <Badge bg="danger">Disable</Badge>}
                              </td>
                               <td className="text-center">
                                  <button onClick={()=> userIdSend(item.id)}  className='btn btn-xs btn-primary text-white'>View</button> &nbsp;
                                  {/* <Link to="single"  className='btn btn-xs btn-primary text-white'>View</Link> &nbsp; */}
                                  {/* {item && item.status == 1 ?
                                  <button type="button" className='btn btn-xs btn-danger text-white'>Make Disable</button>:
                                  <button type="button" className='btn btn-xs btn-success text-white'>Make Enable</button>}   */}
                                </td>
                            </tr>
                              );
                            })
                          }
                           
                        </tbody>
                    </table>
                    {
                      users && users.length > 0 ?
                       <>
                    <div className="col-md-12">
                        <Pagination
                            totalItemsCount={ paginate && paginate.total_data}
                            onChange={handlePageChange}
                            activePage ={ paginate && paginate.current_page }
                            currentPage={ paginate && paginate.current_page }
                            itemsCountPerPage={paginate && paginate.per_page}
                            pageRangeDisplayed={10}
                        />
                    </div>  </>
                        : <></>
                    
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>

  );
}

export default UserList;
