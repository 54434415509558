import React from 'react';
import { Spinner } from 'react-bootstrap';

function loader(props) {
    return (
        <div style={styles.loader}>
            <div className="loaderInner">
                <Spinner animation="border" size="xl" variant='warning' />
            </div>
        </div>
    );
}

export default loader;

const styles = {
    loader:{color: 'red', position: 'absolute',right: 0,left: 0,top: 200,zIndex: 1,textAlign: 'center',},
}