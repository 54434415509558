import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';



function DatatableUser(props) {
    console.log(props , 'props data user');
    return (
        <div>
            <table className='table tabe-bordered'>
                <thead>
                    <tr>
                        <th>SL</th>
                        <th>Name</th>
                        <th>Date of Joining</th>
                        {/* <th>Action</th> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        props.userList && props.userList.map((item,index) => {
                            return(
                                <tr key={index}>
                                <td>{item.id} </td>
                                <td>{item.name}</td>
                                {/* <td>20/12/2022</td> */}
                                <td>
                                    {item && item.status == 1 ?
                                     <Badge bg="success"> enable</Badge> :  
                                     <Badge bg="danger"> Disabled</Badge>   
                                }
                                </td>
                                {/* <td><Link 
                                to={{pathname : 'user/single' ,
                                        state : {item}
                                    }} 

                                // params={item.id} 
                                className='btn btn-xs btn-success text-white'>View</Link></td> */}
                            </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default DatatableUser;