import React, { useEffect, useState } from "react";
import { Outlet, Link, Route, Routes } from "react-router-dom";
import BrandMap from "./brandMap";
import MapingList from "./maping";
import SizeMap from "./sizeMap";

function Maping() {  
  useEffect (() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Outlet />
    </div>

  );
}

export default Maping;
