import React, { useEffect, useState } from "react";
import { Form, Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Loader from '../../component/loader';
import { BiCategory, BiFilterAlt } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import Cookies from 'js-cookie'
import { decrpt } from '../../component/encryption';
import Pagination from "react-js-pagination";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Category() {  
  const [ loader,setLoader ] = useState(false);
  const [ show,setShow ] = useState(false);
  const [ errors, setErrors ] = useState();
  // console.log(errors , 'eerrors');
  const [ statusShow, setStatusShow ] = useState(false);
  const [ status, setStatus] = useState(false);
  const [ Eshow,setEShow ] = useState(false);
  const [ toastText,setToastText ] = useState('');
  const [ category, setcategory ] = useState();
  const [ categoryPreview, setCategoryPreview ] = useState({
    image : '',
    banner_image : ''
  });
  const [  paginate, setPaginate] = useState();
  const [ editCategory, setEditCategory ] = useState();
  const [ newCategory, setNewCategory ] = useState({
    id:'',
    name:'',
    category_id:'',
    level:'1',
    image:'',
    banner_image:'',
  });
  // console.log(toastText , 'newCategory newCategory');
 const [categorySelected , setCategorySelected ] = useState({
  id:'',
  name:'',
  level:1,
  image:'',
  banner_image:'',
  });
  // console.log('Fetch Category List',categorySelected);

  const handleOpen = () => {
    setShow(true);
    setCategoryPreview({
      image : '',
      banner_image : ''
    })
  };

  const handleClose = () => {
    setShow(false);
  };

  const editCall = (item) => {
    console.log(item , 'dsdksdksdksmnd');
    setEditCategory(item);
    setNewCategory(item);
    setCategorySelected({
      ...categorySelected,
      id: item.id,
      name: item.name,
      level:'1',
      image: item.image,
      banner_image: item.banner_image,
    });
    // console.log(categorySelected , 'check selected')
    setEShow(true);
  };

  const handleCloseEdit = () => {
    setEShow(false);
  };

  const textHandler = (e) => {
    setNewCategory({...newCategory,name:e.target.value})
  };

  function handlePreview(e) {
    setCategoryPreview({...categoryPreview , image:URL.createObjectURL(e.target.files[0])});
    setNewCategory({...newCategory, image:e.target.files[0]});
    // console.log(newCategory , 'categoryPreview');
    }
  function handleEditPreview(e) {
    setCategoryPreview({...categoryPreview , image:URL.createObjectURL(e.target.files[0])});
    setCategorySelected({...categorySelected, image:e.target.files[0]});
    // console.log(categorySelected , 'handleEditPreview');
    }
  function handleEditBannerPreview(e) {
    setCategoryPreview({...categoryPreview , banner_image:URL.createObjectURL(e.target.files[0])});
    setCategorySelected({...categorySelected, banner_image:e.target.files[0]});
    // console.log(categorySelected , 'handleEditBannerPreview');
    }
  function handleBannerPreview(e) {
    setCategoryPreview({...categoryPreview , banner_image:URL.createObjectURL(e.target.files[0])});
    setNewCategory({...newCategory, banner_image:e.target.files[0]});
    // console.log(newCategory , 'categoryPreview');
    }
  // Get Category List
  const getCategoryList = async() => {
    setLoader(true);
    try {
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      const response = await axios.post(`admin/category/list`, {type:1})
      // console.log(response.data , 'category list');
      setcategory(response.data.data.list)
      setPaginate(response.data.data.pagination);
      setLoader(false);
    } catch (error) {
      // console.error(error);
      
    }
  }

  const handlePageChange = async (pageNumber) => {
    setLoader(true);
    // console.log(`active page is ${pageNumber}`);
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`admin/category/list?page=${pageNumber}`, {type: 1} )
    .then((response) => {
      setcategory(response.data.data.list);
      setLoader(false);
      // console.log(response.data);
    }).catch((err) => {
      // console.log('seller list error' , err.response)
      setLoader(false);
    })
  }
  // Add Category
  const addCategory = async() => {
    setLoader(true);
    const formData = new FormData();
    // formData.append("id", newCategory.id);
    formData.append("level", newCategory.level);
    formData.append("name", newCategory.name);
    formData.append("image", newCategory.image);
    formData.append("banner_image", newCategory.banner_image);
    for (const value of formData.values()) {
        console.log(value);
      }
    try {
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      const response = await axios.post(`admin/category/save`, formData)
      .then((response)=>{
        if(response.data.success){
          handleClose();
          // console.log(response.data , 'response.data.data');
          let newData = response.data.data;
          category.unshift(newData);
          setEShow(false);
          setLoader(false);
          setToastText("Category Added");
          toast.success(toastText,{hideProgressBar:true});
          setEShow(false);
        }
      }).catch((err)=>{
        setLoader(false);
        console.error('Error',err);
        setErrors(err.response.data.errors)
        setToastText("Something went wrong")
        toast.danger(toastText,{hideProgressBar:true})
        setEShow(true);
      })
    } catch (error) {
      // console.error(error);
      setEShow(false);
    }
  }
  
  const editCategoryUpdate = async() => {
      setLoader(true);
      const formData = new FormData();
      formData.append("id", categorySelected.id);
      formData.append("level", categorySelected.level);
      formData.append("name", categorySelected.name);
      formData.append("image", categorySelected.image);
      formData.append("banner_image", categorySelected.banner_image);
      // console.log(formData.get('image'));
    try {
        const token = Cookies.get('s_l');
        const newToken = decrpt(token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      const response = await axios.post(`admin/category/save`, formData)
      .then((response)  => {
        let newData = response.data.data;
        // console.log(newData ,'category update');
        // console.log(category ,'category update');
        let tmparray = category;
          let obj = tmparray.find((o, i) => {
              if (o.id === newData.id) {
                tmparray[i] = {...category[i] , ...newData };
                console.log('true',tmparray[i])
                  return true; 
              }
            });
            setLoader(false);
            setEShow(false);
            setToastText("Category Updated")
              toast.success(toastText,{hideProgressBar:true})
      })
      // window.location.reload(true);
    }catch(error) {
        // console.log(error , 'editCategoryUpdate error');
        setErrors(error.response.data.errors)
        setLoader(false);
        setToastText("Something went wrong")
        toast.success(toastText,{hideProgressBar:true})
    }
  }

  // Change Category State
 
  const handleStatus = (item) => {
    setStatusShow(true);
    setStatus(item);
    // console.log(status , 'dsdsjdk item');
  };
  const handleStatusClose = () => {
    setStatusShow(false);
  };


  const statusUpdate = async()=> {
    setLoader(true);
    let newstatus = '';
    if (status.status == 1) {
      newstatus = 2;
    } else  {
      newstatus = 1;
    }
    const data = {
      id: status.id,
      status : newstatus
    }
    try {
        const token = Cookies.get('s_l');
        const newToken = decrpt(token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
        const response = await axios.post(`/admin/category/status`, data ).then((response) => {
          // console.log('Response',response);
          let tmparray = category;
          let obj = tmparray.find((o, i) => {
            if (o.id === status.id) {
                tmparray[i] = {...category[i] ,   status : newstatus  };
                return true; 
              }
            });
            setToastText("Category Status Updated")
            toast.success(toastText,{hideProgressBar:true})
            setLoader(false);
            setStatusShow(false);
        })
    }catch(error) {
        // console.log(error);
        setToastText("Category Status Updated")
        toast.error(toastText,{hideProgressBar:true})
        setLoader(false);
    }
  }

  useEffect (() => {
    window.scrollTo(0, 0);
    getCategoryList(); 
    // setTimeout(async() => {
    //   await getCategoryList();      
    //   setLoader(false);
    // }, 500);
  }, []); 

  return (
    <div className="main-content">
      {loader && loader ?
       <Loader /> :
       <>
      <ToastContainer position="top-center"  autoClose={3000}  hideProgressBar={false}  newestOnTop={false}  closeOnClick  rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'colored'}/>
      {/* New User Registration */}
      <div className="section">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <h5><BiCategory className="me-1" />All 1st Level Category</h5>
            </div>
            <div className="card p-2 mt-3">
              <div className="card-title">
                <h5>You have {category && category.length} category</h5>
                <Button onClick={()=> handleOpen()} className="badge btn-xs pt-1">Add New 1st Level Category</Button>
              </div>
              <div>
                <table className='table tabe-bordered'>
                    <thead>
                        <tr>
                            <th>SL</th>
                            <th>1st Level Category</th>
                            <th>Category Image</th>
                            <th>Category Banner</th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {category &&  category.sort((a, b) => a.id > b.id ? 1:-1).map((item,index)=> {
                          return(
                          <tr key={index}>
                            <td>{index + 1}. </td>
                            <td>{item.name}</td>
                            <td> <img src={item.image}  style={{ width: 100,height: 100 }} /> </td>
                            <td> <img src={item.banner_image} style={{ width: 100,height: 100 }} /> </td>
                            <td className="text-center">
                              { item &&  item.status == 1 ?
                              <Badge bg="success">Enable</Badge>:
                              <Badge bg="danger">Disable</Badge>}
                            </td>
                            <td className="text-center">
                              <button onClick={()=>editCall(item)} className='btn btn-xs btn-primary text-white'>Edit</button> &nbsp;
                              {/* <button onClick={()=>deleteCall(item)} className='btn btn-xs btn-danger text-white'>Delete</button> &nbsp; */}
                              {item && item.status == 1 ? 
                                <button onClick={()=>handleStatus(item)} className='btn btn-xs btn-danger text-white'>Make Disable</button>:
                                <button onClick={()=>handleStatus(item)} className='btn btn-xs btn-success text-white'>Make Enable</button>}                                  
                            </td>
                          </tr>
                          )
                        })}
                    </tbody>
                </table>
                { category && category  ?
                    <div className="col-md-12">
                    <Pagination
                    totalItemsCount={ paginate.total_data}
                    onChange={handlePageChange}
                    activePage={  paginate.current_page}
                    itemsCountPerPage={paginate.per_page}
                    // pageRangeDisplayed={10}
                    />
                </div>
                : <></>
                }
              
              </div>

              {/* Add Cateory */}
              <Modal size="md" show={show} onHide={handleClose}>
                  <Modal.Header className="py-1" closeButton>
                      <Modal.Title>Add 1st Level Category</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Category Image* </Form.Label>
                            <Form.Control type="file" onChange={handlePreview} />
                        {errors && errors ? <p style={{fontSize : 12 , color : 'red'}}> {errors.image} </p> : '' }
                        </Form.Group>
                        <img src={categoryPreview && categoryPreview.image !== '' ? categoryPreview.image: '/folder.png'} style={{ width: 100,height: 100 }} />
                        {/*  */}
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>Category banner Image* </Form.Label>
                              <Form.Control type="file" onChange={handleBannerPreview} />
                              {errors && errors.banner_image ? <p style={{fontSize : 12 , color : 'red'}}> {errors.banner_image} </p> : '' }
                          </Form.Group>
                            <img src={categoryPreview && categoryPreview.banner_image !== '' ? categoryPreview.banner_image: '/folder.png'} style={{ width: 100,height: 100 }} />
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>1st Level Category*</Form.Label>
                              <Form.Control size="sm" type="text" placeholder="Enter 1st Level Category" onChange={ e => textHandler(e)} />
                              {errors && errors.name ? <p style={{fontSize : 12 , color : 'red'}}> {errors.name} </p> : '' }
                          </Form.Group>
                      </Form>
                  </Modal.Body>
                  <Modal.Footer className="p-1 justify-content-start">
                      <button onClick={()=>handleClose()} className="btn btn-light btn-sm">
                          Close
                      </button>
                      <button type="button" onClick={()=>addCategory()} className="btn btn-primary btn-sm">
                          Save Changes
                      </button>
                  </Modal.Footer>
              </Modal> 
              {/* Delete Cateory */}
              <Modal size="md" show={statusShow} onHide={handleStatus} centered>
                  <Modal.Header className="py-1">
                      <Modal.Title className="h6">Do you want to  {
                          status && status.status == 1 ?  'Disable' : 'Enable'
                        } category ? <br /><span className="text-danger text-capitalize fw-bold">{status && status.name}</span></Modal.Title>
                  </Modal.Header>
                  <Modal.Footer className="p-1 justify-content-start">
                      <button  onClick={()=> handleStatusClose()} className="btn btn-light btn-sm">
                          Close
                      </button>
                      <button  className="btn btn-primary btn-sm" onClick={() => statusUpdate()} >
                        {status && status.status == 1 ?  'Make Disable' : 'Make Enable'}
                      </button>
                  </Modal.Footer>
              </Modal> 

              {/* Edit Cateory */}
              <Modal size="md" show={Eshow}>
                  <Modal.Header className="py-1">
                      <Modal.Title>Edit 1st Level Category</Modal.Title>
                  </Modal.Header>
                  {/* <Form method="post" onSubmit={editCategoryUpdate}> */}
                  <Modal.Body>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Brand Image </Form.Label>
                          <Form.Control type="file" onChange={handleEditPreview} />
                          {errors && errors ? <p style={{fontSize : 12 , color : 'red'}}> {errors.image} </p> : '' }
                      </Form.Group>
                            <img src={categoryPreview && categoryPreview.image !== '' ? categoryPreview.image: categorySelected.image} style={{ width: 100,height: 100 }} />
                        {/*  */}
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>Brand banner Image </Form.Label>
                              <Form.Control type="file" onChange={handleEditBannerPreview} />
                              {errors && errors ? <p style={{fontSize : 12 , color : 'red'}}> {errors.banner_image} </p> : '' }
                          </Form.Group>
                            <img src={categoryPreview && categoryPreview.banner_image !== '' ? categoryPreview.banner_image: categorySelected.banner_image} style={{ width: 100,height: 100 }} />
                       
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>1st Level Category Name </Form.Label>
                              <Form.Control size="sm" type="text" 
                              placeholder="Enter Category Name"
                              onChange={ e => setCategorySelected({...categorySelected , name:e.target.value})}
                               defaultValue={categorySelected && categorySelected.name} />
                          {errors && errors ? <p style={{fontSize : 12 , color : 'red'}}> {errors.name} </p> : '' }
                          </Form.Group>
                  </Modal.Body>
                  <Modal.Footer className="p-1 justify-content-start">
                      <button onClick={()=>handleCloseEdit()} className="btn btn-light btn-sm">
                          Close
                      </button>
                      <button type="button" onClick={() => editCategoryUpdate()}  className="btn btn-primary btn-sm">
                          Save Changes
                      </button>
                  </Modal.Footer>
                  {/* </Form> */}
              </Modal> 

            </div>
          </div>
        </div>
      </div>
       </>
      }
      
    </div>

  );
}

export default Category;
