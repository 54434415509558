import React, { useEffect, useState } from "react";
import axios from "axios";
import { decrpt } from "../../component/encryption";
import Cookies from "js-cookie";
import { AiFillEdit } from "react-icons/ai";
import { FormControl, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";


function SettingBanner() {  
  const [loader,setLoader] = useState(false);
  const [addShow, setAddShow] = useState(false);
  const [bannerList , setBannerList ] = useState();
  const [addBanner , setAddBanner] = useState();
  const [show , setShow] = useState(false);

  
  const bannerListFetch = async () => {
    setLoader(true);
    try {
      // const formData = new FormData();
      // formData.append("image", slider.image);
      // formData.append("heading", slider.heading);
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(`/admin/slider/list` , {status : 2})
        .then((response) => {
          console.log(`slider list dat dsdsa`, response.data );
          setBannerList(response.data.data);
        })
    setLoader(false);
    }catch (error) {
      console.log(error);
    }
  }


  const BannerAdd = async () => {
    // e.preventDefault();
    setLoader(true);
    try {
      const formData = new FormData();
      formData.append("image", addBanner.image);
      formData.append("heading", addBanner.heading  ? addBanner.heading : '');
      formData.append("type", 2);
      console.log(`slider update data`, formData );
        // let token = decrpt(Cookies.get('s_l'));
        // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      //   const headers = {
      //     'Accept': 'multipart/form-data',
      //     'Access-Control-Allow-Origin' : '*',
      //     'Access-Control-Allow-Method' : 'POST',
      //     'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept',
      //     // 'Authorization' : `Bearer ${token}`,
      //     'credentials' : true,
      // };
        const response = await axios.post(`/admin/slider/save` , formData )
        // const response = await axios.post(`/admin/slider/save` , formData )
        .then((response) => {
          console.log(response , 'banner adsdwsd');
          
        })
    }catch (error) {
      console.log(error);
    }
  }


console.log(addBanner && addBanner , 'addBanner')


  useEffect (() => {
    window.scrollTo(0, 0);
    bannerListFetch();
    // setLoader(true);
    // setTimeout(() => {      
    //   setLoader(false);
    // }, 500);
  }, []);
  return (
    <div className="main-content">
       <div className="section">
        <div className="card p-2">
          <div className="card-title d-flex align-items-center justify-content-between">
            <h5>Banner <small className="text-danger">(4 slider current)</small></h5>
            {
              bannerList && bannerList.length == 4 ? '' :
              <button onClick={()=> setShow(true)}   className="btn btn-xs btn-primary "><AiFillEdit />Add New </button>
            }
          </div>
          <div className="row">
            {  bannerList && bannerList.map((item,index) => {
              return (
                <div  key={index} className="col-md-3 my-2">
                <div>
                  <div style={{height : 150 , overflow : 'hidden'}}>
                    <img src={item && item.image}  />
                  </div>
                  <p>{item.heading}</p>
                  <div className="d-flex justify-content-center pt-2 gap-1">
                    {
                      item && item.status == 1 ? <button className="btn btn-xs btn-success" >Make Enable</button> :
                      <button className="btn btn-xs btn-danger"  >Make Disable</button>
                    }
                    <button className="btn btn-xs btn-primary" ><AiFillEdit /></button>
                    {/* <button className="btn btn-xs btn-danger"><AiFillDelete /></button> */}
                  </div>
                </div>
              </div>
              );
            })
            }
          </div>
        </div>
      </div>
      <Modal  show={show} onHide={() => setShow(false)} >
          <Modal.Header closeButton>
              <Modal.Title>Banner Add </Modal.Title>
          </Modal.Header>
          <Modal.Body>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Image Caption</Form.Label>
                      <FormControl type="text" onChange={e => setAddBanner({...addBanner , heading:e.target.value}) } />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Image upload</Form.Label>
                      {/* <Form.Control type="file"   onChange={e => setSlider({...slider, image:e.target.files[0]})  } /> */}
                      <Form.Control type="file"  onChange={e => setAddBanner({...addBanner , image:(e.target.files[0])})} />
                  </Form.Group>
                  <div className="d-flex w-100 align-items-center ">
                    <div>
                      <Button className="saveChangesBtn  h-100" type="button" onClick={() => BannerAdd()} >Save Changes</Button>
                    </div>
                    <div className="ms-auto">
                      {/* <img src={sliderPreview && sliderPreview.image !== '' ? sliderPreview.image: '/folder.png'} style={{ width: 100,height: 100 }}/> */}
                  </div>
                  </div>
          </Modal.Body>
      </Modal>
    </div>

  );
}

export default SettingBanner;
const styles = {
}
