import React, { useEffect, useState } from "react";
import {  Link } from "react-router-dom";
import { BiCategory, BiFilterAlt } from "react-icons/bi";
import DocumentModal from './component/documentModal';
import RegisteredOffice from "./component/registeredOffice";
import TermsCondModal from "./component/termsCondModal";
import PrivacyModal from "./component/privacyPage";
import DisclaimerCompo from "./component/disclaimerModal";
import Cancellation from "./component/cancellation";
import SellerCompo from "./component/seller";
import HelplineCompo from "./component/helpline"; 
import axios from "axios";
import Cookies from "js-cookie";
import { decrpt } from "../../component/encryption";
import Loader from "../../component/loader"

function SettingList() {  
  const [loader,setLoader] = useState(false)
  const [docShow, setDocShow] = useState(false);
  const [modalHeader, setModalHeader] = useState();
  const [registeredOfficeModal , setRegisteredOfficeModal] = useState(false);
  const [termsModal , setTermsModal] = useState(false);
  const [privacyModal , setPrivacyModal] = useState(false);
  const [disclamerModal , setDisclamerModal] = useState(false);
  const [cancellationModal , setCancellationModal] = useState(false);
  const [sellerModal , setSellerModal ] = useState();
  const [helplineModal , setHelplineModal] = useState();
  const [errors , setErrors ] = useState();

  const [mail,setMail] = useState();
  const [office,setOffice] = useState();
  const [terms,setTerms] = useState();
  const [privacy,setPrivacy] = useState();
  const [disclamer,setDisclamer] = useState();
  const [cancellation,setCancellation] = useState();
  const [seller,setSeller] = useState();
  const [helpline , setHelpline] = useState();

  const mailingAddress = async () => {
    setLoader(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response  = await axios.get(`/admin/setting/mailAddress/view`)
      .then(response => {
        // console.log(response , 'chekc mailing address')
        if(response.data.data != null){
          setMail(response.data.data.desc)
        }else{
          setMail(response.data.data)
        }
        setLoader(false);
      })
    }catch(error) {
      console.log(error , 'new error')
      setLoader(false);
    }
  }
  const mailingAddressUpdate = async () => {
    setLoader(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response  = await axios.post(`/admin/setting/mailAddress/save` , {desc : mail && mail})
      .then(response => {
        console.log(response , 'cnsacs')
        if(response.data.data != null){
          setMail(response.data.data.desc)
        }else{
          setMail(response.data.data.desc)
        }
        setLoader(false);
        setDocShow(false);
      })
    }catch(error) {
       console.log(error , 'new error cnsacs')
      setLoader(false);
      }
  }

  const registerApiViewUpdate = async () => {
    setLoader(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response  = await axios.post(`/admin/setting/regOffice/save` , {desc : office && office })
      .then(response => {
        // console.log(response , 'chekc mailing chek')
        if(response.data.data != null ){
          setOffice(response.data.data.desc)
          setRegisteredOfficeModal(false);
        }else {
          setOffice(response.data.data)
          setRegisteredOfficeModal(false);
        }
        setLoader(false);
      })
    }catch(error) {
      setLoader(false);
      // console.log(error , 'new error')
    }
  }
  const registerApiView = async () => {
    setLoader(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response  = await axios.get(`/admin/setting/regOffice/view`)
      .then(response => {
        // console.log(response , 'chekc mailing registerApiView')
        if(response.data.data != null){
          setOffice(response.data.data.desc)
        }else{
          setOffice(response.data.data)
        }
        setLoader(false);
      })
    }catch(error) {
      // console.log(error , 'new error')
    }
  }
  const termsCOndApi = async () => {
    setLoader(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response  = await axios.get(`/admin/setting/terms/view`)
      .then(response => {
        // console.log(response , 'chekc mailing registerApiView')
        if(response.data.data != null){
          setTerms(response.data.data.desc)
        }else{
          setTerms(response.data.data)
        }
        setLoader(false);
      })
    }catch(error) {
      // console.log(error , 'new error')
    }
  }
  const termsCOndApiUpdate = async () => {
    setLoader(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response  = await axios.post(`/admin/setting/terms/save` , {desc : terms && terms})
      .then(response => {
        // console.log(response , 'chekc mailing registerApiView')
        if(response.data.data != null){
          setTerms(response.data.data.desc)
          setTermsModal(false);
        }else{
          setTerms(response.data.data)
          setTermsModal(false);
        }
        setLoader(false);
      })
    }catch(error) {
      // console.log(error , 'new error')
    }
  }

  const privacyApiView = async () => {
    setLoader(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response  = await axios.get(`/admin/setting/privacy/view`)
      .then(response => {
        console.log(response , 'chekc mailing api')
        if(response.data.data != null){
          setPrivacy(response.data.data.desc)
        }else{
          setPrivacy(response.data.data)
        }
        setLoader(false);
      })
    }catch(error) {
      // console.log(error , 'setPrivacy error')
    }
  }
  const privacyApiUpdate = async () => {
    setLoader(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response  = await axios.post(`/admin/setting/privacy/save` , {desc : privacy && privacy})
      .then(response => {
        console.log(response , 'chekc mailing registcscscerApiView')
        if(response.data.data != null){
          setPrivacy(response.data.data.desc)
          setPrivacyModal(false);
        }else{
          setPrivacy(response.data.data)
          setPrivacyModal(false);
        }
        setLoader(false);
      })
    }catch(error) {
      // console.log(error , 'setPrivacy error')
    }
  }
  const disclaimerApi = async () => {
    setLoader(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response  = await axios.get(`/admin/setting/disc/view`)
      .then(response => {
        // console.log(response , 'chekc mailing api')
        if(response.data.data != null){
          setDisclamer(response.data.data.desc)
        }else{
          setDisclamer(response.data.data)
        }
        setLoader(false);
      })
    }catch(error) {
      // console.log(error , 'setPrivacy error')
    }
  }
  const disclaimerApiSave = async () => {
    setLoader(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response  = await axios.post(`/admin/setting/disc/save` , {desc : disclamer && disclamer})
      .then(response => {
        console.log(response , 'chekc mailing registcscscerApiView')
        if(response.data.data != null){
          setPrivacy(response.data.data.desc);
          setDisclamerModal(false)
        }else{
          setPrivacy(response.data.data)
          setDisclamerModal(false)
        }
        setLoader(false);
      })
    }catch(error) {
      // console.log(error , 'setPrivacy error')
    }
  }

  const cancellationView = async () => {
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response  = await axios.get(`/admin/setting/cancelPolicy/view`)
      .then(response => {
        if(response.data.data != null){
          setCancellation(response.data.data.desc);
          setCancellationModal(false)
        }else{
          setCancellation(response.data.data)
          setCancellationModal(false)
        }
      })
    }catch(error) {}
  }
  const cancellationUpdate = async () => {
    setLoader(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response  = await axios.post(`/admin/setting/cancelPolicy/save` , {desc :cancellation && cancellation })
      .then(response => {
        // console.log(response , 'response')
        if(response.data.data != null){
          setCancellation(response.data.data.desc);
          setCancellationModal(false)
        }else{
          setCancellation(response.data.data)
          setCancellationModal(false)
        }
        setLoader(false);
      })
    }catch(error) {}
  }
  const SellerView = async () => {
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response  = await axios.get(`/admin/setting/sellerPolicy/view`)
      .then(response => {
        if(response.data.data != null){
          setSeller(response.data.data.desc);
        }else{
          setSeller(response.data.data)
        }
      })
    }catch(error) {}
  }
  const SellerUpdate = async () => {
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response  = await axios.post(`/admin/setting/sellerPolicy/save` , {desc : seller && seller})
      .then(response => {
        if(response.data.data != null){
          setSeller(response.data.data.desc);
        }else{
          setSeller(response.data.data)
        }
        setSellerModal(false);
      })
    }catch(error) {}
  }
  const helplineApi = async () => {
    setLoader(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response  = await axios.get(`/admin/setting/helpline/view`)
      .then(response => {
        if(response.data.data != null){
          setHelpline(response.data.data.number);
        }else{
          setHelpline(response.data.data)
        }
        setLoader(false);
      })
    }catch(error) {}
  }
  const helplineSave = async () => {
    setLoader(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response  = await axios.post(`/admin/setting/helpline/save` , {number :helpline && helpline })
      .then(response => {
      // console.log(response , 'error smlc')
        if(response.data.data != null){
          setHelpline(response.data.data.number);
        }else{
          setHelpline(response.data.data)
        }
        setLoader(false);
        setHelplineModal(false)
      })
    }catch(error) {
      // console.log(error , 'error smlc')
      setErrors(error.response.data.errors)
      setHelplineModal(true);
    }
  }
// console.log(helpline && helpline ,'helpline')

  useEffect (() => {
    window.scrollTo(0, 0);
    mailingAddress();
    registerApiView();
    termsCOndApi();
    privacyApiView();
    disclaimerApi();
    cancellationView();
    SellerView();
    // helplineApi();
  }, []);
  return (
    <div className="main-content"> 
      <div className="section">
        <div className="row">
              {
                loader ? <Loader /> :
                <>
                {/* WEBSITE SLIDER & BANNER */}
                    <div className="col-md-12 mb-4">
                      <div className="section-title">
                        <h5><BiCategory className="me-1" />Website slider & banner</h5>
                      </div>
                      
                      <div className="count-section d-flex gap-3 mt-2">
                        {/* count 1 */}
                        <div className="count-block d-flex align-items-center justify-content-start no-divider">
                          <div className="count-content text-start">
                            <h5>Website Slider</h5>
                            <Link to="./slider" className="link-btn">view</Link>
                          </div>
                        </div>
                        {/* count 2 */}
                        <div className="count-block d-flex align-items-center justify-content-start no-divider">
                          {/* <div  className="count-figure"><h3>1</h3></div> */}
                          <div className="count-content text-start">
                            <h5>Website Banner</h5>
                            <Link to="./banner" className="link-btn">view</Link>
                          </div>
                        </div>
                      </div>

                    </div>

                    {/* ADDRESS & CONTACT */}
                    <div className="col-md-12 mb-4">
                      <div className="section-title">
                        <h5><BiFilterAlt className="me-1" />Address & Contact</h5>
                      </div>
                      
                      <div className="count-section d-flex gap-3 mt-2">
                        {/* count 1 */}
                        <div className="count-block d-flex align-items-center justify-content-start no-divider">
                          <div className="count-content text-start">
                            <h5>Mailling Address</h5>
                            <button onClick={() => setDocShow(true)} className="link-btn">view</button>
                          </div>
                        </div>
                        {/* count 2 */}
                        <div className="count-block d-flex align-items-center justify-content-start no-divider">
                          {/* <div  className="count-figure"><h3>1</h3></div> */}
                          <div className="count-content text-start">
                            <h5>Registered Offices</h5>
                            <button onClick={()=> setRegisteredOfficeModal(true)} className="link-btn">view</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* DOCUMENTS */}
                    <div className="col-md-12 mb-4">
                      <div className="section-title">
                        <h5><BiFilterAlt className="me-1" />Documents</h5>
                      </div>
                      <div className="count-section d-flex gap-3">
                        {/* count 1 */}
                        <div className="count-block d-flex align-items-center justify-content-start no-divider">
                          <div className="count-content text-start">
                            <h5>Terms & Condition</h5>
                            <button onClick={()=> setTermsModal(true)}  className="link-btn">view</button>
                          </div>
                        </div>
                        {/* count 2 */}
                        <div className="count-block d-flex align-items-center justify-content-start no-divider">
                          <div className="count-content text-start">
                            <h5>Privacy Policy</h5>
                            <button onClick={() => setPrivacyModal(true)}  className="link-btn">view</button>
                          </div>
                        </div>
                        {/* count 3 */}
                        <div className="count-block d-flex align-items-center justify-content-start no-divider">
                          <div className="count-content text-start">
                            <h5>Disclaimer</h5>
                            <button onClick={() => setDisclamerModal(true)} className="link-btn">view</button>
                          </div>
                        </div>
                        {/* count 3 */}
                        <div className="count-block d-flex align-items-center justify-content-start no-divider">
                          <div className="count-content text-start">
                            <h5>Cancellation Policy</h5>
                            <button onClick={() => setCancellationModal(true)}  className="link-btn">view</button>
                          </div>
                        </div>
                      </div>
                      <div className="count-section d-flex gap-3">
                        {/* count 1 */}
                        <div className="count-block d-flex align-items-center justify-content-start no-divider">
                          <div className="count-content text-start">
                            <h5>Seller Policy</h5>
                            <button onClick={()=> setSellerModal(true)} className="link-btn">view</button>
                          </div>
                        </div>
                        {/* count 1 */}
                        <div className="count-block d-flex align-items-center justify-content-start no-divider">
                          <div className="count-content text-start">
                            <h5>Helpline Number</h5>
                            <button onClick={()=> setHelplineModal(true)} className="link-btn">view</button>
                          </div>
                        </div>
                      </div>
                    </div>
                </>
              }    
          <DocumentModal docShow={docShow} mail={mail} setMail={setMail} mailingAddressUpdate={mailingAddressUpdate} setDocShow={setDocShow} modalHeader={modalHeader} />
          <RegisteredOffice registeredOfficeModal={registeredOfficeModal} setRegisteredOfficeModal={setRegisteredOfficeModal}
          setOffice={setOffice} office={office} registerApiViewUpdate={registerApiViewUpdate}/>
          <TermsCondModal termsModal={termsModal} setTermsModal={setTermsModal} setTerms={setTerms} terms={terms} termsCOndApiUpdate={termsCOndApiUpdate} />
          <PrivacyModal setPrivacyModal={setPrivacyModal} privacyModal={privacyModal} setPrivacy={setPrivacy} privacy={privacy}
          privacyApiUpdate={privacyApiUpdate} />
          <DisclaimerCompo  setDisclamerModal={setDisclamerModal} disclamerModal={disclamerModal} setDisclamer={setDisclamer} disclamer={disclamer} 
          disclaimerApiSave={disclaimerApiSave} />
          <Cancellation setCancellationModal={setCancellationModal} cancellation={cancellation} setCancellation={setCancellation} 
          cancellationModal={cancellationModal} cancellationUpdate={cancellationUpdate}  />

          <SellerCompo setSellerModal={setSellerModal} sellerModal={sellerModal}  setSeller={setSeller} seller={seller} SellerUpdate={SellerUpdate}/>
          <HelplineCompo helplineModal={helplineModal} setHelplineModal={setHelplineModal}  helpline={helpline} setHelpline={setHelpline} helplineSave={helplineSave} errors={errors} />
        </div>
      </div>
    </div>

  );
}

export default SettingList;

