import React, { useEffect, useState } from "react";
import { Outlet, Link,useNavigate } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Loader from '../../component/loader';
import Pagination from "react-js-pagination";
import axios from 'axios';
import { Modal } from "react-bootstrap";
import { AiOutlinePullRequest, AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";
import Cookies from "js-cookie";
import {decrpt} from "../../component/encryption";

function ProductList() {
  const [show,setShow] = useState(false);
  const [featureModal , setFeatureModal ] = useState();
  const [dshow,setDShow] = useState(false)
  const [loader,setLoader] = useState(false)
  const navigate = useNavigate();
  //product api l
  const [selectedStatus, setSelectedStatus] = useState();
  const [productList,setProductList]=useState('');
  const [selectedProductList,setSelectedProductList]=useState();
  const [paginate , setPaginate] = useState();
  const [featured , setFeatured ] = useState();
  const [approve,setApprove]=useState();
  
  
  console.log( approve && approve,'selectedproductList');

  const fetchProductList=async(status)=>{
    setLoader(true);
    const data = {
      status: status,
  }
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization']=`Bearer ${token}`;
      const response = await axios.post(`/admin/product/list`, data);
      console.log(response.data,'fetchProductList');
      if (response.data.success) {
        setProductList(response.data.data.list);
        setSelectedProductList(response.data.data.list);
        setPaginate(response.data.data.pagination);
        setLoader(false);
      } else {
        setLoader(false);
      }
      
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }
  //list btn status change
  const statusTypeHandler = (status) => {
    // fetchProductList(status);
    if(status==1){
      let array=[];
      productList && productList.map((item,index)=>{
        if(item.status==1 || item.status==2){
          array.push(item);
        }
      })
      setSelectedProductList(array);
    }else if (status==3) {
      let array=[];
      productList && productList.map((item,index)=>{
        if(item.status==3){
          array.push(item);
        }
      })
      setSelectedProductList(array);
    } else if (status==4) {
      let array=[];
      productList && productList.map((item,index)=>{
        if(item.status==4){
          array.push(item);
        }
      })
      setSelectedProductList(array);
    }else{
      console.log("something went wrong");
    }
    
    setSelectedStatus(status);
    // console.log(status,'status');
}

// console.log(approve.item,'approve');

const approveClose = () => {
  setShow(false);
};

const approveReject = (item,status) => {
  setApprove({item,status});
  setShow(true);
}

//make approve Api
const sellerApprove =async()=>{
  setLoader(true);
  setShow(false)
   const data = {
    id: approve.item.id,
    status : approve.status
  }
  console.log(data,'data');
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/admin/product/approve/reject`,data)
      // console.log(response.data,'response');
      // if (response.data.success) {
        setLoader(false);
      let newData = response.data.data;
      console.log(newData,'newData');
      let tmparray = selectedProductList;
      let obj = tmparray.find((o, i) => {
        if (o.id === newData.id) {
              tmparray[i] = {...selectedProductList[i] ,   ...newData  };
              return true; 
          }
      });
    // }
} catch (error) {
  console.log(error);
  setLoader(false);
}
}

const [enables,setEnables]=useState('')
const handleClose = () => {
  setDShow(false);
};

const enableDesable = (item) => {
  setEnables(item);
  setDShow(true);
}

// product enable api
const sellerEnable = async () => {
  setLoader(true);
  setDShow(false)
  let newstatus = '';
    if (enables.status == '1') {
      newstatus = '2';
    } else {
      newstatus = '1';
    }
    const data = {
      id: enables.id,
      status : newstatus
    }
  console.log(data,'data');
  try {
  let token = decrpt(Cookies.get('s_l'));
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  const response = await axios.post(`admin/product/status/update`,data)
  // console.log(response.data,'response');
  
  // if(response.data.sucess) {
    let  newData =response.data.data;
    setLoader(false);
      let tmparray = selectedProductList;
      let obj = tmparray.find((o, i) => {
        if (o.id === newData.id) {
            tmparray[i] = {...selectedProductList[i] ,   ...newData  };
            return true; 
        }
    });
  // } else {
  // }
  setLoader(false);
  } catch (error) {
      console.log('Error',error);
      // setLoader(false);
  }
}


// pagination
  const handlePageChange = async (pageNumber) => {
    setLoader(true);
    // console.log(`active page is ${pageNumber}`);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/admin/product/list?page=${pageNumber}`)
      // console.log(response,'response active');
      if(response.data.success) {
        setSelectedProductList(response.data.data.list);
        setLoader(false);
      }else{
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }

  const handleFeature = (item) => {
    setFeatureModal(true)
    setFeatured(item);
    console.log(featured  , 'featured')
  }


  const makeFeaturedFetch = async (item) => {
    console.log(item, 'check itm')
    try {
      let newstatus = '';
    if (featured.is_featured == '1') {
      newstatus = '2';
    } else {
      newstatus = '1';
    }
      const data = {
        id : featured && featured.id,
        is_featured : newstatus
      }
      console.log(data , 'data check')
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/admin/product/featured/status`, data)
      .then(response => {
        setFeatureModal(false);
        let  newData =response.data.data;
          let tmparray = selectedProductList;
          let obj = tmparray.find((o, i) => {
            if (o.id === newData.id) {
                tmparray[i] = {...selectedProductList[i] ,   ...newData  };
                return true; 
            }
        });
      })
    }catch(error) {
      console.log(error , 'error')
    }
  }




// view details
const viewDetails =(item)=> {
  navigate('./single' , {state : {item}});
}
 useEffect (() => {
    fetchProductList();
    // handlePageChange();
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main-content">
      {loader ? 
        <Loader />
        :
        <>
           <div className="count-section d-flex justify-content-left">
            
                {/* count all */}
                <button className="product-page-block d-flex align-items-center me-3" onClick={() => statusTypeHandler(1)} style={selectedStatus == 1 ? styles.activeBtn : null}>
                    <div className="product-page-inner-block">
                        <h5 style={selectedStatus == 1 ? styles.activeBtnTxt : null}><AiOutlinePullRequest /> Approved</h5>
                    </div>
                </button>
                {/* count 1 */}
                <button className="product-page-block d-flex align-items-center me-3" onClick={() => statusTypeHandler(3)} style={selectedStatus == 3 ? styles.activeBtn : null}>
                    <div className="product-page-inner-block">
                        <h5 style={selectedStatus == 3 ? styles.activeBtnTxt : null}><AiOutlinePullRequest/> Pending</h5>
                    </div>
                </button>
                {/* count 2 */}
                <button className="product-page-block d-flex align-items-center" onClick={() => statusTypeHandler(4)} style={selectedStatus == 4 ? styles.activeBtn : null}>
                    <div className="product-page-inner-block">
                        <h5 style={selectedStatus == 4 ? styles.activeBtnTxt : null}><AiOutlineCheckCircle />Rejected</h5>
                    </div>
                </button>
            </div>
          {/* New User Registration */}
          <div className="section">
            <div className="row">
              {/* Past Order */}
              <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <h5>All Product</h5>
                  </div>
                  <div>
                    <table className='table tabe-bordered'>
                        <thead>
                            <tr>
                                <th>Product ID</th>
                                <th style={{maxWidth:350}}>Product Name</th>
                                <th>Seller Name</th>
                                <th className="text-center">1st Category</th>
                                {/* <th className="text-center">2nd Category</th>
                                <th className="text-center">3rd Category</th> */}
                                <th className="text-center">Brand</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        { selectedProductList && selectedProductList.length > 0 ?
                              <>
                          {selectedProductList && selectedProductList.map((item,index)=>{
                            return(
                              <tr key={index}>
                              <td>{item.uu_id}</td>
                              <td>
                                {/* <img src="https://assets.myntassets.com/dpr_1.5,q_60,w_400,c_limit,fl_progressive/assets/images/12554514/2020/10/16/7e767b2e-b393-4354-aa24-3e86e92abd031602822707923-MANQ-Men-Suits-5401602822706180-1.jpg" className="table-img" /> */}
                               {item.name}</td>
                              <td>{item.seller_name}</td>
                              <td className="text-center">{item.category}</td>
                              <td className="text-center">{item.brand}</td>
                              <td className="text-center">
                              { item.status == 3 ? <Badge bg="danger"> Not Approved</Badge> : (item.status == 1)  ? 
                              <Badge bg="success"> Enabled</Badge>:(item.status == 2)  ? <Badge bg="warning"> Disabled</Badge> : <Badge bg="danger"> Reject</Badge> }
                              </td>
                              <td className="text-center">
                                {item.is_featured === '1' ?
                                  <button type="button" className='btn btn-xs btn-danger text-white me-1' onClick={()=> handleFeature(item)} >Remove Featured</button> 
                                  : (item.is_featured ===  '2' ) ?
                                  <button type="button" className='btn btn-xs btn-info text-dark me-1' onClick={()=> handleFeature(item)} >Make Featured</button> : ''
                                }
                              <button type="button" className='btn btn-xs btn-primary text-white me-1'  onClick={()=> viewDetails(item.id) }>View</button> &nbsp;

                              {item.status == 1 ?   
                                <button type="button"   className='btn btn-xs btn-danger text-white me-1' onClick={() => enableDesable(item)  }>Make Disable</button> :  (item.status == 2)  ?  
                                <button type="button"   className='btn btn-xs btn-warning text-dark me-1' onClick={() => enableDesable(item)  }>Make enable</button> : '' }

                                {item.status == 3 ?
                                <>
                                <button type="button" className='btn btn-xs btn-success text-white me-1' onClick={()=>approveReject(item,'1')}> Make Approve </button> 
                                <button type="button" className='btn btn-xs btn-danger text-white' onClick={()=>approveReject(item,'4')}> Reject </button> 
                                </>
                                :''
                                }
                                 </td>
                              </tr>
                              )
                            })}
                            </>
                            :
                            <tr><td>No data found</td></tr>
                            } 
                          </tbody>
                    </table>
                    {
                      selectedProductList && selectedProductList.length > 0 ?
                       <>
                    <div className="col-md-12">
                        <Pagination
                        totalItemsCount={ paginate && paginate.total_data}
                        onChange={handlePageChange}
                        activePage={  paginate && paginate.current_page}
                        itemsCountPerPage={paginate && paginate.per_page}
                        pageRangeDisplayed={10}
                        />
                    </div>  </>
                        : <></>
                    
                    }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
            {/* enable disable */}
          <Modal size="sm" show={dshow} onHide={handleClose} centered>
            <Modal.Header>
              {enables && enables.status == '1' ?
                <Modal.Title className="h6 fw-bolder">Are You Sure to make Disable?</Modal.Title>:
                <Modal.Title className="h6 fw-bolder">Are You Sure to make Enable?</Modal.Title>
               }
            </Modal.Header>
            <Modal.Footer className="p-1 justify-content-start">
              <button onClick={()=>handleClose()} className="btn btn-light btn-sm">
                  No
              </button>
              <button onClick={()=>sellerEnable(enables)} className="btn btn-primary btn-sm">
                  Yes
              </button>
            </Modal.Footer>
        </Modal> 

          {/* approve reject */}
          <Modal size="sm" show={show} onHide={approveClose} centered>
            <Modal.Header>
            {approve && approve.status == '1' ?
                <Modal.Title className="h6 fw-bolder">Are You Sure to Approve?</Modal.Title>:
                <Modal.Title className="h6 fw-bolder">Are You Sure to Reject?</Modal.Title>
              }
            </Modal.Header>
            <Modal.Footer className="p-1 justify-content-start">
              <button onClick={()=>approveClose()} className="btn btn-light btn-sm">
                  No
              </button>
              <button onClick={()=>sellerApprove()} className="btn btn-primary btn-sm">
                  Yes
              </button>
            </Modal.Footer>
        </Modal>

          <Modal size="sm" show={featureModal}  centered>
            <Modal.Header>
              <Modal.Title className="h6 fw-bolder">Are you Sure  ?</Modal.Title>:
            </Modal.Header>
            <Modal.Footer className="p-1 justify-content-start">
              <button  onClick={()=> setFeatureModal(false)} className="btn btn-light btn-sm">No</button>
              <button  onClick={()=> makeFeaturedFetch(1)} className="btn btn-primary btn-sm">Yes</button>
            </Modal.Footer>
        </Modal>
        </>
      }
    </div>

  );
}

export default ProductList;
const styles = {
  activeTable: { disply: 'table-row' },
  inActiveTable: { display: 'none' },
  activeBtn: { backgroundColor: '#ffc001ad' },
  activeBtnTxt: { color: '#000' },
}; 