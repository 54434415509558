import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Loader from '../../component/loader';
import { BiCategory, BiFilterAlt } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import Pagination from "react-js-pagination";
import { decrpt } from "../../component/encryption";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BrandMap() {  
  const [loader,setLoader] = useState(false)
  const [show,setShow] = useState(false)
  const [dshow,setDShow] = useState(false)
  const [Eshow,setEShow] = useState(false);
  const [errors , setErrors] = useState();
  const [ toastText,setToastText ] = useState('');
  const [paginate , setPaginate] = useState();
  const [lastCategory, setLastCategory] = useState();
  const [newLastCategory , setNewLastCategory] = useState();
  const [brandList , setBrandList] = useState();
  const [brandListfetch , setBrandListfetch] = useState();
  const [statusShow , setStatusShow] = useState();
  const [newUpdateStatus , setNewUpdateStatus] = useState();
  const [ category, setCategory ] = useState();
  const [ subCategory, setSubCategory ] = useState();
  const [disabled , setDisabled ] = useState(true);


  const [newBrand , setNewBrand] = useState();
  const [newBrandPreview , setNewBrandPreview] = useState();
  const [brandMapList , setBrandMapList] = useState();
  const [editBrand , setEditBrand ] = useState({
    name : '',
    category_id : '',
    size_chart : '',
    brand_id : ''
  });
// console.log(newBrand  , 'newSize upload check');
console.log(brandMapList , 'newLastCategory ');
  const handleOpen = () => {
    setShow(true);
    setCategory(false);
    setDisabled(true);
    getCategoryList();
    setErrors(false);
    setNewBrandPreview(false);
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleCloseDelete = () => {
    setDShow(false);
  };

  const editCall = (item) => {
    console.log(item, 'item chedfck');
    setEditBrand({
      ...editBrand,
      // name        : item.name,
      category_id : item.category_id,
      size_chart  : item.size_chart,
      brand_id    : item.brand_id
    });
    setNewBrand(item);
    setEShow(true);
    console.log(editBrand , 'setEditBrand');
  };
// console.log(newBrand , 'check new brand');
  const handleCloseEdit = () => {
    setEShow(false);
  };

  function handleChartPreview(e) {
    setNewBrandPreview({...newBrandPreview , size_chart:URL.createObjectURL(e.target.files[0])});
    setNewBrand({...newBrand, size_chart:e.target.files[0]});
    }
  function handleEditChartPreview(e) {
    setNewBrandPreview({...newBrandPreview , size_chart:URL.createObjectURL(e.target.files[0])});
    setEditBrand({...editBrand, size_chart:e.target.files[0]});
    }

    const handleCategory = (category_id) => {
      console.log(category_id , 'category_id');
      setSubCategory({
        ...subCategory,
        category_id : category_id
      })
      getSubCategoryList(category_id);
      console.log(subCategory , 'subCategory check');
    }
    
    const handleSubCategory = (sub_category_id) => {
      console.log(sub_category_id , 'sub_category_id');
      setNewLastCategory({
        ...newLastCategory,
        sub_category_id : sub_category_id,
      })
      getLastCategoryList(sub_category_id);
      console.log(newLastCategory , 'handle sub category fetch category');
    }
// status
const handleStatus = (item)=> {
  setStatusShow(true);
  setNewUpdateStatus(item);
}
const handleStatusClose = () =>{
  setStatusShow(false);
}
// sttaus

// fetch first category
const getCategoryList = async() => {
  try {
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`admin/category/list`, {type:1})
    setCategory(response.data.data.list)
  } catch (error) {
    console.error(error);
  }
}
// fetch first category


// fetch second category
const getSubCategoryList = async(category_id ) => {
  const data = {
    type : 2,
    category_id : category_id
  }
  try {
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`admin/category/dropdown/list`, data)
    console.log(response.data.data , ' fetch second cat with respct first');
    setSubCategory(response.data.data);
  } catch (error) {
    console.error(error);
  }
}

const getLastCategoryList = async(category_id) => {
  try {
  const data = {
    type : 3 ,
    category_id : category_id
  }
  console.log(category_id ,'sub_category_id');
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`admin/category/dropdown/list`, data)
    console.log('Last Level',response.data);
    setDisabled(false);
    setLastCategory(response.data.data)
    setPaginate(response.data.data.pagination);
  } catch (error) {
    console.error(error);
  }
}
// fetch last category
// fetch brandFetch
const fetchbrandList = async () => {
    setLoader(true);
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`/admin/master/brand/list` )
    .then((response) => {
      setBrandListfetch(response.data.data.list);
      setPaginate(response.data.data.pagination);
      setLoader(false);
    }).catch((err) => {
      console.log('seller list error' , err.response)
      setLoader(false);
    })
  }
// fetch brandFetch

// add brandmap
const addBrandMap = async() => {
  setLoader(true);
  try {
        const formData = new FormData();
        formData.append("category_id", newLastCategory.category_id);
        formData.append("brand_id", newBrand.brand_id);
        formData.append("size_chart", newBrand.size_chart);
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      const response = await axios.post(`/admin/master/brand/map/save`, formData)
      .then((response)=>{
          handleClose();
          console.log(response.data.data);
          brandMapList.unshift(response.data.data);
            setEShow(false);
            setLoader(false);
            setToastText("Brand Map Added")
            toast.success(toastText,{hideProgressBar:true})
      }).catch((err)=>{
        console.error('Error',err);
        setToastText("Something went wrong")
        toast.danger(toastText,{hideProgressBar:true})
        setErrors('Please Fill all the inputs');
        setEShow(false);
        setLoader(false);
      })
    } catch (error) {
      console.log(error , 'eooror');
      setEShow(false);
      setLoader(false);
      if(error.response.status == 412){
        setErrors(error.response.data.message);
      }else {
        setErrors('please fill all the Inputs')
      }
    }
  }
// add brand map
// update brandmap
const updateBrandMap = async() => {
    setLoader(true);
    const formData = new FormData();
    formData.append("category_id", editBrand.category_id);
    formData.append("brand_id", editBrand.brand_id);
    formData.append("id", editBrand.id);
    formData.append("size_chart", editBrand.size_chart);
try {
  console.log(formData.get('category_id') , 'category_id');
  const token = Cookies.get('s_l');  
  const newToken = decrpt(token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
  const response = await axios.post(`/admin/master/brand/map/save`, formData)
  .then((response)=>{
    if(response.data.success){
      handleClose();
      let newData = response.data.data;
      console.log(newData , 'newData');
      let tmparray = brandList;
      let obj = tmparray.find((o, i) => {
        if (o.id) {
            tmparray[i] = {...brandList[i] , ...newData };
            console.log('true',tmparray[i])
              return true; 
          }
        });
        setLoader(false);
        setEShow(false);
      setToastText("Updated Brand Map")
      toast.success(toastText,{hideProgressBar:true})
    }
  }).catch((err)=>{
    console.error('Error',err);
    // setErrors(err.response.data.errors);
    console.log(errors);
    setToastText("Something went wrong")
    toast.danger(toastText,{hideProgressBar:true})
    setLoader(false);
  })
} catch (error) {
  console.error(error);
//   setEShow(false);
}
}

// update brand map

  const fetchBrandMapList = async () => {
    setLoader(true);
    const data = {
        status : 'all'
    }
    setLoader(true);
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`/admin/master/brand/map/list`, data )
    .then((response) => {
      setBrandMapList(response.data.data.list);
      setPaginate(response.data.data.pagination);
      console.log(response.data.data);
      setLoader(false);
    }).catch((err) => {
      console.log('seller list error' , err.response)
      setLoader(false);
    })
  }
// status of brand map

const statusBrandMap = async()=> {
    setLoader(true);
    let newstatus = '';
    if (newUpdateStatus.status == 1) {
      newstatus = 2;
    } else  {
      newstatus = 1;
    }
    const data = {
      id: newUpdateStatus.id,
      status : newstatus
    }
    try {
        const token = Cookies.get('s_l');
        const newToken = decrpt(token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
        const response = await axios.post(`/admin/master/brand/map/status`, data ).then((response) => {
          console.log('Response',response);
          let tmparray = brandMapList;
          let obj = tmparray.find((o, i) => {
            if (o.id === newUpdateStatus.id) {
                tmparray[i] = {...brandMapList[i] ,   status : newstatus  };
                return true; 
              }
            });
            setToastText("Brand Map Status Updated")
            toast.success(toastText,{hideProgressBar:true})
            setLoader(false);
            setStatusShow(false);
        })
    }catch(error) {
        console.log(error);
        setToastText("Something went wrong")
        toast.success(toastText,{hideProgressBar:true})
        setLoader(false);
    }
  }
// statuys of brand map

// pagination
const handlePageChange = async (pageNumber) => {
  setLoader(true);
  console.log(`active page is ${pageNumber}`);
  const token = Cookies.get('s_l');  
  const newToken = decrpt(token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
  const response = await axios.post(`/admin/master/brand/list?page=${pageNumber}`, {type: 1} )
  .then((response) => {
    // setSizeList(response.data.data.list);
    setLoader(false);
    console.log(response.data);
  }).catch((err) => {
    console.log('seller list error' , err.response)
    setLoader(false);
  })
}
// pagination
  useEffect (() => {
    window.scrollTo(0, 0);
    setLoader(true);
    getCategoryList();
    fetchBrandMapList();
    setTimeout(async() => {
        // await getLastCategoryList(); 
        await fetchbrandList();     
      setLoader(false);
    }, 500);
  }, []);

  // useEffect(() =>{

  // })

  return (
    <div className="main-content">
      {loader ? 
        <Loader />
        :
        <>
      <ToastContainer position="top-center"  autoClose={3000}  hideProgressBar={false}  newestOnTop={false}  closeOnClick  rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'colored'}/>
          {/* New User Registration */}
          <div className="section">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                  <h5><BiCategory className="me-1" />All brand category mapping</h5>
                </div>
                <div className="card p-2 mt-3">
                  <div className="card-title">      
                    <h5>You have { brandMapList && brandMapList.length} Brand Maps</h5>            
                    <Button onClick={()=> handleOpen()} className="badge btn-xs pt-1">Add New Brand Map</Button>
                  </div>
                  <div>
                    <table className='table tabe-bordered'>
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Brand</th>
                                <th>Category</th>
                                <th>Size Chart</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            brandMapList && brandMapList.length > 0 ? 
                            <>
                            { brandMapList && brandMapList.map((item,index) => {
                                    return (
                                        <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.brand}</td>
                                        <td>{item && item.category}</td>
                                        <td>
                                        <img  src={item && item.size_chart} style={{ width: 100,height: 100 }} />
                                        </td>
                                        <td className="text-center">
                                            { item &&  item.status == 1 ?
                                            <Badge bg="success">Enable</Badge>:
                                            <Badge bg="danger">Disable</Badge>}
                                            </td>
                                        <td className="text-center">
                                          <button onClick={()=>editCall(item)} className='btn btn-xs btn-primary text-white'>Edit</button> &nbsp;
                                          {item && item.status == 1 ? 
                                <button onClick={()=>handleStatus(item)} className='btn btn-xs btn-danger text-white'>Make Disable</button>:
                                <button onClick={()=>handleStatus(item)} className='btn btn-xs btn-success text-white'>Make Enable</button>}                                  
                                        </td>
                                    </tr>
                                    )
                                   }
                                )
                            }
                            </> 
                            : 
                            <><tr><td>No data found</td></tr></>
                        }
                               
                             
                        </tbody>
                    </table>
                    { paginate && paginate  ?
                    <div className="col-md-12">
                        <Pagination
                        totalItemsCount={ paginate.total_data}
                        onChange={handlePageChange}
                        activePage={  paginate.current_page}
                        itemsCountPerPage={paginate.per_page}
                        // pageRangeDisplayed={10}
                        />
                    </div>
                : <></>
                }
                  </div>

                  {/* Add Cateory */}
                  <Modal size="md" show={show} onHide={handleClose}>
                      <Modal.Header className="py-1" closeButton>
                          <Modal.Title>Add Brand Map</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <Form>
                          {errors && errors ? <p style={{color : 'red' , fontSize : '12px'}}> {errors}</p> : ''}
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Cartegory name</Form.Label>
                            <Form.Select aria-label="Default select example" 
                              onChange = {category => handleCategory(category.target.value)}
                            >
                              <option selected disabled>----select from below----</option>
                            {
                              category  && category.map((item,index) => {
                                  return (
                                    <option key={index} value={item.id}> {item.name} </option>
                                  )
                              })
                            }
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Select Sub Category</Form.Label>
                                <Form.Select aria-label="Default select example"
                                //  onChange={e => subCategoryHandler(e)}
                                 onChange = {sub_category => handleSubCategory(sub_category.target.value)}
                                 >
                                  {subCategory && subCategory.length > 0 ?
                                  <>
                                      <option selected disabled>----select from below</option>
                                    { subCategory.map((item,index) => {
                                      return <option key={index}  value={item.id}>{item.name}</option>
                                    })}
                                  </>:
                                  <>
                                    <option disabled>Select Category First</option>
                                  </>
                                  }
                                </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Last Category</Form.Label>
                            <Form.Select aria-label="Default select example" 
                            onChange={e => setNewLastCategory({...newLastCategory, category_id:e.target.value})}
                            >
                              <option>---- select below -------</option>
                              {lastCategory && lastCategory.map((item,index) => {
                                return <option key={index}  value={item.id}>{item.name}</option>
                              })}
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Brand</Form.Label>
                            <Form.Select aria-label="Default select example" 
                            onChange={e => setNewBrand({...newBrand, brand_id:e.target.value})}
                            disabled={disabled} 
                            >
                              <option>---- select below -------</option>
                              {brandListfetch && brandListfetch.map((item,index) => {
                                return <option key={index}  value={item.id}>{item.name}</option>
                              })}
                            </Form.Select>
                           
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Brand Map image </Form.Label>
                            <Form.Control type="file" onChange={handleChartPreview} />
                            {/* {errors && errors ? <p>{errors}</p> : ''} */}
                              </Form.Group>    
                            <img src={newBrandPreview && newBrandPreview !== '' ? newBrandPreview.size_chart: '/folder.png'} style={{ width: 100,height: 100 }} />

                          </Form>
                      </Modal.Body>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button onClick={()=>handleClose()} className="btn btn-light btn-sm">
                              Close
                          </button>
                          <button onClick={()=>addBrandMap()} className="btn btn-primary btn-sm">
                              Save Changes
                          </button>
                      </Modal.Footer>
                  </Modal> 

                  {/* Delete Cateory */}
                  <Modal size="md" show={statusShow} onHide={handleCloseDelete} centered>
                      <Modal.Header>
                          <Modal.Title className="h6 fw-bolder">Do you want to  {
                          newUpdateStatus && newUpdateStatus.status == 1 ?  'Disable' : 'Enable'
                        }</Modal.Title>
                      </Modal.Header>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button onClick={()=>handleStatusClose()} className="btn btn-light btn-sm">
                              Close
                          </button>
                          <button onClick={()=>statusBrandMap()} className="btn btn-primary btn-sm">
                          {newUpdateStatus && newUpdateStatus.status == 1 ?  'Make Disable' : 'Make Enable'}
                          </button>
                      </Modal.Footer>
                  </Modal> 

                  {/* Edit Cateory */}
                  <Modal size="md" show={Eshow}>
                      <Modal.Header>
                          <Modal.Title>Edit Brand Map</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Cartegory name</Form.Label>
                            <Form.Select aria-label="Default select example" 
                              onChange = {category => handleCategory(category.target.value)}
                            >
                              <option selected disabled>----select from below----</option>
                            {
                              category  && category.map((item,index) => {
                                  return (
                                    <option key={index} value={item.id}> {item.name} </option>
                                  )
                              })
                            }
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Select Sub Category</Form.Label>
                                <Form.Select aria-label="Default select example"
                                //  onChange={e => subCategoryHandler(e)}
                                 onChange = {sub_category => handleSubCategory(sub_category.target.value)}
                                 >
                                  {subCategory && subCategory.length > 0 ?
                                  <>
                                      <option selected disabled>----select from below</option>
                                    { subCategory.map((item,index) => {
                                      return <option key={index}  value={item.id}>{item.name}</option>
                                    })}
                                  </>:
                                  <>
                                    <option disabled>Select Category First</option>
                                  </>
                                  }
                                </Form.Select>
                          </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Category</Form.Label>
                            <Form.Select aria-label="Default select example" 
                            onChange={e => setEditBrand({...editBrand, category_id:e.target.value})}
                            >
                              {lastCategory && lastCategory.map((item,index) => {
                                return <option key={index}  value={item.id}>{item.name}</option>
                              })}
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Brand</Form.Label>
                            <Form.Select aria-label="Default select example" 
                            onChange={e => setEditBrand({...editBrand, brand_id:e.target.value})}
                            >
                              {brandListfetch && brandListfetch.map((item,index) => {
                                return <option key={index}  value={item.id}>{item.name}</option>
                              })}
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Brand Map image </Form.Label>
                            <Form.Control type="file" onChange={handleEditChartPreview} />
                              </Form.Group>    
                            <img src={newBrandPreview && newBrandPreview !== '' ? newBrandPreview.size_chart: '/folder.png'} style={{ width: 100,height: 100 }} />
                      </Modal.Body>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button onClick={()=>handleCloseEdit()} className="btn btn-light btn-sm">
                              Close
                          </button>
                          <button onClick={()=>updateBrandMap()} className="btn btn-primary btn-sm">
                              Save Changes
                          </button>
                      </Modal.Footer>
                  </Modal> 

                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default BrandMap;
