import React, { useEffect, useState } from "react";
// import { Outlet, Link } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Loader from '../../component/loader';
import { BiCategory, BiFilterAlt } from "react-icons/bi";
import { Modal ,Row } from "react-bootstrap";

import Cookies from "js-cookie";
import { decrpt } from "../../component/encryption";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Pagination from "react-js-pagination";


function TaxMaster() {  
  const [loader,setLoader] = useState(false);
  const [show,setShow] = useState(false);
  const [Eshow , setEShow] = useState(false);
  const [paginate , setPaginate] = useState();
  const [statusShow , setStatusShow] = useState();
  const [newUpdateStatus , setNewUpdateStatus] = useState();
  const [errors, setErrors ] = useState();
  const [ toastText,setToastText ] = useState('');
  const [taxList , setTaxList ] = useState('');
  const [ category, setCategory ] = useState();
  const [ subCategory, setSubCategory ] = useState();
  const [lastCategory, setLastCategory] = useState();
  const [disabled , setDisabled ] = useState(true);
  const [data, setData ] = useState();
  const [newLastCategory , setNewLastCategory] = useState();
  const [newTax , setNewTax] = useState();
  const [newCommision , setNewCommision] = useState();
  const [editTax , setEditTax] = useState();
  const [editCommision , setEditCommision] = useState();


 console.log(errors , 'errors');
  const handleOpen = () => {
    setShow(true);
    setErrors(false);
    getCategoryList();
    setNewTax(false);
    setNewCommision(false);
  };

  const handleClose = () => {
    setShow(false);
  };

  const editCall = (item) => {
    getCategoryList();
    setEShow(true);
    console.log(item);
    setData(item);
    setErrors(false);
    setNewLastCategory(item.category_id);
    console.log(data.id , 'check editBrand');
  };
  const handleCloseEdit = () => {
    setEShow(false);
  };

  const handleStatus = (item)=> {
    setStatusShow(true);
    setNewUpdateStatus(item);
  }
  const handleStatusClose = () =>{
    setStatusShow(false);
  }

  const handleCategory = (category_id) => {
    console.log(category_id , 'category_id');
    setSubCategory({
      ...subCategory,
      category_id : category_id
    })
    getSubCategoryList(category_id);
    console.log(subCategory , 'subCategory check');
  }
  
  const handleSubCategory = (sub_category_id) => {
    console.log(sub_category_id , 'sub_category_id');
    setNewLastCategory({
      ...newLastCategory,
      sub_category_id : sub_category_id,
    })
    getLastCategoryList(sub_category_id);
    console.log(newLastCategory , 'handle sub category fetch category');
  }  


 const fetchTaxMaster = async () => {
    setLoader(true);
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`/admin/tax_commissions/list`)
    .then((response) => {
      setLoader(false);
      console.log('tax master commision' , response.data);
      setTaxList(response.data.data);
    }).catch((err) => {
      console.log('tax master commision' , err.response);
      setLoader(false);
    })
  }

const taxStatus = async() => {
  setLoader(true);
  try {
    let newstatus = '';
    if (newUpdateStatus.status == 1) {
      newstatus = 2;
    } else {
      newstatus = 1;
    }
    const data = {
      id: newUpdateStatus.id,
      status  : newstatus
    }
    // console.log(data , 'data of api getc')
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
    const response = await axios.post(`/admin/tax_commissions/status/update` , data)
    .then((response) => {
      let tmparray = taxList;
      let obj = tmparray.find((o, i) => {
          if (o.id === newUpdateStatus.id) {
            tmparray[i] = {...taxList[i] ,   status : newstatus  };
            console.log('true',tmparray[i])
              return true; 
          }
      });
    })
    setStatusShow(false)
    setToastText("Status Updated");
    toast.success(toastText,{hideProgressBar:true})
  setLoader(false);
  }catch(error) {
      console.log(error, 'error');
      setLoader(false);
      setToastText("Something went wrong");
    toast.success(toastText,{hideProgressBar:true})
  }
}


const getCategoryList = async() => {
  try {
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`admin/category/list`, {type:1})
    setCategory(response.data.data.list)
  } catch (error) {
    console.error(error);
  }
}
// fetch first category


// fetch second category
const getSubCategoryList = async(category_id ) => {
  const data = {
    type : 2,
    category_id : category_id
  }
  try {
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`admin/category/dropdown/list`, data)
    console.log(response.data.data , ' fetch second cat with respct first');
    setSubCategory(response.data.data);
  } catch (error) {
    console.error(error);
  }
}

const getLastCategoryList = async(category_id) => {
  try {
  const data = {
    type : 3 ,
    category_id : category_id
  }
  console.log(category_id ,'sub_category_id');
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`admin/category/dropdown/list`, data)
    console.log('Last Level',response.data);
    setDisabled(false);
    setLastCategory(response.data.data);
    console.log(response.data.data , 'cscscs');
    setPaginate(response.data.data.pagination);
  } catch (error) {
    console.error(error);
  }
}

const addTaxMaster = async() => {
  try{
    setLoader(true);
  const data = {
    commission : newCommision.commission,
    tax        : newTax.tax,
    category_id   : newLastCategory.category_id
  }
  const token = Cookies.get('s_l');  
  const newToken = decrpt(token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
  const response = await axios.post(`admin/tax_commissions/add` , data)
  .then((response) => {
    console.log(response.data);
    taxList.unshift(response.data.data);
    setLoader(false);
    setShow(false);
    setToastText("Tax Master Added")
    toast.success(toastText,{hideProgressBar:true})
    })
}catch(err){
  setLoader(false);
  console.log(err);
  setErrors(err.response.data.errors);
  setShow(true);
  
}
}


const editTaxMaster = async() => {
  try{
    setLoader(true);
  const data2 = {
    id         : data.id,
    commission : editCommision.commission,
    tax        : editTax.tax,
    category_id   : newLastCategory.category_id
  }
  const token = Cookies.get('s_l');  
  const newToken = decrpt(token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
  const response = await axios.post(`admin/tax_commissions/add` , data2)
  .then((response) => {
    console.log(response.data);
    let newData = response.data.data;
    let tmparray = taxList;
        let obj = tmparray.find((o, i) => {
          if (o.id === newData.id) {
              tmparray[i] = {...taxList[i] , ...newData };
              console.log('true',tmparray[i])
                return true; 
            }
          });
    setLoader(false);
    setEShow(false);
    })
}catch(err){
  setLoader(false);
  console.log(err);
  setErrors(err.response.data.errors);
  setEShow(true);
}
}




// pagination

// const handlePageChange = async (pageNumber) => {
//   setLoader(true);
//   console.log(`active page is ${pageNumber}`);
//   const token = Cookies.get('s_l');  
//   const newToken = decrpt(token);
//   axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
//   const response = await axios.post(`admin/category/list?page=${pageNumber}` )
//   .then((response) => {
//     setBrandList(response.data.data.list);
//     setLoader(false);
//   }).catch((err) => {
//     console.log('seller list error' , err.response)
//     setLoader(false);
//   })
// }
// pagination

  useEffect (() => {
    window.scrollTo(0, 0);
    fetchTaxMaster();
  }, []);
  return (
    <div className="main-content">
      {loader ? 
        <Loader />
        :
        <>
      <ToastContainer position="top-center"  autoClose={3000}  hideProgressBar={false}  newestOnTop={false}  closeOnClick  rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'colored'}/>      

          {/* New User Registration */}
          <div className="section">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                  <h5><BiCategory className="me-1" />All Tax master</h5>
                </div>
                <div className="card p-2 mt-3">
                  <div className="card-title">      
                    <h5>You have 10 Tax Masters</h5>            
                    <Button onClick={()=> handleOpen()} className="badge btn-xs pt-1">Add New Brands</Button>
                  </div>
                  <div>
                    <table className='table tabe-bordered'>
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Tax Percent</th>
                                <th>Commission Percent</th>
                                <th>#rd Category Name</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                          {
                            taxList && taxList.length > 0 ? taxList.map((item,index) => {
                              return (
                                <tr key={index}>
                                    <td>{item.id}</td>
                                    <td> {item.tax}%</td>
                                    <td> {item.commission}%</td>
                                    
                                    <td className="text-center">
                                    {item && item.status == 1 ?
                                      <Badge bg="success">Enable</Badge>:
                                      <Badge bg="danger">Disable</Badge>}
                                    </td>
                                    <td className="text-center">
                                        <button onClick={()=>editCall(item)} className='btn btn-xs btn-primary text-white'>Edit</button> &nbsp;
                                        {item && item.status == 1 ?
                                        <button type="button" onClick={()=>handleStatus(item)} className='btn btn-xs btn-danger text-white'>Make Disable</button>:
                                        <button type="button" onClick={()=>handleStatus(item)} className='btn btn-xs btn-success text-white'>Make Enable</button>}  
                                      </td>
                                </tr>
                              );
                            }) :
                            <tr>
                            <td colSpan={6} className="text-center fst-italic text-danger">No Tax Commision  added</td>
                          </tr>
                          }
                          
                        </tbody>
                    </table>
                    {/* { brandList && brandList  ?
                    <div className="col-md-12">
                    <Pagination
                    totalItemsCount={ paginate.total_data}
                    onChange={handlePageChange}
                    activePage={  paginate.current_page}
                    itemsCountPerPage={paginate.per_page}
                    prevPageText={false}
                    nextPageText={false}
                    />
                </div>
                : <></>
                } */}
                  </div>

                </div>
                <Modal size="xl" show={show} centered>
                    <Modal.Header className="py-1">
                        <Modal.Title>Add Tax & Commision</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {/* {errors && errors ? <p style={{color : 'red' , fontSize : '12px'}}> {errors}</p> : ''} */}
                    <Row>
                          <Form.Group className=" col-md-4 mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Cartegory name</Form.Label>
                            <Form.Select aria-label="Default select example" 
                              onChange = {category => handleCategory(category.target.value)}
                            >
                              <option selected disabled>----select from below----</option>
                            {
                              category  && category.map((item,index) => {
                                  return (
                                    <option key={index} value={item.id}> {item.name} </option>
                                  )
                              })
                            }
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className=" col-md-4  mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Select Sub Category</Form.Label>
                                <Form.Select aria-label="Default select example"
                                //  onChange={e => subCategoryHandler(e)}
                                 onChange = {sub_category => handleSubCategory(sub_category.target.value)}
                                 >
                                  {subCategory && subCategory.length > 0 ?
                                  <>
                                      <option selected disabled>----select from below</option>
                                    { subCategory.map((item,index) => {
                                      return <option key={index}  value={item.id}>{item.name}</option>
                                    })}
                                  </>:
                                  <>
                                    <option disabled>Select Category First</option>
                                  </>
                                  }
                                </Form.Select>
                          </Form.Group>
                          <Form.Group className=" col-md-4  mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Last Category</Form.Label>
                            <Form.Select aria-label="Default select example" 
                            onChange={e => setNewLastCategory({...newLastCategory, category_id:e.target.value})}
                            >
                              <option>---- select below -------</option>
                              {lastCategory && lastCategory.map((item,index) => {
                                return <option key={index}  value={item.id}>{item.name}</option>
                              })}
                            </Form.Select>
                          </Form.Group>
                        <Form.Group className="col-md-6 mb-3 " controlId="exampleForm.ControlInput1">
                              <Form.Label>Tax percent</Form.Label>
                              <Form.Control size="sm" type="text" placeholder="Add new Tax" 
                                onChange={e=> setNewTax({...newTax , tax:e.target.value})}
                                />
                                 {
                                  errors && errors.tax ? <p style={{color : 'red' , fontSize : '12px'}}>{errors.tax
                                  }</p> : ''
                                }
                        </Form.Group>
                        <Form.Group className="col-md-6 mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>Commision percent</Form.Label>
                              <Form.Control size="sm" type="text" placeholder="Add new Commision"
                               onChange={e=> setNewCommision({...newCommision , commission:e.target.value})}
                              />
                               {
                                  errors && errors.commission ? <p style={{color : 'red' , fontSize : '12px'}}>{errors.commission
                                  }</p> : ''
                                }
                        </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                          <button onClick={()=>handleClose()} className="btn btn-light btn-sm">Close</button>
                          <button onClick={()=>addTaxMaster()} className="btn btn-primary btn-sm">Save Changes</button>
                    </Modal.Footer>
                </Modal>
                <Modal size="md" show={statusShow}  centered>
                      <Modal.Header className="py-1">
                          <Modal.Title className="h6">Do you want to  {
                              newUpdateStatus && newUpdateStatus.status == 1 ?  'Disable' : 'Enable'
                            } Sub category ? <br /><span className="text-danger text-capitalize fw-bold">{newUpdateStatus && newUpdateStatus.name}</span></Modal.Title>
                      </Modal.Header>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button  onClick={()=> handleStatusClose()} className="btn btn-light btn-sm">
                              Close
                          </button>
                          <button  className="btn btn-primary btn-sm" onClick={() => taxStatus()} >
                            {newUpdateStatus && newUpdateStatus.status == 1 ?  'Make Disable' : 'Make Enable'}
                          </button>
                      </Modal.Footer>
                </Modal> 

                <Modal size="xl" show={Eshow} centered>
                    <Modal.Header className="py-1">
                        <Modal.Title>Edit Tax & Commision</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {/* {errors && errors ? <p style={{color : 'red' , fontSize : '12px'}}> {errors}</p> : ''} */}
                    <Row>
                          <Form.Group className=" col-md-4 mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Cartegory name</Form.Label>
                            <Form.Select aria-label="Default select example" 
                              onChange = {category => handleCategory(category.target.value)}
                            >
                              <option selected disabled>----select from below----</option>
                            {
                              category  && category.map((item,index) => {
                                  return (
                                    <option key={index} value={item.id}> {item.name} </option>
                                  )
                              })
                            }
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className=" col-md-4  mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Select Sub Category</Form.Label>
                                <Form.Select aria-label="Default select example"
                                //  onChange={e => subCategoryHandler(e)}
                                 onChange = {sub_category => handleSubCategory(sub_category.target.value)}
                                 >
                                  {subCategory && subCategory.length > 0 ?
                                  <>
                                      <option selected disabled>----select from below</option>
                                    { subCategory.map((item,index) => {
                                      return <option key={index}  value={item.id}>{item.name}</option>
                                    })}
                                  </>:
                                  <>
                                    <option disabled>Select Category First</option>
                                  </>
                                  }
                                </Form.Select>
                          </Form.Group>
                          <Form.Group className=" col-md-4  mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Last Category</Form.Label>
                            <Form.Select aria-label="Default select example" 
                            onChange={e => setNewLastCategory({...newLastCategory, category_id:e.target.value})}
                            >
                              <option>---- select below -------</option>
                              {lastCategory && lastCategory.map((item,index) => {
                                return <option key={index}  value={item.id}>{item.name}</option>
                              })}
                            </Form.Select>
                            {
                                  errors && errors.category_id ? <p style={{color : 'red' , fontSize : '12px'}}>{errors.category_id
                                  }</p> : ''
                                }
                          </Form.Group>
                        <Form.Group className="col-md-6 mb-3 " controlId="exampleForm.ControlInput1">
                              <Form.Label>Edit Tax percent</Form.Label>
                              <Form.Control size="sm" type="text" placeholder="Add new Tax" 
                              defaultValue={data && data.tax}
                                onChange={e=> setEditTax({...editTax , tax:e.target.value})}
                                />
                                {
                                  errors && errors.tax ? <p style={{color : 'red' , fontSize : '12px'}}>{errors.tax}</p> : ''
                                }
                        </Form.Group>
                        <Form.Group className="col-md-6 mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>Edit Commision percent</Form.Label>
                              <Form.Control size="sm" type="text" placeholder="Add new Commision"
                              defaultValue={data && data.commission}
                               onChange={e=> setEditCommision({...editCommision , commission:e.target.value})}
                              />
                              {
                                  errors && errors.commission ? <p style={{color : 'red' , fontSize : '12px'}}>{errors.commission
                                  }</p> : ''
                                }
                        </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                          <button onClick={()=>handleCloseEdit()} className="btn btn-light btn-sm">Close</button>
                          <button onClick={()=>editTaxMaster()} className="btn btn-primary btn-sm">Save Changes</button>
                    </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default TaxMaster;
