import React, { useEffect, useState } from "react";
import {  useLocation , useNavigate } from "react-router-dom";
import { FaPhoneAlt, FaRegEnvelopeOpen, FaRegPaperPlane } from "react-icons/fa";
import { AiOutlinePoweroff, AiOutlineKey } from "react-icons/ai";
import Badge from 'react-bootstrap/Badge';
import Loader from "../../component/loader";
import { Chart } from "react-google-charts";
import Address from './component/address'
import Cookies from "js-cookie";
import { decrpt } from "../../component/encryption";
import axios from "axios";



const data = [
  ["Month", "User"],
  ["Jan-Mar", 300],
  ["Aprl-Jun", 500],
  ["Jul-Sep", 450],
  ["Oct-Dec", 837],
];

const options = {
  // title: "NEW ORDER",
  hAxis: { title: "Year", titleTextStyle: { color: "#333" } },
  vAxis: { minValue: 0 },
  chartArea: { width: "60%", height: "75%" },
};

function UserSingle() {  
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location, 'location data');
  const [loader,setLoader] = useState(false);
  const [userDeatils , setUserDeatils ] = useState();
  const [ dashData,  setDashData] = useState();
  const [cart , setCart ] = useState();
  const [wishList , setWishList ] = useState();
 
  let itemId = location.state && location.state.item;
  console.log(wishList , 'cnsucnsucn')


  const userDeatilsFetch = async() => {
    try{
      setLoader(true);
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
    const response = await axios.post(`/admin/user/details` ,{user_id : itemId})
    .then((response) => {
      console.log(response.data.data.user_details.address , 'check response');
      setUserDeatils(response.data.data.user_details);
      setDashData(response.data);
      setCart(response.data.data.user_details.cart)
      setWishList(response.data.data.user_details.wishlist)
      setLoader(false);
    })
  }catch(err){
    console.log(err , 'err');
    setLoader(false);
  }
  }
  const userIdSend =(item)=> {
    navigate('/order/single' , {state : {item}});
  }

  useEffect (  () => {
    window.scrollTo(0, 0);
    userDeatilsFetch();

  }, []);
  return (
    <div className="main-content">
      {loader ? 
        <Loader />
        :
        <>
          {/* New User Registration */}
          <div className="section">
            <div className="row">

              <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <h3 className="h1 fw-bold" style={styles.header}>{userDeatils && userDeatils.name}</h3>
                  <p>Gender : {userDeatils && userDeatils.gender == "M" ? 'Male' : (userDeatils && userDeatils.gender == "F" ? "Female" : "")   }</p>
                  <div className="d-flex ">
                    <p> <FaPhoneAlt /> {userDeatils && userDeatils.mobile}</p>
                    {userDeatils && userDeatils.email ? 
                      <p> <FaRegEnvelopeOpen />  { ' &nbsp;  | &nbsp; ' + userDeatils && userDeatils.email}</p>
                      : ''
                    }
                  </div>
                  <p className="mb-0">
                 
                      {/* {userDeatils && userDeatils.address ?
                      <>
                      {  userDeatils.address && userDeatils.address[0].address   !== null ? ( '  ' + userDeatils.address[0].address + ' , ') : ''  }
                      { userDeatils.address && userDeatils.address[0].land_mark  !== null ? userDeatils.address[0].land_mark : ''  }
                      { userDeatils.address && userDeatils.address[0].city_name  !== null ? (userDeatils.address[0].city_name + ' , ') : '' }
                      { userDeatils.address && userDeatils.address[0].street     !== null ? (userDeatils.address[0].street + ' , ') : '' }
                      { userDeatils.address && userDeatils.address[0].state_name !== null ? (userDeatils.address[0].state_name ) : '' }
                      </>
                        :
                        <>
                        </>
                    } */}
                    {
                      userDeatils  && userDeatils.address != null ?
                      userDeatils.address.map((item,index) => {
                        return (
                          <>
                               <FaRegPaperPlane  /> 
                            { item.address   !== null ? ( '  ' +item.address + ' , ') : ''}
                            {item.land_mark  !== null ?item.land_mark : ''  }
                            {item.city_name  !== null ? (item.city_name + ' , ') : '' }
                            {item.street     !== null ? (item.street + ' , ') : '' }
                            {item.state_name !== null ? (item.state_name ) : '' }
                            <br/>
                          </>
                        );
                      }): ''
                    }
                    </p>
                  <Address 
                    userDeatils= { userDeatils && userDeatils}
                  />
                  {/* this section is pending */}
                  <div className="d-flex justify-content-evenly" style={{gap:12}}>
                    <div className="text-center" style={styles.box}>
                      <h3 style={styles.boxNumber}>{dashData && dashData.new_orders}</h3>  
                      <h4 style={styles.boxHeading}>New Orders</h4>
                    </div>
                    <div className="text-center" style={styles.box}>
                      <h3 style={styles.boxNumber}>{dashData && dashData.cancelled_orders}</h3>  
                      <h4 style={styles.boxHeading}>Total Order Cancelled</h4>
                    </div>
                    <div className="text-center" style={styles.box}>
                      <h3 style={styles.boxNumber}>{dashData && dashData.total_cart}</h3>  
                      <h4 style={styles.boxHeading}>Total Cart Items</h4>
                    </div>
                    <div className="text-center" style={styles.box}>
                      <h3 style={styles.boxNumber}> {dashData && dashData.total_wishlist} Nos</h3>  
                      <h4 style={styles.boxHeading}>Total Wishlist Items</h4>
                    </div>
                    <div className="text-center" style={styles.box}>
                      <h3 style={styles.boxNumber}>₹ {dashData && dashData.total_purchase}.00</h3>  
                      <h4 style={styles.boxHeading}>Total Purchace</h4>
                    </div>
                  </div>
                  {/* this section is pending */}
                </div>
              </div>

              {/* <div className="col-md-4">
                <div className="card mb-3">
                  <Chart
                    chartType="AreaChart"
                    width="100%"
                    height="300px"
                    data={data}
                    options={options}
                  />
                </div>
              </div> */}

              {/* Past Order */}
              

              {/* Cart Items */}
              <div className="col-md-12 mb-3">
                <div className="card p-2">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <h5>User Cart Items</h5>
                  </div>
                  <div>
                    <table className='table tabe-bordered'>
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th style={{maxWidth:175}}>Product Name</th>
                                <th >Brand</th>
                                <th >Product Price </th>
                                <th >Product MRP </th>
                                <th >Quantity </th>
                                <th >Catagory</th>
                                <th >Sub Catagory</th>
                                <th >Last Catagory</th>
                                <th >Added Date</th>
                            </tr>
                        </thead>
                        <tbody>
                          {cart && cart.map((item,index) => {
                            return (
                                <tr key={index}>
                                    <td>{index + 1} </td>
                                    <td>{item.product_name}</td>
                                    <td>{item.brand}</td>
                                    <td> ₹{item.product_price}.00</td>
                                    <td>₹{item.product_mrp}.00</td>
                                    <td>{item.quantity}Nos</td>
                                    <td>{item.category}</td>
                                    <td>{item.sub_category}</td>
                                    <td>{item.third_category}</td>
                                    <td >{item.created_at}</td>
                                </tr>
                            );
                          })}
                        </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* Wishlist Items */}
              <div className="col-md-12">
                <div className="card p-2">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <h5>User Wishlist Items</h5>
                  </div>
                  <div>
                    <table className='table tabe-bordered'>
                        <thead>
                            <tr>
                            <th>SL</th>
                                <th style={{maxWidth:175}}>Product Name</th>
                                <th >Brand</th>
                                <th >Product Price </th>
                                <th >Product MRP </th>
                                <th >Quantity </th>
                                <th >Catagory</th>
                                <th >Sub Catagory</th>
                                <th >Last Catagory</th>
                                <th >Added Date</th>
                            </tr>
                        </thead>
                        <tbody>
                          {
                            wishList && wishList.map((item,index) => {
                              return (
                                  <tr key={index} >
                                           <td>{index +1} </td>
                                    <td>{item.product_name}</td>
                                    <td>{item.brand}</td>
                                    <td> ₹{item.product_price}.00</td>
                                    <td>₹{item.product_mrp}.00</td>
                                    <td>{item.size_type == 1 ? 'One ' : 'Many ' }size</td>
                                    <td>{item.category}</td>
                                    <td>{item.sub_category}</td>
                                    <td>{item.third_category}</td>
                                    <td >{item.created_at}</td>
                                  </tr>
                              );
                            })
                          }
                        </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-12 my-4">
                <div className="card mb-3 p-2">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <h5>User Order Items</h5>
                  </div>
                  <div>
                    <table className='table tabe-bordered'>
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th style={{maxWidth:350}}>Order ID</th>
                                <th>Payment Type</th>
                                <th className="text-center">Total Price</th>
                                <th className="text-center">Shipping Charge</th>
                                <th className="text-center">Order Status</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                              userDeatils && userDeatils.orders.map((item,index) => {
                                return (
                                      <tr key={index} >
                                          <td>{index + 1} </td>
                                          <td>{item.order_uuid}</td>
                                          <td>{item.payment_type == 2 ? <Badge bg="info" text="dark">Cash On Delivery</Badge> : <Badge bg="info" text="dark">Online</Badge>}</td>
                                          <td className="text-center">₹{item.total_price}.00</td>
                                          <td className="text-center">₹ {item.shipping_chagre} .00</td>
                                          <td className="text-center">
                                          { item.order_status == 1 ? <Badge bg="primary"> New</Badge> : 
                                        (item.order_status == 2)  ? <Badge bg="success"> Accepted </Badge> : 
                                        (item.order_status == 6)  ? <Badge bg="danger"> Canceled </Badge> : 
                                        (item.order_status == 3) ? <Badge bg="warning"> Dispatch </Badge> : 
                                        (item.order_status == 4) ? <Badge bg="secondary"> Out For Delivered</Badge> : 
                                        <Badge bg="success"> Delivered</Badge> }</td>
                                          <td className="text-center">
                                            <button onClick={()=> userIdSend(item.id)} className='btn btn-xs btn-primary text-white'>View Order Detail</button>
                                          </td>
                                      </tr>
                                );
                              })
                            }

                        </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>

  );
}

export default UserSingle;
const styles = {
  header: {textTransform: 'uppercase',color:'#149348',marginBottom:0},
  box : {background:'#e1e1e1',borderRadius:10, paddingLeft:10,paddingRight:10,padding:10,flex:1,boxShadow: '0 0 5px 0 #868686',border: '2px dotted #fff'},
  boxHeading : {color:'#333',fontWeight:'900',fontSize:15,marginBottom:0},
  boxNumber : {color:'green',fontWeight:'900',fontSize:25,marginBottom:0},
}
