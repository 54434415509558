import  React ,{ createContext } from "react";
const AuthUser = createContext();
export default AuthUser;






// import axios from "axios";
// import { useState } from "react";

// export default function AuthUser() {

//     const [token,setToken] = useState()
//     const [user,setUser] = useState()

//     const saveToken = (user,token) => {
        
//     }

//     const axois = axios.create({
//         baseURL : 'http://jhle.webinfoghy.co.in/public',
//         headers : {
//             "Content-type" : "application/json"
//         }
//     });
//     return{
//         axois
//     }
// }