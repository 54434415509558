import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Loader from '../../component/loader';
import { BiCategory, BiFilterAlt } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import Cookies from 'js-cookie'
import { decrpt } from '../../component/encryption';
import Pagination from "react-js-pagination";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Return() {  
  const [ loader,setLoader ] = useState(false);
  const [ addreturnshow , setAddreturnshow] = useState();
  const [ editReturnshow , setEditReturnshow] = useState();
  const [ returnPolicyList , setReturnPolicyList ] = useState();
  const [ errors , setErrors ] = useState(false);
  const [ newStatus, setNewStatus] = useState();
  const [ statusShow , setStatusShow] = useState();
  const [ toastText,setToastText ] = useState('');
  const [ addPolicy , setAddPolicy ] = useState({
    // type : '',
    return_policy : '',
    status:"1"
  });
  const [editPolicy , setEditPolicy] = useState();

  const fetchReturnList = async () => {
    setLoader(true);
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`/admin/return/policy/list` )
    .then((response) => {
        setReturnPolicyList(response.data.data);
        setLoader(false);
        console.log(response.data.data , 'newBrand');
    }).catch((err) => {
      console.log('seller list error' , err.response)
      setLoader(false);
    })
  }

  const handleStatus = (item) => {
    setNewStatus(item);
    setStatusShow(true);
}
    const handleStatusClose = () => {
    setStatusShow(false);
  }
  const handleClose = () => {setAddreturnshow(false);}

  const handleCloseEdit = () => {setEditReturnshow(false);}

  const handleEdit = (item) => {
    setEditReturnshow(true);
    setEditPolicy(item);
    console.log(editPolicy , 'editPolicy');
  }
//   functions end

  const returnStatus = async() => {
    setLoader(true);
    try {
      let newstatus = '';
      if (newStatus.status == 1) {
        newstatus = 2;
      } else {
        newstatus = 1;
      }
      const data = {
        id: newStatus.id,
        status  : newstatus
      }
      console.log(data , 'data of api getc')
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
      const response = await axios.post(`/admin/return/policy/status/update` , data)
      .then((response) => {
        let tmparray = returnPolicyList;
        let obj = tmparray.find((o, i) => {
            if (o.id === newStatus.id) {
              tmparray[i] = {...returnPolicyList[i] ,   status : newstatus  };
              console.log('true',tmparray[i])
                return true; 
            }
        });
      })
        setStatusShow(false);
      setToastText("Status Updated");
      toast.success(toastText,{hideProgressBar:true})
      setLoader(false);
    }catch(error) {
        console.log(error, 'error');
        setLoader(false);
        setToastText("Something went wrong");
        toast.error(toastText,{hideProgressBar:true})
    }
  }

  const addReturnPolicy = async () => {
    setLoader(true);
    try {
        const token = Cookies.get('s_l');  
        const newToken = decrpt(token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
        const response = await axios.post(`/admin/return/policy/add/update` , addPolicy )
        .then((response) => {
            console.log(response.data);
            returnPolicyList.unshift(addPolicy);
            setLoader(false);
            setAddreturnshow(false);
        })
    }catch(error) {
        console.log(error, 'error');
        setErrors(error.response.data.errors);
        setLoader(false);
        setAddreturnshow(true);
    }
  }
  const editReturnPolicy = async () => {
    setLoader(true);
    try {
        const token = Cookies.get('s_l');  
        const newToken = decrpt(token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
        const response = await axios.post(`/admin/return/policy/add/update` , editPolicy )
        .then((response) => {
            if(response.data.success){
                console.log(response.data);
                let tmparray = returnPolicyList;
                let obj = tmparray.find((o, i) => {
                if (o.id === editPolicy.id) {
                  tmparray[i] = {...returnPolicyList[i] ,  ...editPolicy};
                  console.log('true',tmparray[i])
                    return true; 
                }
            });
            }
            setLoader(false);
            setEditReturnshow(false);
        })
    }catch(error) {
        console.log(error, 'error');
        setLoader(false);
        setEditReturnshow(true);
        setErrors(error.response.data.errors);
    }
  }



  useEffect (() => {
    window.scrollTo(0, 0);
    fetchReturnList();
  }, []); 

  return (
    <div className="main-content">
      {loader && loader ?
      <Loader /> :
    <>
      <ToastContainer position="top-center"  autoClose={3000}  hideProgressBar={false}  newestOnTop={false}  closeOnClick  rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'colored'}/>
      {/* New User Registration */}
      <div className="section">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <h5><BiCategory className="me-1" />Return Policy Master List</h5>
            </div>
            <div className="card p-2 mt-3">
              <div className="card-title">
                <h5>You have  Added  nos Return Policy</h5>
                <Button  onClick={() => setAddreturnshow(true)} className="badge btn-xs pt-1">Add New Return Policy</Button>
              </div>
              <div>
                <table className='table tabe-bordered'>
                    <thead>
                        <tr>
                            <th>SL</th>
                            <th>Return Policy </th>
                            {/* <th>Type </th> */}
                            <th className="text-center">Status</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {returnPolicyList &&  returnPolicyList.map((item,index)=> {
                          return(
                          <tr key={index}>
                            <td>{index + 1}. </td>
                            <td>{item.return_policy}</td>
                            {/* <td>{item.type}</td> */}
                            <td className="text-center">
                              { item &&  item.status == 1 ?
                              <Badge bg="success">Enable</Badge>:
                              <Badge bg="danger">Disable</Badge>}
                            </td>
                            <td className="text-center">
                              <button type="button" onClick={()=> handleEdit(item)} className='btn btn-xs btn-primary text-white'>Edit</button> &nbsp;
                              {item && item.status == 1 ? 
                                <button onClick={()=> handleStatus(item)} className='btn btn-xs btn-danger text-white'>Make Disable</button>:
                                <button onClick={()=> handleStatus(item)} className='btn btn-xs btn-success text-white'>Make Enable</button>}                                  
                            </td>
                          </tr>
                          )
                        })}
                    </tbody>
                </table>
              </div>

              <Modal size="md" show={addreturnshow} onHide={handleClose}>
                      <Modal.Header className="py-1" closeButton>
                          <Modal.Title>Add Return Policy</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <Form>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Add Return Policy Name </Form.Label>
                                <Form.Control type="text" 
                                onChange={e=> setAddPolicy({...addPolicy ,return_policy:e.target.value })}
                                />
                                {errors && errors ? <p style={{color : 'red' , fontSize : '12px'}}>{errors.return_policy}</p> : ''}
                            </Form.Group>
                              {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Add Return Policy Type </Form.Label>
                                <Form.Control type="text"
                                onChange={e=> setAddPolicy({...addPolicy ,type:e.target.value })}
                                 />
                            </Form.Group> */}
                          </Form>
                      </Modal.Body>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button onClick={()=>handleClose()} className="btn btn-light btn-sm">
                              Close
                          </button>
                          <button type="button" onClick={()=>addReturnPolicy()} className="btn btn-primary btn-sm">Save Changes
                          </button>
                      </Modal.Footer>
                </Modal> 
                
              <Modal size="md" show={editReturnshow} onHide={handleCloseEdit}>
                      <Modal.Header className="py-1" closeButton>
                          <Modal.Title>Edit Return Policy</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <Form>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Edit Return Policy Name </Form.Label>
                                <Form.Control type="text" 
                                defaultValue={editPolicy && editPolicy.return_policy}
                                onChange={e=> setEditPolicy({...editPolicy ,return_policy:e.target.value })}
                                />
                                {errors && errors ? <p style={{color : 'red' , fontSize : '12px'}}>{errors.return_policy}</p> : ''}
                            </Form.Group>
                              {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Edit Return Policy Type </Form.Label>
                                <Form.Control type="text"
                                defaultValue={editPolicy && editPolicy.type}
                                onChange={e=> setEditPolicy({...editPolicy ,type:e.target.value })}
                                 />
                            </Form.Group> */}
                          </Form>
                      </Modal.Body>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button onClick={()=>handleCloseEdit()} className="btn btn-light btn-sm">
                              Close
                          </button>
                          <button type="button" onClick={()=> editReturnPolicy()}  className="btn btn-primary btn-sm">Save Changes
                          </button>
                      </Modal.Footer>
                </Modal> 



              <Modal size="md" show={statusShow}  centered>
                  <Modal.Header className="py-1">
                      <Modal.Title className="h6">Do you want to  {
                          newStatus && newStatus.status == 1 ?  'Disable' : 'Enable'
                        } This Return Policy ? <br /><span className="text-danger text-capitalize fw-bold">{newStatus && newStatus.name}</span></Modal.Title>
                  </Modal.Header>
                  <Modal.Footer className="p-1 justify-content-start">
                      <button  onClick={()=> handleStatusClose()} className="btn btn-light btn-sm">
                          Close
                      </button> 
                      <button  className="btn btn-primary btn-sm" onClick={() => returnStatus()} >
                        {newStatus && newStatus.status == 1 ?  'Make Disable' : 'Make Enable'}
                      </button>
                  </Modal.Footer>
              </Modal> 
            </div>
          </div>
        </div>
      </div>
    </>
    }
    </div>
  );
}

export default Return;
