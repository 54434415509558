import React, { useEffect, useState } from "react";
import { Outlet, Link, useNavigate  } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiProductHuntLine, RiListSettingsFill } from "react-icons/ri";
import Datatable from '../../component/datatable';
import DatatableOrder from "../../component/datatableorder";
import DatatableUser from "../../component/datatableuser";
import { Chart } from "react-google-charts";
import Loader from '../../component/loader';
import { decrpt } from "../../component/encryption";
import axios from "axios";
import Cookies from "js-cookie";


const options = {
  // title: "NEW ORDER",
  hAxis: { title: "Year", titleTextStyle: { color: "#333" } },
  vAxis: { minValue: 0 },
  chartArea: { width: "60%", height: "75%" },
};

function Dashboard() {  

  const navigate = useNavigate();
  const [sidebarClose,setSidebarClose] = useState(false);
  const [toastText, setToastText] = useState();
  const [loader,setLoader] = useState(false);
  const [chart_data , setChart_data] = useState();
  const [counts , setCounts ] = useState();
  const [data_list, setData_list  ] = useState();

// console.log(chart_data  ,'chart_data' );
  const notify = () => toast("Welcome Admin, You are logged in");

const fetchDashboard = async () => {
  try {
        const token = Cookies.get('s_l');  
        const newToken = decrpt(token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
        const response = await axios.get(`/admin/dashboard`)
        .then((response) => {
          if(response.data.success){
            // setDashboardList(response.data);
            setCounts(response.data.counts);
            setChart_data(response.data.chart_data.order_chart);
            setData_list(response.data.data_list);
            console.log(response.data , 'fetch dahboard');
          }else {
            navigate()
            console.log('alete', response.data)
          }
        })
      }catch(error){
        console.log('alete', error.response.data)
        navigate('../')
        setToastText("You are Not Logged In ");
        toast.success(toastText,{hideProgressBar:true})
        // navigate('../login/index.js')
  }
}
let newSeller = data_list && data_list.new_sellers;
let orderList = data_list && data_list.order_list;
let userList = data_list && data_list.user_list;
let chartList =  chart_data  && { ...chart_data };

// let item = '';
const data = [
  // ["Month", "cancelled" , "completed"],
  // ["Jan-Mar", 2300, 200],
  // ["Aprl-Jun", 500, 521],
  // ["Jul-Sep", 45, 455],
  // ["Oct-Dec", 837, 4545],
  // [chart_data.map(x => x.month , x.completed, x.cancelled)]
];
// const data = ["Month", "cancelled" , "completed"] , {chart_data.map(x => x.month , x.completed, x.cancelled)} ;
// console.log(chart_data , 'chart_data');
console.log(chartList,' chartList  2');


  useEffect (() => {
    window.scrollTo(0, 0);
    setLoader(true);
    fetchDashboard();
    setTimeout(() => {      
      setLoader(false);
    }, 2500);
  }, []);
  return (
    <div className="main-content">
      {loader &&
        <Loader />
      }
      <ToastContainer position="top-center"
        autoClose={100000}  hideProgressBar={false}  newestOnTop={false}    rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'colored'} />
      {/* Count */}
      <div className="count-section d-flex justify-content-between">
        {/* count 1 */}
        <div className="count-block d-flex align-items-center justify-content-between">
          <div  className="count-figure"><h3>{ counts && counts.total_products}</h3></div>
          <div className="count-content">
            <h5><RiProductHuntLine className="menu-icon" /> Total Product</h5>
            <Link to="../product" className="link-btn">Product List</Link>
          </div>
        </div>
        {/* count 2 */}
        <div className="count-block d-flex align-items-center justify-content-between">
          <div  className="count-figure"><h3>{counts && counts.total_new_orders}</h3></div>
          <div className="count-content">
            <h5><RiProductHuntLine className="menu-icon" /> Total Order</h5>
            <Link to="../order" className="link-btn">see order</Link>
          </div>
        </div>
        {/* count 3 */}
        <div className="count-block d-flex align-items-center justify-content-between">
          <div  className="count-figure"><h3>{counts && counts.seller_count}</h3></div>
          <div className="count-content">
            <h5><RiProductHuntLine className="menu-icon" /> Total Seller</h5>
            <Link to="../seller" className="link-btn">See sellers</Link>
          </div>
        </div>
        {/* count 4 */}
        <div className="count-block d-flex align-items-center justify-content-between">
          <div  className="count-figure"><h3>{counts && counts.user_count}</h3></div>
          <div className="count-content">
            <h5><RiProductHuntLine className="menu-icon" /> Total User</h5>
            <Link to="../user" className="link-btn">See user</Link>
          </div>
        </div>
      </div>

      {/* New Order */}
      <div className="section-main">
        {/* New User Registration */}
        <div className="section">
          <div className="section-title mb-3">
            <h5><RiListSettingsFill className="me-1" />All New User</h5>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card p-2">
                <div className="card-title d-flex align-items-center justify-content-between">
                  <h5>New User Registration</h5>
                  <Link to="../user" className="btn btn-xs btn-warning">View All User</Link>
                </div>
                <DatatableUser 
                  userList={userList}
                />
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="card">
                <Chart
                  chartType="AreaChart"
                  width="100%"
                  height="300px"
                  //   data = {[
                  //   ['month' , 'completed' , 'cancelled'],
                  //   chartList.map((item) => [item.month, item.completed , item.cancelled] )
                  // ]}
                  options={options}
                />
              </div>
            </div> */}
          </div>
        </div>

        {/* New Seller Registration */}
        <div className="section">
          <div className="section-title mb-3">
            <h5><RiListSettingsFill className="me-1" />All New Seller</h5>
          </div>
          <div className="row">
            {/* <div className="col-md-6">
              <div className="card">
                <Chart
                  chartType="AreaChart"
                  width="100%"
                  height="300px"
                  //   data = {[
                  //   ['month' , 'completed' , 'cancelled'],
                  //   chartList.map((item) => [item.month, item.completed , item.cancelled] )
                  // ]}
                  options={options}
                />
              </div>
            </div> */}
            <div className="col-md-12">
              <div className="card p-2">
                <div className="card-title d-flex align-items-center justify-content-between">
                  <h5>New Seller Registration</h5>
                  <Link to="../seller" className="btn btn-xs btn-warning">View All Seller</Link>
                </div>
                <Datatable 
                  newSeller={newSeller}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="section-title mb-3">
            <h5><RiListSettingsFill className="me-1" />All Orders</h5>
          </div>
          <div className="row ">
            {/* <div className="col-md-6">
              <div className="card">
                <Chart
                  chartType="AreaChart"
                  width="100%"
                  height="300px"
                  // data={chart_data && chart_data.map((item) => [item.month, item.completed , item.cancelled] )}
                  // data={data}
                  // data = {[
                  //   ['month' , 'completed' , 'cancelled'],
                  //   chartList && chartList.map((item) => [item.month, item.completed , item.cancelled] 
                  //   , console.log('ck' , 'checki'))
                  // ]}
                  options={options}
                />
              </div>
            </div> */}
            <div className="col-md-12">
              <div className="card p-2">
                <div className="card-title d-flex align-items-center justify-content-between">
                  <h5>New Order</h5>
                  <Link to="../order" className="btn btn-xs btn-warning">View All Order</Link>
                </div>
                <DatatableOrder
                  orderList={orderList}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  );
}

export default Dashboard;
