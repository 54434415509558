import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Loader from '../../component/loader';
import { BiCategory, BiFilterAlt } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import Cookies from 'js-cookie'
import { decrpt } from '../../component/encryption';
import Pagination from "react-js-pagination";
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LastCategory() {  
  const [ loader,setLoader ] = useState(false);
  const [ show,setShow ] = useState(false);
  const [disabled , setDisabled ] = useState(true);
  const [ dshow,setDShow ] = useState(false);
  const [ errors , setErrors ] = useState();
  const [ Eshow,setEShow ] = useState(false);
  const [ toastText,setToastText ] = useState('');
  const [ paginate , setPaginate] = useState();
  const [statusShow , setStatusShow] = useState();
  const [ category, setCategory ] = useState();
  const [ subCategory, setSubCategory ] = useState("");
  const [ lastCategory, setLastCategory ] = useState();
  const [newUpdateStatus , setNewUpdateStatus] = useState();
 
  const [ lastCategoryPreview , setLastCategoryPreview] = useState({
    image : '',
    banner_image : ''
  });
  const [ editLastCategory, setEditLastCategory ] = useState();
  console.log(errors , 'errors');
  const [ newLastCategory, setNewLastCategory ] = useState({
    id:'',
    name:'',
    category_id:'',
    category_name:'',
    level:'3',
    image:'',
    banner_image:'',
    sub_category_id : '',
    sub_category_name : '',
    category_name : ''
  });
  const [lastCategorySelected , setLastCategorySelected] = useState({
    id:'',
    name:'',
    category_id:'',
    level:'3',
    image:'',
    banner_image:'',
  })
  // console.log(editLastCategory , 'editLastCategory');
  const handleOpen = () => {
    setShow(true);
    setDisabled(true);
    setLastCategoryPreview({
      image : '',
      banner_image : ''
    })
  };
  const handleClose = () => {
    setShow(false);
  };
  const deleteCall = () => {
    setDShow(true);
  };
  const handleCloseDelete = () => {
    setDShow(false);
  };
  const editCall = async (item) => {
    setNewLastCategory(item);
  await  setEditLastCategory(item);
    setLastCategoryPreview(item);
    setLastCategorySelected(item);
    await console.log(editLastCategory , 'editSubCategory');
    await  setEShow(true);
    
  };
  const handleCloseEdit = () => {
    setEShow(false);
  };
  const textHandler = (e) => {
    setNewLastCategory({...newLastCategory, name:e.target.value})
   console.log(newLastCategory.name , 'newLastCategory');
  };
  

  const lastCategoryHandler = (e) => {
    console.log(e);
    setNewLastCategory({...newLastCategory, sub_category_id:e.target.value})
   console.log(newLastCategory , 'newLastCategory');
  };
  function handlePreview(e) {
    setLastCategoryPreview({...lastCategoryPreview , image:URL.createObjectURL(e.target.files[0])});
    setNewLastCategory({...newLastCategory, image:e.target.files[0]});
    console.log(newLastCategory , 'categoryPreview');
    }
  function handleBannerPreview(e) {
    setLastCategoryPreview({...lastCategoryPreview , banner_image:URL.createObjectURL(e.target.files[0])});
    setNewLastCategory({...newLastCategory, banner_image:e.target.files[0]});
    console.log(newLastCategory , 'categoryPreview');
    }
const handleStatus = (item)=> {
  setStatusShow(true);
  setNewUpdateStatus(item);
}
const handleStatusClose = () =>{
  setStatusShow(false);
}

  // Get Category List
  const getCategoryList = async() => {
    try {
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      // console.log(newToken);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      const response = await axios.post(`admin/category/list`, {type:1})
      // console.log(response.data);
      setCategory(response.data.data.list)
    } catch (error) {
      console.error(error);
    }
  }
  const handleCategory = (category_id) => {
    setNewLastCategory({
      ...newLastCategory,
      category_id : category_id,
    })
    getSubCategoryList(category_id);
    // console.log(category_id , 'handle sub category fetch category');
  }

  const handleSubCategory = (category_id) => {
    setNewLastCategory({
      ...newLastCategory,
      category_id : category_id,
    })
// getSubCategoryList(sub_category_id);
    // console.log(newLastCategory , 'handle sub category fetch category');
    setDisabled(false);
  }

  // for editing only
  const categoryEditHandle = (category_id) => {
    setEditLastCategory({
      ...editLastCategory,
      category_id : category_id, 
    })
    getSubCategoryList(category_id)
  }
  const handleSubCategoryEdit = (category_id) => {
    setEditLastCategory({
      ...editLastCategory,
      category_id : category_id, 
    })
  }
  // Get Sub Category List
  const getSubCategoryList = async(category_id ) => {
    const data = {
      type : 2,
      category_id : category_id
    }
    // console.log(data , 'category_id category_id');
    try {
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      // console.log(newToken);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      const response = await axios.post(`admin/category/dropdown/list`, data)
      
      // console.log(response.data.data , ' fetch second cat with respct first');
      setSubCategory(response.data.data);
      // setNewLastCategory({
      //   ...newLastCategory,
      //   sub_category_id : subCategory.id
      // })

      // console.log(newLastCategory , 'getSubCategoryList check');
    } catch (error) {
      console.error(error);
    }
  }
  // Get Last Category List
  const getLastCategoryList = async() => {
    try {
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      const response = await axios.post(`admin/category/list`, {type:3})
      console.log('Last Level',response.data);
      setLastCategory(response.data.data.list)
      setPaginate(response.data.data.pagination);
    } catch (error) {
      console.error(error);
    }
  }

  // Add Category
  const addLastCategory = async() => {
    try {
      setLoader(true);
      const formData = new FormData();
      formData.append("level", 3);
      formData.append("name", newLastCategory.name);
      formData.append("category_id", newLastCategory.category_id); 
      // formData.append("sub_category_id", newLastCategory.sub_category_id);
      formData.append("image", newLastCategory.image);
      formData.append("banner_image", newLastCategory.banner_image);
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      const response = await axios.post(`admin/category/save`, formData)
      .then((response)=>{
        if(response.data.success){
          let lastResponse = response.data.data;
          console.log(lastResponse , 'lastResponse');
          setLoader(true);
          handleClose();
          let tmparray = lastCategory;
          let obj = tmparray.find((o, i) => {
              if (o.id) {
                tmparray[i] = {...lastCategory[i] , ...lastResponse };
                console.log('true',tmparray[i])
                  return true; 
              }
          setLoader(false);
          });
          console.log(lastCategory , 'lastCategory check');
          getLastCategoryList();
          setToastText("Category Added")
          toast.success(toastText,{hideProgressBar:true})
          setLoader(false);
        }
      }).catch((err)=>{
        console.error('Error',err);
        setErrors(err.response.data.errors);
        setToastText("Something went wrong")
        toast.success(toastText,{hideProgressBar:true})
      })
    } catch (error) {
      console.error(error);
      setErrors('Last Category Field Required');
      setToastText("Something went wrong");
    }
  }
// last category status
  
const categoryStatus = async() => {
  setLoader(true);
  try {
    let newstatus = '';
    if (newUpdateStatus.status == 1) {
      newstatus = 2;
    } else {
      newstatus = 1;
    }
    const data = {
      id: newUpdateStatus.id,
      status  : newstatus
    }
    console.log(data , 'data of api getc')
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
    const response = await axios.post(`admin/category/status` , data)
    .then((response) => {
      let tmparray = lastCategory;
      let obj = tmparray.find((o, i) => {
          if (o.id === newUpdateStatus.id) {
            tmparray[i] = {...lastCategory[i] ,   status : newstatus  };
            console.log('true',tmparray[i])
              return true; 
          }
      });
    })
    setStatusShow(false);
    setToastText("Status Updated");
    toast.success(toastText,{hideProgressBar:true})
  setLoader(false);
  }catch(error) {
      console.log(error, 'error');
      setToastText("Something went wrong")
      toast.error(toastText,{hideProgressBar:true})
      setLoader(false);
  }
}
// last category status
// update last category

const updateLastCategory = async() => {
  setLoader(true);
  const formData = new FormData();
  formData.append("level", newLastCategory.level);
  formData.append("id", editLastCategory.id);
  formData.append("name", editLastCategory.name);
  formData.append("category_id", editLastCategory.category_id);
  // formData.append("sub_category_id", newLastCategory.sub_category_id);
  formData.append("image", newLastCategory.image);
  formData.append("banner_image", newLastCategory.banner_image);
  // for (const value of formData.values()) {
  //   console.log(value);
  // }
  try {
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
    const response = await axios.post(`admin/category/save` , formData )
    .then((response) => {
        let newvar = response.data.data;
        // console.log(newvar , 'newvar');
        // lastCategory.unshift(resp)
        // let tmparray = lastCategory;
        // let obj = tmparray.find((o, i) => {
        //     if (o.id === editLastCategory.id) {
        //       tmparray[i] = {...lastCategory[i] , ...newvar };
        //       console.log('true',tmparray[i])
        //         return true; 
        //     }
        // });
        getLastCategoryList();
        setToastText("Category Name Updated");
        toast.success(toastText,{hideProgressBar:true})
        setLoader(false);
        setEShow(false);
    })
  }catch(error) {
      console.log(error);
      setLoader(false);
      setEShow(true);
      setErrors(error.response.data.errors);
      setToastText("Something went wrong")
      toast.success(toastText,{hideProgressBar:true})
  }
}
// pagination
const handlePageChange = async (pageNumber) => {
  setLoader(true);
  console.log(`active page is ${pageNumber}`);
  const token = Cookies.get('s_l');  
  const newToken = decrpt(token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
  const response = await axios.post(`admin/category/list?page=${pageNumber}`, {type: 3} )
  .then((response) => {
    setLastCategory(response.data.data.list);
    setLoader(false);
  }).catch((err) => {
    console.log('seller list error' , err.response)
    setLoader(false);
  })
}
// handle category

// pagination
// update last category

  useEffect (() => {
    window.scrollTo(0, 0);
    setLoader(true);
    //  getSubCategoryList();      
    setTimeout(async() => {
      await getCategoryList();      
      await getLastCategoryList();      
      setLoader(false);
    }, 500);
  }, []); 

  return (
    <div className="main-content">
      {loader ? 
        <Loader />
        :
        <>
      <ToastContainer position="top-center"  autoClose={3000}  hideProgressBar={false}  newestOnTop={false}  closeOnClick  rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'colored'}/>      

          {/* New User Registration */}
          <div className="section">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                  <h5><BiCategory className="me-1" />All 3rd Level Category</h5>
                </div>
                <div className="card p-2 mt-3">
                  <div className="card-title">      
                    <h5>You have {lastCategory && lastCategory.length > 0 ? lastCategory.length : 'No'} 3rd Level Category</h5>            
                    <Button onClick={()=> handleOpen()} className="badge btn-xs pt-1">Add New 3rd Level Category</Button>
                  </div>
                  <div>
                    <table className='table tabe-bordered'>
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Image</th>
                                <th>Banner Image</th>
                                <th>3rd Level Category</th>
                                <th>2nd Level Category</th>
                                <th>1st Level Category</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                          {lastCategory && lastCategory.length > 0 ? lastCategory.map((item,index)=> {
                          return(
                            <tr>
                              <td>{index + 1}. </td>
                              <td> <img src={item.image} style={{ width: 100,height: 100 }} /> </td>
                              <td> <img src={item.banner_image} style={{ width: 100,height: 100 }} /> </td>
                              <td>{item.name}</td>
                              <td>{item.sub_category_name}</td>
                              <td>{item.category_name}</td>
                              <td className="text-center">
                              {item && item.status == 1 ?
                                <Badge bg="success">Enable</Badge>:
                                <Badge bg="danger">Disable</Badge>}
                              </td>
                              <td className="text-center">
                                <button onClick={()=>editCall(item)} className='btn btn-xs btn-primary text-white'>Edit</button> &nbsp;
                                {/* <button onClick={()=>deleteCall()} className='btn btn-xs btn-danger text-white'>Delete</button> &nbsp; */}
                                {item && item.status == 1 ?
                                  <button type="button" onClick={()=>handleStatus(item)} className='btn btn-xs btn-danger text-white'>Make Disable</button>:
                                  <button type="button" onClick={()=>handleStatus(item)} className='btn btn-xs btn-success text-white'>Make Enable</button>}  
                              </td>
                            </tr>
                            )
                          }):
                            <tr>
                              <td colSpan={6} className="text-center fst-italic text-danger">no category added</td>
                            </tr>
                          }
                        </tbody>
                    </table>
                    { lastCategory && lastCategory  ?
                    <div className="col-md-12">
                    <Pagination
                    totalItemsCount={ paginate.total_data}
                    onChange={handlePageChange}
                    activePage={  paginate.current_page}
                    itemsCountPerPage={paginate.per_page}
                    />
                </div>
                : <></>
                }
                  </div>

                  {/* Add Cateory */}
                  <Modal size="md" show={show} onHide={handleClose}>
                      <Modal.Header className="py-1" closeButton>
                          <Modal.Title>Add 3rd Level Category</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <Form>
                              <Form.Group>
                                <Form.Label>Select First Category</Form.Label>
                                <Form.Select aria-label="Default select example" 
                                // onChange={category => setNewLastCategory({...newLastCategory , category_id:category.target.value })}
                                  onChange = {category => handleCategory(category.target.value)}
                                >
                                  {category && category.length > 0 ?
                                  <>
                                      <option selected disabled>----select from below----</option>
                                    {category.map((item,index) => {
                                      return (
                                        <>
                                        <option key={index} value={item.id}>{item.name}</option>
                                        </>
                                      ) 
                                    })}
                                  </>:
                                  <>
                                    <option disabled>Select Category First</option>
                                  </>
                                  }
                                </Form.Select>
                              </Form.Group>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Select Sub Category</Form.Label>
                                <Form.Select aria-label="Default select example"
                                //  onChange={e => subCategoryHandler(e)}
                                 onChange = {sub_category => handleSubCategory(sub_category.target.value)}
                                 >
                                  {subCategory && subCategory.length > 0 ?
                                  <>
                                      <option selected disabled>----select from below</option>
                                    {subCategory.map((item,index) => {
                                      return <option key={index}  value={item.id}>{item.name}</option>
                                    })}
                                  </>:
                                  <>
                                    <option disabled>Select Category First</option>
                                  </>
                                  }
                                </Form.Select>
                              </Form.Group>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                  <Form.Label>3rd Level Category Name</Form.Label>
                                  <Form.Control size="sm" type="text" placeholder="Enter Category Name" onChange={e =>  textHandler(e)} disabled={disabled} />
                                  {errors && errors ? <p style={{color : 'red' , fontSize : '12px'}}>{errors.name} </p> : ''}
                              </Form.Group>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>3rd Level Category Image </Form.Label>
                                <Form.Control type="file" onChange={handlePreview} />
                              </Form.Group>
                                <img src={lastCategoryPreview && lastCategoryPreview.image !== '' ? lastCategoryPreview.image: '/folder.png'} style={{ width: 100,height: 100 }} />
                     
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>3rd Level Category banner Image </Form.Label>
                                <Form.Control type="file" onChange={handleBannerPreview} />
                              </Form.Group>
                                <img src={lastCategoryPreview && lastCategoryPreview.banner_image !== '' ? lastCategoryPreview.banner_image: '/folder.png'} style={{ width: 100,height: 100 }} />
                             
                          </Form>
                      </Modal.Body>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button onClick={()=>handleClose()} className="btn btn-light btn-sm">
                              Close
                          </button>
                          <button onClick={()=>addLastCategory()} className="btn btn-primary btn-sm">
                              Save Changes
                          </button>
                      </Modal.Footer>
                  </Modal> 

                  {/* Edit Cateory */}
                  <Modal size="md" show={Eshow}>
                      <Modal.Header>
                          <Modal.Title>Edit 3rd Level Category</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <Form>

                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Sub Category Image </Form.Label>
                          <Form.Control type="file" onChange={handlePreview} />
                          {errors && errors ? <p style={{fontSize : 12 , color : 'red'}}> {errors.image} </p> : '' }
                            </Form.Group>
                            <img src={lastCategoryPreview && lastCategoryPreview.image !== '' ? lastCategoryPreview.image: lastCategorySelected.image} style={{ width: 100,height: 100 }} />
                        {/*  */}
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Sub Category banner Image </Form.Label>
                            <Form.Control type="file" onChange={handleBannerPreview} />
                          {errors && errors ? <p style={{fontSize : 12 , color : 'red'}}> {errors.banner_image} </p> : '' }
                          </Form.Group>
                            <img src={lastCategoryPreview && lastCategoryPreview.banner_image !== '' ? lastCategoryPreview.banner_image: lastCategorySelected.banner_image} style={{ width: 100,height: 100 }} />
                            <Form.Group>
                                <Form.Label>Select First Category</Form.Label>
                                <Form.Select aria-label="Default select example" 
                                // onChange={category => setNewLastCategory({...newLastCategory , category_id:category.target.value })}
                                  onChange = {category => categoryEditHandle(category.target.value)}
                                  value={editLastCategory && editLastCategory.category_id} 

                                >
                                  {category && category.length > 0 ?
                                  <>
                                      <option  disabled>----select from below----</option>
                                    {category.map((item,index) => {
                                      return (
                                        <>
                                        <option key={index} value={item.id}>{item.name}</option>
                                        </>
                                      ) 
                                    })}
                                  </>:
                                  <>
                                    <option disabled>Select Category First</option>
                                  </>
                                  }
                                </Form.Select>
                          {errors && errors.category_id ? <p style={{fontSize : 12 , color : 'red'}}> Category is required </p> : '' }
                              </Form.Group>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Select Sub Category</Form.Label>
                                <Form.Select aria-label="Default select example"
                                //  onChange={e => subCategoryHandler(e)}
                                 onChange = {sub_category => handleSubCategoryEdit(sub_category.target.value)}
                                 value={editLastCategory && editLastCategory.sub_category_id} 
                                 >
                                  {subCategory && subCategory.length > 0 ?
                                  <>
                                      <option  disabled>----select from below</option>
                                    {subCategory.map((item,index) => {
                                      return <option key={index}  value={item.id}>{item.name}</option>
                                    })}
                                  </>:
                                  <>
                                    <option disabled>Select Category First</option>
                                  </>
                                  }
                                </Form.Select>
                              </Form.Group>
                            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Select Sub Category</Form.Label>
                                <Form.Select aria-label="Default select example" onChange={e => lastCategoryHandler(e)}>
                                    {subCategory&& subCategory.map((item,index) => {
                                      return <option key={index} value={item.id}>{item.name}</option>
                                    })}
                                </Form.Select>
                            </Form.Group> */}
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                  <Form.Label>3rd Level Category Name </Form.Label>
                                  <Form.Control size="sm" type="text" placeholder="Enter 3rd Level Category Name"
                                   defaultValue={editLastCategory && editLastCategory.name} 
                                  onChange={ e => setEditLastCategory({...editLastCategory , name:e.target.value})}
                                  />
                                {errors && errors.name ? <p style={{fontSize : 12 , color : 'red'}}> {errors.name} </p> : '' }
                              </Form.Group>
                          </Form>
                      </Modal.Body>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button onClick={()=>handleCloseEdit()} className="btn btn-light btn-sm">
                              Close
                          </button>
                          <button onClick={()=>updateLastCategory()} className="btn btn-primary btn-sm">
                              Save Changes
                          </button>
                      </Modal.Footer>
                  </Modal> 

                  <Modal size="md" show={statusShow}  centered>
                  <Modal.Header className="py-1">
                      <Modal.Title className="h6">Do you want to  {
                          newUpdateStatus && newUpdateStatus.status == 1 ?  'Disable' : 'Enable'
                        } Sub category ? <br /><span className="text-danger text-capitalize fw-bold">{newUpdateStatus && newUpdateStatus.name}</span></Modal.Title>
                  </Modal.Header>
                  <Modal.Footer className="p-1 justify-content-start">
                      <button  onClick={()=> handleStatusClose()} className="btn btn-light btn-sm">
                          Close
                      </button>
                      <button  className="btn btn-primary btn-sm" onClick={() => categoryStatus()} >
                        {newUpdateStatus && newUpdateStatus.status == 1 ?  'Make Disable' : 'Make Enable'}
                      </button>
                  </Modal.Footer>
              </Modal> 

                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default LastCategory;
