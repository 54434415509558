import React, { useEffect, useState } from "react";
import {useNavigate } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import Loader from '../../component/loader';
import Pagination from "react-js-pagination";
import { AiOutlinePullRequest, AiOutlineCheckCircle } from "react-icons/ai";
import axios from 'axios';
import { Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import {decrpt} from "../../component/encryption";
import moment from 'moment';

function SellerList() {  
  const [loader,setLoader] = useState(false)
  const [sellerList, setSellerList] = useState();
  const [sellerShow , setSellerShow] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [statusShow, setStatusShow] = useState();
  const [status, setStatus] = useState();
  const [sellerStatus , setSellerStatus] = useState({
    status : ''
  });
  console.log(sellerStatus , 'sellerStatus');
  const [paginate , setPaginate] = useState();
  const navigate = useNavigate();

  const fetchSellerList = async(registration_status) => {
     setLoader(true);
     const data = {
      registration_status: registration_status,
    }
    console.log(data, 'chekc data sjdsajd')
    let token = decrpt(Cookies.get('s_l'));
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axios.post(`/admin/seller/list` , data)
    .then((response) => {
      console.log('sellers list data' , response.data.data)
      setPaginate(response.data.data.pagination);
        setSellerList(response.data.data.list);
      setLoader(false);
      console.log(response.data , 'seller list');
    }).catch((err) => {
      console.log('seller list error' , err)
      setLoader(false);
    })
  }
// pagination
  const handlePageChange = async (pageNumber) => {
    setLoader(true);
    // console.log(`active page is ${pageNumber}`);
    let token = decrpt(Cookies.get('s_l'));
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axios.post(`/admin/seller/list?page=${pageNumber +1}`)
    .then((response) => {
      if(response.data.data.list){
        setSellerList(response.data.data.list);
      setLoader(false);
      }
      
    }).catch((err) => {
      console.log('seller list error' , err)
      setLoader(false);
    })
  }
  const statusTypeHandler = (registration_status) => {
    fetchSellerList(registration_status);
    setSelectedStatus(registration_status);
  }

  const handleStatus = (item) => {
    setStatusShow(true);
    setStatus(item);
    console.log(status , 'dsdsjdk item');
  };
  const handleStatusClose = () => {
    setStatusShow(false);
  };

//  seller approve
  const sellerApprove = async (id) =>{
    setLoader(true);
    const data = {id: id}
    try {
    let token = decrpt(Cookies.get('s_l'));
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axios.post(`/admin/seller/approve`,data)
    // console.log(response.data , 'seller approve data respone');
    if (response.data) {
      let newData = response.data.data;
        console.log('sellerApprove status', response.data.data);
        let tmparray = sellerList;
        let obj = tmparray.find((o, i) => {
        if (o.id === newData.id ) {
            tmparray[i] = {...sellerList[i] ,   ...newData  };
            return true; 
        }
    });


        setLoader(false);
    } 
    } catch (error) {
        console.log('Error',error);
        setLoader(false);
    }
  }
//  seller approve

// seller enable api
const sellerEnable = async () =>{
  setLoader(true);
  let newstatus = '';
  if (status.status == 1) {
    newstatus = 2;
  } else {
    newstatus = 1;
  }
  const data = {
    id: status.id,
    status : newstatus
  }
  console.log(data,'data');
  try {
  let token = decrpt(Cookies.get('s_l'));
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  const response = await axios.post(`/admin/seller/status/update`,data)
  .then((response) => {
      let tmparray = sellerList;
    let obj = tmparray.find((o, i) => {
        if (o.id === status.id) {
            tmparray[i] = {...sellerList[i] ,   status : newstatus  };
            return true; 
        }
    });
  })
    setStatusShow(false);
    setLoader(false);
  } catch (error) {
      console.log('Error',error);
      setLoader(false);
  }
}

// seller enable api

// filtrer api fecth
const sellerIdSend =(item)=> {
  navigate('./single' , {state : {item}});
}

useEffect(()=> {
  window.scrollTo(0, 0);
  fetchSellerList();
  handlePageChange(); 
},[]);

// useEffect(() => {
//   setSellerStatus({
//     ...sellerStatus,
//           id    : sellerList  ? sellerList.id : '',
//         status : sellerList ? sellerList.status : ''
//   })
// },[sellerList]);



  return (
    <div className="main-content">
      {loader ? 
        <Loader />
        :
        <>
          {/* New User Registration */}
          <div className="section sellerList">
            <div className="row">
              <div className="col-md-12">
              <div className="count-section d-flex justify-content-left">
                {/* count all */}
                {/* <button className="product-page-block d-flex align-items-center" onClick={() => fetchSellerList()} >
                    <div className="product-page-inner-block">
                        <h5 style={selectedStatus && selectedStatus === true  ? styles.activeBtnTxt : null}><AiOutlinePullRequest /> All Seller List</h5>
                    </div>
                </button> */}
                {/* count 1 */}
                <button className="product-page-block d-flex align-items-center me-3" onClick={() => statusTypeHandler(6)} style={selectedStatus == 6 ? styles.activeBtn : null}>
                    <div className="product-page-inner-block">
                        <h5 style={ selectedStatus && selectedStatus == 6 ? styles.activeBtnTxt : null}><AiOutlinePullRequest /> Approved</h5>
                    </div>
                </button>
                {/* count 2 */}
                <button className="product-page-block d-flex align-items-center" onClick={() => statusTypeHandler(5)} style={selectedStatus == 5 ? styles.activeBtn : null}>
                    <div className="product-page-inner-block">
                        <h5 style={selectedStatus && selectedStatus == 5 ? styles.activeBtnTxt : null}><AiOutlineCheckCircle /> Pending</h5>
                    </div>
                </button>
            </div>

            {/* New Order */}
                <div className="card p-2">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <h5>All Seller</h5>
                  </div>
                  <div>
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Seller Name</th>
                                <th className="text-center">Date of Registration</th>
                                <th className="text-center">Mobile Number</th>
                                <th className="text-center">Email</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                              { sellerList && sellerList.length > 0 ?
                              <>
                                {sellerList && sellerList.map((item , index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td className="text-center">  {moment(item.created_at).format("DD, MMMM , YYYY")} </td>
                                        <td className="text-center">{item.mobile}</td>
                                        <td className="text-center">{item.email}</td>
                                        <td className="text-center">
                                          { item.registration_status == 5 &&  item.status == 1 ?<Badge bg="danger"> Not Approved</Badge> :
                                           (item.registration_status == 6 && item.status == 2)  ? <Badge bg="danger"> Disabled</Badge> : <Badge bg="success"> Enabled</Badge> }
                                          </td>
                                        <td className="text-center">
                                          <button type="button" className='btn btn-xs btn-primary text-white me-1'  onClick={()=> sellerIdSend(item) } >View</button>
                                          {/* {item.status == 2 ?<button type="button" className='btn btn-xs btn-warning text-black me-1' >Make Enable</button> :<button type="button" className='btn btn-xs btn-secondary text-white me-1' >Make Disable</button>} */}
                                          { item.registration_status == 6 && item.status == 1 ?   
                                          <button type="button"   className='btn btn-xs btn-danger text-white me-1' onClick={() => handleStatus(item)  } >Make Disable</button> : 
                                           (item.registration_status == 5  ? '' : 
                                          <button type="button"   className='btn btn-xs btn-warning text-dark me-1' onClick={() => handleStatus(item)} >Make enable</button> 
                                          
                                          )}
                                          {item.registration_status == 5 && item.status == 1 ?<button type="button" className='btn btn-xs btn-success text-white' onClick={() => sellerApprove(item.id)} > Make Approve </button> 
                                          :''}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </> :
                                <tr><td>No data found</td></tr>
                              } 
                        </tbody>
                    </table>
                    {
                      sellerList && sellerList.length > 0 ?
                       <>
                    <div className="col-md-12">
                        <Pagination
                        totalItemsCount={ paginate && paginate.total_data}
                        onChange={handlePageChange}
                        activePage={  paginate && paginate.current_page}
                        itemsCountPerPage={paginate && paginate.per_page}
                        pageRangeDisplayed={10}
                        />
                    </div>  </>
                        : <></>
                    
                    }
                  </div>
                </div>
              </div>
            </div>
            <Modal size="md" show={statusShow} onHide={handleStatus} centered>
                  <Modal.Header className="py-1">
                      <Modal.Title className="h6">Do you want to  {
                          status && status.status == 1 ?  'Disable' : 'Enable'
                        } category ? <br /><span className="text-danger text-capitalize fw-bold">{status && status.name}</span></Modal.Title>
                  </Modal.Header>
                  <Modal.Footer className="p-1 justify-content-start">
                      <button  onClick={()=> handleStatusClose()} className="btn btn-light btn-sm">
                          Close
                      </button>
                      <button  className="btn btn-primary btn-sm" onClick={() => sellerEnable()} >
                        {status && status.status == 1 ?  'Make Disable' : 'Make Enable'}
                      </button>
                  </Modal.Footer>
              </Modal> 
          </div>
        </>
      }
    </div>

  );
}

export default SellerList;

const styles = {
  activeTable: { disply: 'table-row' },
  inActiveTable: { display: 'none' },
  activeBtn: { backgroundColor: '#ffc001ad' },
  activeBtnTxt: { color: '#000' },
}; 