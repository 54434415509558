import React from 'react';
import Badge from 'react-bootstrap/Badge';

function DatatableOrder(props) {
    // console.log(props , 'props new order')
    return (
        <div>
            <table className='table table-bordered'>
                <thead>
                    <tr>
                        <th>SL</th>
                        <th>Buyer's Name</th>
                        <th>Date of order</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.orderList && props.orderList.length > 0 ?
                        <>
                        {props.orderList.map((item,index) => {
                            return (
                                <tr key={index}>
                                <td>{item.id} </td>
                                <td>{item.user_name}</td>
                                <td>{item.order_date}</td>
                                {/* <td><a className='btn btn-xs btn-success text-white'>View</a></td> */}
                                <td >
                                { item.order_status == 1 ? <Badge bg="primary"> New</Badge> : 
                                (item.order_status == 2)  ? <Badge bg="success"> Accepted </Badge> : 
                                (item.order_status == 6)  ? <Badge bg="danger"> Canceled </Badge> : 
                                (item.order_status == 3) ? <Badge bg="warning"> Dispatch </Badge> : 
                                (item.order_status == 4) ? <Badge bg="secondary"> Out For Delivered</Badge> : 
                                <Badge bg="success"> Delivered</Badge> }
                                </td>
                            </tr>
                            );
                        })
                            
                        }
                        </> :
                        <tr><td>No data found</td></tr>
                    }
                    {/* <tr>
                        <td>2. </td>
                        <td>Hello Das</td>
                        <td>20/12/2022</td>
                        <td><a className='btn btn-xs btn-success text-white'>View</a></td>
                    </tr>
                    <tr>
                        <td>3. </td>
                        <td>Hello Das</td>
                        <td>20/12/2022</td>
                        <td><a className='btn btn-xs btn-success text-white'>View</a></td>
                    </tr>
                    <tr>
                        <td>4. </td>
                        <td>Hello Das</td>
                        <td>20/12/2022</td>
                        <td><a className='btn btn-xs btn-success text-white'>View</a></td>
                    </tr>
                    <tr>
                        <td>5. </td>
                        <td>Hello Das</td>
                        <td>20/12/2022</td>
                        <td><a className='btn btn-xs btn-success text-white'>View</a></td>
                    </tr> */}
                </tbody>
            </table>
        </div>
    );
}

export default DatatableOrder;