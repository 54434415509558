import React, { useEffect, useState } from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import UserList from '../user/list';
import UserSingle from '../user/single';

function User() {  
  useEffect (() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* <Routes>   
        <Route exact index path="/*" element={<UserList />} />
        <Route exact path="/single/*" element={<UserSingle />} />
      </Routes> */}
      <Outlet />
    </div>

  );
}

export default User;
