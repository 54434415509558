import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Loader from '../../component/loader';
import { BiCategory, BiFilterAlt } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import { decrpt } from "../../component/encryption";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Pagination from "react-js-pagination";


function Brand() {  
  const [loader,setLoader] = useState(false);
  const [show,setShow] = useState(false);
  const [Eshow,setEShow] = useState(false);
  const [paginate , setPaginate] = useState();
  const [statusShow , setStatusShow] = useState();
  const [newUpdateStatus , setNewUpdateStatus] = useState();
  const [errors, setErrors ] = useState(false);
  const [ toastText,setToastText ] = useState('');
  const [brand , setBrand] = useState();
  const [editBrand , setEditBrand] = useState();
  const [brandList , setBrandList] = useState();
  const [brandPreview , setBrandPreview] = useState({
    image : '',
    banner : ''
  });
  const [ newBrand, setNewBrand ] = useState({
    id:'',
    name:'',
    image:'',
    banner:'',
    status:'',
  });
  const [brandSelected , setBrandSelected ] = useState({
    id  :  '',
    image :  '',
    heading : '',
    banner:'',
    status:'',
  });
  const handleOpen = () => {
    setShow(true);
    setBrandPreview({
      image : '',
      banner : ''
    })
    setErrors(false)
  };

  const handleClose = () => {
    setShow(false);
  };

  const editCall = (item) => {
    setEditBrand(item);
    setBrandSelected(item);
    setEShow(true);
    // console.log(item);
  };
console.log(editBrand , 'check editBrand');
  const handleCloseEdit = () => {
    setEShow(false);
  };

  const handleStatus = (item)=> {
    setStatusShow(true);
    setNewUpdateStatus(item);
  }
  const handleStatusClose = () =>{
    setStatusShow(false);
  }

  function handlePreview(e) {
    setBrandPreview({...brandPreview , image:URL.createObjectURL(e.target.files[0])});
    setNewBrand({...newBrand, image:e.target.files[0]});
    setEditBrand({...editBrand, image:e.target.files[0]});
    console.log(newBrand , 'newBrand');
    }
  function handleBannerPreview(e) {
    setBrandPreview({...brandPreview , banner:URL.createObjectURL(e.target.files[0])});
    setNewBrand({...newBrand, banner:e.target.files[0]});
    setEditBrand({...editBrand, banner:e.target.files[0]});
    console.log(newBrand , 'newBrand');
    }
    
 const fetchbrandList = async () => {
    setLoader(true);
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`/admin/master/brand/list` )
    .then((response) => {
      setBrandList(response.data.data.list);
      setPaginate(response.data.data.pagination);
      setLoader(false);
    console.log(brandList , 'newBrand');
    }).catch((err) => {
      console.log('seller list error' , err.response)
      setLoader(false);
    })
  }

const addBrandMaster = async () => {
  setLoader(true);
  const formData = new FormData();
  formData.append("id", newBrand.id);
  formData.append("name", newBrand.name);
  formData.append("image", newBrand.image);
  formData.append("banner", newBrand.banner);

  const token = Cookies.get('s_l');  
  const newToken = decrpt(token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
  const response = await axios.post(`/admin/master/brand/save` , formData )
  .then((response) => {
    console.log(response.data.data);
    let newvar = response.data.data;
    brandList.unshift(newvar);
    let tmparray = brandList;
    let obj = tmparray.find((o, i) => {
        if (o.id) {
          tmparray[i] = {...brandList[i] , ...newvar };
          console.log('true',tmparray[i])
            return true; 
        }
    });
    setShow(false);
    setLoader(false);
    setToastText("Brand Added");
    toast.success(toastText,{hideProgressBar:true})

  }).catch((err) => {
    console.log('seller list error' , err)
    setToastText("Sorry Something Went Wrong");
    toast.error(toastText,{hideProgressBar:true})
    setErrors('Brand Name is Missing');
    setLoader(false);
    setShow(true);

  })
}

const updatebrand = async() => {
  setLoader(true);
  const formData = new FormData();
  formData.append("id", editBrand.id);
  formData.append("name", editBrand.name);
  formData.append("image", editBrand.image);
  formData.append("banner", editBrand.banner);
  try {
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
    const response = await axios.post(`/admin/master/brand/save` ,formData )
    .then((response) => {
      console.log(response.data , 'updatebrand updatebrand');
        // let newvar = response.data.data;
        // let tmparray = brandList;
        // let obj = tmparray.find((o, i) => {
        //     if (o.id === editBrand.id) {
        //       tmparray[i] = {...brandList[i] , ...newvar };
        //       console.log('true',tmparray[i])
        //         return true; 
        //     }
        // });
        setToastText("Category Name Updated");
        toast.success(toastText,{hideProgressBar:true})
        setLoader(false);
        setEShow(false);
    })
  }catch(error) {
      console.log(error);
      setLoader(false);
      setEShow(true);
      setErrors(error.response.data.errors);

  }
}
  
const brandStatus = async() => {
  setLoader(true);
  try {
    let newstatus = '';
    if (newUpdateStatus.status == 1) {
      newstatus = 2;
    } else {
      newstatus = 1;
    }
    const data = {
      id: newUpdateStatus.id,
      status  : newstatus
    }
    console.log(data , 'data of api getc')
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
    const response = await axios.post(`admin/master/brand/status` , data)
    .then((response) => {
      let tmparray = brandList;
      let obj = tmparray.find((o, i) => {
          if (o.id === newUpdateStatus.id) {
            tmparray[i] = {...brandList[i] ,   status : newstatus  };
            console.log('true',tmparray[i])
              return true; 
          }
      });
    })
    setStatusShow(false)
    setToastText("Status Updated");
    toast.success(toastText,{hideProgressBar:true})
  setLoader(false);
  }catch(error) {
      console.log(error, 'error');
      setLoader(false);
      setToastText("Something went wrong");
    toast.success(toastText,{hideProgressBar:true})
  }
}
// pagination

const handlePageChange = async (pageNumber) => {
  setLoader(true);
  console.log(`active page is ${pageNumber}`);
  const token = Cookies.get('s_l');  
  const newToken = decrpt(token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
  const response = await axios.post(`admin/category/list?page=${pageNumber}` )
  .then((response) => {
    setBrandList(response.data.data.list);
    setLoader(false);
  }).catch((err) => {
    console.log('seller list error' , err.response)
    setLoader(false);
  })
}
// pagination

  useEffect (() => {
    window.scrollTo(0, 0);
    fetchbrandList();
  }, []);
  return (
    <div className="main-content">
      {loader ? 
        <Loader />
        :
        <>
      <ToastContainer position="top-center"  autoClose={3000}  hideProgressBar={false}  newestOnTop={false}  closeOnClick  rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'colored'}/>      

          {/* New User Registration */}
          <div className="section">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                  <h5><BiCategory className="me-1" />All Brands</h5>
                </div>
                <div className="card p-2 mt-3">
                  <div className="card-title">      
                    <h5>You have 10 Brands</h5>            
                    <Button onClick={()=> handleOpen()} className="badge btn-xs pt-1">Add New Brands</Button>
                  </div>
                  <div>
                    <table className='table tabe-bordered'>
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Brand</th>
                                <th>Image </th>
                                <th>Banner Image</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                          brandList && brandList.length > 0 ? brandList.map((item,index) => {
                            return (

                              <tr key={index}>
                                <td>{index +1} </td>
                                <td>{item.name}</td>
                                <td>
                                  <img className="img-fluid" src={item.image} alt="img" style={{ width: 100,height: 100 }}/>
                                </td>
                                <td>
                                  <img className="img-fluid" src={item.banner} alt="img" style={{ width: 100,height: 100 }}/>
                                </td>
                                <td className="text-center">
                              {item && item.status == 1 ?
                                <Badge bg="success">Enable</Badge>:
                                <Badge bg="danger">Disable</Badge>}
                              </td>
                                <td className="text-center">
                                  <button onClick={()=>editCall(item)} className='btn btn-xs btn-primary text-white'>Edit</button> &nbsp;
                                  {item && item.status == 1 ?
                                  <button type="button" onClick={()=>handleStatus(item)} className='btn btn-xs btn-danger text-white'>Make Disable</button>:
                                  <button type="button" onClick={()=>handleStatus(item)} className='btn btn-xs btn-success text-white'>Make Enable</button>}  
                                </td>
                            </tr>
                              )
                            }) :
                            <tr>
                            <td colSpan={6} className="text-center fst-italic text-danger">no category added</td>
                          </tr>
                          }
                        </tbody>
                    </table>
                    { brandList && brandList  ?
                      <div className="col-md-12">
                      <Pagination
                      totalItemsCount={ paginate.total_data}
                      onChange={handlePageChange}
                      activePage={  paginate.current_page}
                      itemsCountPerPage={paginate.per_page}
                      prevPageText={false}
                      nextPageText={false}
                      />
                      </div>
                      : <></>
                  }
                  </div>

                  {/* Add Cateory */}
                  <Modal size="md" show={show} onHide={handleClose}>
                      <Modal.Header className="py-1" closeButton>
                          <Modal.Title>Add Brand</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <Form>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Brand Image </Form.Label>
                                <Form.Control type="file" onChange={handlePreview} />
                                  </Form.Group>
                                  <img src={brandPreview && brandPreview.image !== '' ? brandPreview.image: '/folder.png'} style={{ width: 100,height: 100 }} />
                              {/*  */}
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Brand banner Image </Form.Label>
                                <Form.Control type="file" onChange={handleBannerPreview} />
                                  </Form.Group>
                                  <img src={brandPreview && brandPreview.banner !== '' ? brandPreview.banner: '/folder.png'} style={{ width: 100,height: 100 }} />
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label>Brand Name</Form.Label>
                                      <Form.Control size="sm" type="text"
                                      placeholder="Enter Category Name" 
                                        onChange={e =>setNewBrand({...newBrand , name:e.target.value})}
                                      />
                                      {errors && errors ? <p style={{color : 'red' , fontSize : '12px'}}>{errors}</p> : ''}
                              </Form.Group>
                          </Form>
                      </Modal.Body>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button onClick={()=>handleClose()} className="btn btn-light btn-sm">Close</button>
                          <button type="button" onClick={()=>addBrandMaster()} className="btn btn-primary btn-sm">Save Changes</button>
                      </Modal.Footer>
                  </Modal> 

                  {/* Edit Cateory */}
                  <Modal size="md" show={Eshow}>
                      <Modal.Header>
                          <Modal.Title>Edit Brand</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <Form>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Brand Image </Form.Label>
                                <Form.Control type="file" onChange={handlePreview} />
                                </Form.Group>
                                  <img src={brandPreview && brandPreview.image !== '' ? brandPreview.image: brandSelected.image} style={{ width: 100,height: 100 }} />
                        {/*  */}
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Brand banner Image </Form.Label>
                                <Form.Control type="file" onChange={handleBannerPreview} />
                                  </Form.Group>
                                  <img src={brandPreview && brandPreview.banner !== '' ? brandPreview.banner: brandSelected.banner} style={{ width: 100,height: 100 }} />
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                  <Form.Label>Brand Name</Form.Label>
                                  <Form.Control size="sm" type="text" placeholder="Enter Category Name" 
                                  defaultValue={editBrand && editBrand.name }
                                    onChange={e => setEditBrand({...editBrand , name:e.target.value}) }
                                  />
                                      {errors && errors ? <p style={{color : 'red' , fontSize : '12px'}}>{errors}</p> : ''}
                              </Form.Group>
                          </Form>
                      </Modal.Body>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button onClick={()=>handleCloseEdit()} className="btn btn-light btn-sm">Close</button>
                          <button onClick={()=>updatebrand()} className="btn btn-primary btn-sm">Save Changes</button>
                      </Modal.Footer>
                  </Modal> 

                  <Modal size="md" show={statusShow}  centered>
                      <Modal.Header className="py-1">
                          <Modal.Title className="h6">Do you want to  {
                              newUpdateStatus && newUpdateStatus.status == 1 ?  'Disable' : 'Enable'
                            } Sub category ? <br /><span className="text-danger text-capitalize fw-bold">{newUpdateStatus && newUpdateStatus.name}</span></Modal.Title>
                      </Modal.Header>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button  onClick={()=> handleStatusClose()} className="btn btn-light btn-sm">
                              Close
                          </button>
                          <button  className="btn btn-primary btn-sm" onClick={() => brandStatus()} >
                            {newUpdateStatus && newUpdateStatus.status == 1 ?  'Make Disable' : 'Make Enable'}
                          </button>
                      </Modal.Footer>
                    </Modal> 
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default Brand;
