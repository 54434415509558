import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaPhoneAlt, FaRegEnvelopeOpen } from "react-icons/fa";

function Address(props) {
    const [lgShow, setLgShow] = useState(false);

    console.log(props , 'props');

    return (
        <div>
            <Button style={{fontSize:12, textDecoration : 'none',marginBottom : '10' }} className="badge text-primary badge-primary my-2" variant="link" onClick={() => setLgShow(true)} > 
            { props.userDeatils && props.userDeatils.address.length >= 2 ?
            <>
            {props.userDeatils && props.userDeatils.address.length} more address
            </> :''}
            </Button>
            
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton className='py-1'>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        All Address
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        { props.userDeatils && props.userDeatils.address ? props.userDeatils.address.map((item,index) => {
                            return (
                                <div key={index} className='col-md-6'>
                                    <div className='address-box mb-3'>
                                        <h6 className='mb-1'>{props.userDeatils.name}</h6>
                                        <p className='mb-1'>
                                            {item.address  ? (item.address + ' , ') : ''}
                                            {item.street  ? (item.street + ' , ') : ''}
                                            {item.land_mark  ? (item.land_mark + ' , ') : ''}
                                            {item.city_name  ? (item.city_name + ' , ') : ''}
                                            {item.state_name  ? (item.state_name ) : ''}
                                            {/* {props.userDeatils.address} */}
                                            </p>
                                        <div className="d-flex gap-3 align-items-center ">
                                            {props.userDeatils && props.userDeatils.mobile ?
                                                <p className='mb-0'> <FaPhoneAlt /> {props.userDeatils.mobile}</p>
                                                : <></> 
                                            }
                                            {props.userDeatils && props.userDeatils.email ?
                                                <p className='mb-0'> <FaRegEnvelopeOpen /> {props.userDeatils.email}</p>
                                                : <></> 
                                            }
                                            {/* <p className='mb-0'> <FaRegEnvelopeOpen />  {props.userDeatils.email}</p> */}
                                        </div>
                                    </div>
                                </div>
                                );
                            }) 
                            :
                            <><p>No New Address</p></>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Address;