import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Loader from '../../component/loader';
import { BiCategory, BiFilterAlt } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import Pagination from "react-js-pagination";
import { decrpt } from "../../component/encryption";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function FilterMap() {  
  const [loader,setLoader] = useState(false)
  const [show,setShow] = useState(false)
  const [dshow,setDShow] = useState(false)
  const [Eshow,setEShow] = useState(false);
  const [errors , setErrors] = useState();
  const [toastText,setToastText ] = useState('');
  const [paginate , setPaginate] = useState();
  const [category, setCategory] = useState();
  const [newLastCategory , setNewLastCategory] = useState();
  const [subCategory , setSubCategory] = useState();
  const [lastCategory, setLastCategory] = useState();
  const [filterList , setFilterList ] = useState();
  const [filterListMaster , setFilterListMaster ] = useState();
  const [ newFilterMap , setNewFilterMap] = useState({
    name : ''
  });
  
  const [ editNewFilterMap , setEditNewFilterMap] = useState({
    name : ''
  });

  const [addLastCategory , setAddLastCategory ] = useState();
  const [editAddLastCategory , setEditAddLastCategory ] = useState();
  const [status , setStatus] = useState();
  const [ editID ,setEditID] = useState();
console.log(addLastCategory  , 'newSize upload check');

  const handleOpen = () => {
    setShow(true);
    setErrors(false);
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleCloseDelete = () => {
    setDShow(false);
  };
  const editCall = (item) => {
    setEShow(true);
    setEditID(item);
    console.log(editID);
  };
  const handleStatus = (item) => {
    setDShow(true);
    console.log(item , 'item');
    setStatus(item);
    console.log(status , 'check status');
  }
// console.log(newBrand , 'check new brand');
  const handleCloseEdit = () => {
    setEShow(false);
  };

  const getCategoryList = async() => {
    try {
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      // console.log(newToken);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      const response = await axios.post(`admin/category/list`, {type:1})
      console.log(response.data);
      setCategory(response.data.data.list)
    } catch (error) {
      console.error(error);
    }
  }
  const handleCategory = (category_id) => {
    setNewLastCategory({
      ...newLastCategory,
      category_id : category_id,
    })
    getSubCategoryList(category_id);
    console.log(category_id , 'handle sub category fetch category');
  }

  const handleSubCategory = (category_id) => {
    setNewLastCategory({
      ...newLastCategory,
      category_id : category_id,
    })
    fetchLastCategory(category_id);
    console.log(newLastCategory , 'handle sub category fetch category');
  }
 
  // Get Sub Category List
  const getSubCategoryList = async(category_id ) => {
    const data = {
      type : 2,
      category_id : category_id
    }
    console.log(data , 'category_id category_id');
    try {
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      // console.log(newToken);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      const response = await axios.post(`admin/category/dropdown/list`, data)
      
      console.log(response.data.data , ' fetch second cat with respct first');
      setSubCategory(response.data.data);
      // setNewLastCategory({
      //   ...newLastCategory,
      //   sub_category_id : subCategory.id
      // })

      console.log(newLastCategory , 'getSubCategoryList check');
    } catch (error) {
      console.error(error);
    }
  }

const fetchLastCategory = async(category_id) => {
    const data = {
        type : 3 ,
        category_id : category_id
    }
    const token = Cookies.get('s_l');
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`admin/category/dropdown/list` , data )
    .then((response) => {   
        setLastCategory(response.data.data);
        console.log(response.data.data , 'last category');
        // setLoader(false);
    }).catch((err) => {
        console.log(err.response)
    })
}

const fetchFilterList = async () => {
    setLoader(true);
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`/admin/master/filter/map/list` )
    .then((response) => {
        setFilterList(response.data.data.list);
        setPaginate(response.data.data.pagination);
        console.log('seller list error' , response.data.data)
      setLoader(false);
    }).catch((err) => {
      console.log('seller list error' , err.response)
      setLoader(false);
    })
  }

const fetchFilterListMaster = async() => {
    try {
        const token = Cookies.get('s_l');  
        const newToken = decrpt(token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
        const response = await axios.post(`admin/master/filter/dropdown/list`)
        .then((response) => {
            setFilterListMaster(response.data.data);
            console.log(response.data.data);
        }) 
    }catch {

    }
}
const addFilterMap =  async() => {
  setLoader(true);
  try {
      const data = {
          category_id : addLastCategory.category_id ,
          filter_master_id : newFilterMap.filter_master_id
      }
      console.log(data, 'data');
        const token = Cookies.get('s_l');  
        const newToken = decrpt(token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
        const response = await axios.post(`admin/master/filter/map/save` , data)
        .then((response) => {
            console.log(response.data , 'add filter brand map');
            fetchFilterList();
            setLoader(false);
            setShow(false);
        })
    }catch(error) {
        console.log(error , 'error');
        setLoader(false);
        setShow(false);
        if(error.response.status == 412){
          setErrors(error.response.data.message);
        }else {
          setErrors('Please fill all the inputs');
        }
    }
}

const statusUpdate = async() => {
    setLoader(true);
    try {
      let newstatus = '';
      if (status.status == 1) {
        newstatus = 2;
      } else {
        newstatus = 1;
      }
      const data = {
        id: status.id,
        status  : newstatus
      }
      console.log(data, 'data');
      console.log(data , 'data of api getc')
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
      const response = await axios.post(`admin/master/filter/map/status` , data)
      .then((response) => {
        console.log(response.data.data , 'statusUpdate statusUpdate');
        let tmparray = filterList;
        let obj = tmparray.find((o, i) => {
            if (o.id === status.id) {
              tmparray[i] = {...filterList[i] ,   status : newstatus  };
              console.log('true',tmparray[i])
                return true; 
            }
        });
      })
        setDShow(false);
      setToastText("Status Updated");
      toast.success(toastText,{hideProgressBar:true})
    setLoader(false);
    }catch(error) {
        console.log(error, 'error');
        setToastText("Something went wrong")
        toast.success(toastText,{hideProgressBar:true})
        setLoader(false);
    }
  }

const editFilterMap = async() => {
  setLoader(true);
    const data = {
        id              : editID.id,
        filter_master_id :  editNewFilterMap.filter_master_id ,
        category_id : editAddLastCategory.category_id
    }
    try{
        const token = Cookies.get('s_l');  
        const newToken = decrpt(token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
        const response = await axios.post(`admin/master/filter/map/save`, data)
        .then((response) => {
            console.log(response.data , 'check');
          setLoader(false);
        })
    }catch(err) {
        console.log(err , 'error');
        setErrors(err.response.data.message);
        if(err.response.data.code == "Precondition_Failed"){
            setToastText("Map Already Exists")
            toast.success(toastText,{hideProgressBar:true})
        }
    }
}

const handlePageChange = async (pageNumber) => {
  setLoader(true);
  console.log(`active page is ${pageNumber}`);
  const token = Cookies.get('s_l');  
  const newToken = decrpt(token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
  const response = await axios.post(`/admin/master/filter/map/list?page=${pageNumber}` )
  .then((response) => {
    setFilterList(response.data.data.list);
    setLoader(false);
    console.log(response.data);
  }).catch((err) => {
    console.log('seller list error' , err.response)
    setLoader(false);
  })
}


useEffect(() => {
    fetchFilterList();
    getCategoryList();
    fetchFilterListMaster();
}, []);

  return (
    <div className="main-content">
      {loader ? 
        <Loader />
        :
        <>
      <ToastContainer position="top-center"  autoClose={3000}  hideProgressBar={false}  newestOnTop={false}  closeOnClick  rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'colored'}/>
          {/* New User Registration */}
          <div className="section">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                  <h5><BiCategory className="me-1" />All  Filter mapping</h5>
                </div>
                <div className="card p-2 mt-3">
                  <div className="card-title">      
                    <h5>You have {filterList && filterList.length} filter maps</h5>            
                    <Button onClick={()=> handleOpen()} className="badge btn-xs pt-1">Add New filter map</Button>
                  </div>
                  <div>
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Filter Map name </th>
                                <th>Category Name</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {filterList && filterList.length > 0 ? 
                            <>
                            { filterList && filterList.map((item,index) => {
                                    return (
                                        <tr key={index}>
                                        <td>{item.id}</td>
                                        <td>{item.filter_master_name}</td>
                                        <td>{item && item.category_name}</td>
                                        <td className="text-center">
                                            { item &&  item.status == 1 ?
                                            <Badge bg="success">Enable</Badge>:
                                            <Badge bg="danger">Disable</Badge>}
                                            </td>
                                        <td className="text-center">
                                          <button onClick={()=>editCall(item)} className='btn btn-xs btn-primary text-white'>Edit</button> &nbsp;
                                          {item && item.status == 1 ? 
                                            <button onClick={()=> handleStatus(item)}  className='btn btn-xs btn-danger text-white'>Make Disable</button>:
                                            <button  onClick={()=> handleStatus(item)} className='btn btn-xs btn-success text-white'>Make Enable</button>}                                  
                                        </td>
                                    </tr>
                                    )
                                   }
                                )
                            }
                            </> 
                            : 
                            <><tr><td>No data found</td></tr></>
                        }
                        </tbody>
                    </table>
                    { paginate && paginate  ?
                    <div className="col-md-12">
                        <Pagination
                        totalItemsCount={ paginate.total_data}
                        onChange={handlePageChange}
                        activePage={  paginate.current_page}
                        itemsCountPerPage={paginate.per_page}
                        // pageRangeDisplayed={10}
                        />
                    </div>
                : <></>
                }
                  </div>

                  {/* Add Cateory */}
                  <Modal size="md" show={show} onHide={handleClose}>
                      <Modal.Header className="py-1" closeButton>
                          <Modal.Title>Add Filter Map</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <Form>
                            {errors  && errors  ? <p style={{color : 'red' , fontSize : '12px'}}>{errors}</p> : ''}
                          <Form.Group>
                                <Form.Label>Select First Category</Form.Label>
                                <Form.Select aria-label="Default select example" 
                                // onChange={category => setNewLastCategory({...newLastCategory , category_id:category.target.value })}
                                  onChange = {category => handleCategory(category.target.value)}
                                >
                                  {category && category.length > 0 ?
                                  <>
                                      <option selected disabled>----select from below----</option>
                                    {category.map((item,index) => {
                                      return (
                                        <>
                                        <option key={index} value={item.id}>{item.name}</option>
                                        </>
                                      ) 
                                    })}
                                  </>:
                                  <>
                                    <option disabled>Select Category First</option>
                                  </>
                                  }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Sub Category</Form.Label>
                            <Form.Select aria-label="Default select example"
                            //  onChange={e => subCategoryHandler(e)}
                                onChange = {sub_category => handleSubCategory(sub_category.target.value)}
                                >
                                {subCategory && subCategory.length > 0 ?
                                <>
                                    <option selected disabled>----select from below-----</option>
                                {subCategory.map((item,index) => {
                                    return <option key={index}  value={item.id}>{item.name}</option>
                                })}
                                </>:
                                <>
                                <option disabled>Select Category First</option>
                                </>
                                }
                            </Form.Select>
                            </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Last Category</Form.Label>
                            <Form.Select aria-label="Default select example" 
                            onChange={ e => setAddLastCategory({...addLastCategory , category_id:e.target.value})}
                            >
                                <option selected disabled>----select from below-----</option>
                                {lastCategory && lastCategory.map((item, index) => {
                                    return (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        );
                                    })
                                }
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Filter Map Name </Form.Label>
                            <Form.Select aria-label="Default select example" 
                                onChange={e => setNewFilterMap({...newFilterMap , filter_master_id:e.target.value }) }
                            >
                                      <option selected disabled>----select from below----</option>
                                {filterListMaster && filterListMaster.map((item,index) => {
                                      <option selected disabled>----select from below-----</option>
                                    return (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    );
                                }) 
                                }
                            </Form.Select>
                          </Form.Group>
                          </Form>
                      </Modal.Body>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button onClick={()=>handleClose()} className="btn btn-light btn-sm">
                              Close
                          </button>
                          <button onClick={()=>addFilterMap()} className="btn btn-primary btn-sm">
                              Save Changes
                          </button>
                      </Modal.Footer>
                  </Modal> 

                  {/* Delete Cateory */}
                  <Modal size="md" show={dshow} onHide={handleCloseDelete} centered>
                      <Modal.Header>
                          <Modal.Title className="h6 fw-bolder">Do you want to  {
                          status && status.status == 1 ?  'Disable' : 'Enable'
                        }</Modal.Title>
                      </Modal.Header>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button onClick={()=>handleCloseDelete()} className="btn btn-light btn-sm">No</button>
                          <button onClick={()=>statusUpdate()} className="btn btn-primary btn-sm">
                          {status && status.status == 1 ?  'Make Disable' : 'Make Enable'}
                          </button>
                      </Modal.Footer>
                  </Modal> 
                  {/* Edit Cateory */}
                  <Modal size="md" show={Eshow}>
                      <Modal.Header>
                          <Modal.Title>Edit Size</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                            <Form.Group>
                                <Form.Label>Select First Category</Form.Label>
                                <Form.Select aria-label="Default select example" 
                                // onChange={category => setNewLastCategory({...newLastCategory , category_id:category.target.value })}
                                  onChange = {category => handleCategory(category.target.value)}
                                  defaultValue= {editID && editID.category_name}
                                >
                                  {category && category.length > 0 ?
                                  <>
                                      <option selected disabled>----select from below----</option>
                                    {category.map((item,index) => {
                                      return (
                                        <>
                                        <option key={index} value={item.id}>{item.name}</option>
                                        </>
                                      ) 
                                    })}
                                  </>:
                                  <>
                                    <option disabled>Select Category First</option>
                                  </>
                                  }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Select Sub Category</Form.Label>
                                <Form.Select aria-label="Default select example"
                                //  onChange={e => subCategoryHandler(e)}
                                    onChange = {sub_category => handleSubCategory(sub_category.target.value)}
                                    >
                                    {subCategory && subCategory.length > 0 ?
                                    <>
                                        <option selected disabled>----select from below-----</option>
                                    {subCategory.map((item,index) => {
                                        return <option key={index}  value={item.id}>{item.name}</option>
                                    })}
                                    </>:
                                    <>
                                    <option disabled>Select Category First</option>
                                    </>
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Select Last Category</Form.Label>
                                    <Form.Select aria-label="Default select example" 
                                    onChange={ e => setEditAddLastCategory({...editAddLastCategory , category_id:e.target.value})}
                                    >
                                        {
                                            lastCategory && lastCategory.length > 0 ?
                                            <>
                                            <option selected disabled>----select from below-----</option>
                                            {lastCategory && lastCategory.map((item, index) => {
                                                return (
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    );
                                                })
                                            }
                                            </>:
                                            <>
                                            <option disabled>Select Category First</option>
                                            </>
                                        }
                                    </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Select Filter Map Name </Form.Label>
                                <Form.Select aria-label="Default select example" 
                                    onChange={e => setEditNewFilterMap({...editNewFilterMap , filter_master_id:e.target.value }) }
                                >
                                      <option selected disabled>----select from below----</option>
                                    {filterListMaster && filterListMaster.map((item,index) => {
                                        <option selected disabled>----select from below-----</option>
                                        return (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        );
                                    }) 
                                    }
                                </Form.Select>
                          </Form.Group>
                      </Modal.Body>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button onClick={()=>handleCloseEdit()} className="btn btn-light btn-sm">
                              Close
                          </button>
                          <button onClick={()=>editFilterMap()} className="btn btn-primary btn-sm">
                              Save Changes
                          </button>
                      </Modal.Footer>
                  </Modal> 

                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default FilterMap;
