import React, { useEffect, useState , useRef } from "react";
import * as ReactDomClient from "react-dom/client";
import { useLocation } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import { Badge} from 'react-bootstrap';
import Loader from '../../component/loader';
import ProductDetailsThumbImage from '../../component/productDetailsThumbImg';
import axios from 'axios';
import Cookies from "js-cookie";
import {decrpt} from "../../component/encryption";
import ReactHtmlParser from 'react-html-parser';
import { render } from "@testing-library/react";


function ProductSingle() {
  const [loader,setLoader] = useState(false)
  const location = useLocation();
  // console.log(location , "location");

  const [productDetails,setProductDetails]=useState('');
  
  const fetchProductDitails=async()=>{
    setLoader(true);
    const data = {
      id: location.state.item,
    }
    // console.log(id,'id');
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization']=`Bearer ${token}`;
      const response = await axios.post(`/admin/product/details`,data);
      if (response.data.success) {
        setLoader(false);
        setProductDetails(response.data.data);
    console.log(response.data.data,'id');
      } else {
        setLoader(false);
      }
      
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }

  useEffect (() => {
    window.scrollTo(0, 0);
    fetchProductDitails()
  }, []);
  // const Example = () => {
  //   const componentRef = useRef();
  //   const handlePrint = useReactToPrint({
  //     content : () => componentRef.current,
  //   });
  //   return (
  //     <div>
  //       <ProductSingle ref={componentRef} />
  //       <button onClick={handlePrint} >print thius</button>
  //     </div>
  //   );
  // }
  // render(<Example /> , document.querySelector("#root"))


  return (
    <div className="main-content">
      {loader ? 
        <Loader />
        :
        <>
          {/* New User Registration */}
          <div className="section">
            <div className="row">
              <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <div className="row">
                    <div className="col-md-9">
                      <div className="d-flex justify-content-between">
                          <h3 className="h5 fw-bold" style={styles.header}>{productDetails && productDetails.name}</h3>
                      </div>
                      <div className="d-flex align-items-center mt-2">
                        <label>{productDetails && productDetails.category}</label>
                        &nbsp; <AiOutlineRight /> &nbsp; 
                        <label>{productDetails && productDetails.sub_category}</label>
                        &nbsp; <AiOutlineRight /> &nbsp; 
                        <label>{productDetails && productDetails.third_category}</label>
                      </div>
                      <div>
                      <div className="d-flex mt-2">
                        <label><strong>Brand : </strong></label>
                        <p className="mb-1" style={{paddingLeft:10}}><Badge bg="primary" text="light">{productDetails && productDetails.brand}</Badge></p>
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <div>
                        {productDetails && productDetails.weight != null ? 
                        <div className="d-flex">
                          <label><strong>Weight : </strong></label>
                          <p className="mb-1" style={{paddingLeft:10}}>{productDetails && productDetails.weight} (gm)</p>
                        </div>:''
                        }
                      </div>
                      &nbsp; &nbsp; 
                      <div>
                      {productDetails && productDetails.height != null ?
                      <div className="d-flex">
                        <label><strong>Height : </strong></label>
                        <p className="mb-1" style={{paddingLeft:10}}>{productDetails && productDetails.height} (inch)</p>
                      </div>:''
                    }
                      </div>
                      &nbsp; &nbsp; 
                      <div>
                      {productDetails && productDetails.width != null ?
                        <div className="d-flex">
                            <label><strong>Width : </strong></label>
                            <p className="mb-1" style={{paddingLeft:10}}>{productDetails && productDetails.width} (inch)</p>
                          </div>:''
                        }
                      </div>
                     </div>
                  <div className="row mt-2">
                    {/* Shipping Address */}
                      <div>
                        <label className="mb-0"><strong>Product Short Description</strong> </label> 
                        {/*   { ReactHtmlParser(prodetails) } */}
                        <p className="mb-0" style={{fontSize:15}}> 
                        { ReactHtmlParser(productDetails && productDetails.short_description)}
                        </p>
                      </div>
                  </div>
                  </div>
                    <div className="col-md-3">
                      <div className="d-flex gap-2 py-3">
                        {productDetails && productDetails ?
                              <>
                              <div className="product-single text-center">
                                <img src={productDetails && productDetails.main_image} />
                              </div>
                              <div className="d-flex">
                                  <div className="productDetails-thumbnail d-flex flex-column">
                                    {
                                      productDetails && productDetails.images.map((item,index) => {
                                        return (
                                          <ProductDetailsThumbImage key={index} image={item.image}  className="mb-2"></ProductDetailsThumbImage>
                                        );
                                      })
                                    }
                                  </div>
                              </div>
                              </>
                              :
                              ''}
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>

              {/* long description */}
              <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5>Product Long Description</h5>
                  </div>
                  <div><p className="mb-0" style={{fontSize:15}}> { ReactHtmlParser(productDetails && productDetails.description)}</p></div>
                </div>
              </div>
              {/* return policy */}
              <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5>Return policy Details</h5>
                  </div>
                  <div>
                  {productDetails && productDetails.return_day!= 0 || productDetails && productDetails.return_policy != null ?
                  <div>
                    {productDetails && productDetails.return_day != 0 ? 
                      <p className="mb-0" style={{fontSize:15}}> Return Days : {productDetails && productDetails.return_day}</p>:''
                    }
                    {productDetails && productDetails.return_day != null ? 
                      <p className="mb-0" style={{fontSize:15}}> Return Policy : {productDetails && productDetails.return_policy}</p>:''
                    }
                  </div>:
                    <p className="mb-0" style={{fontSize:15}}>No Return policy here</p>
                  }
                  </div>
                </div>
              </div>
              {/* Past Order */}
                <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <h5>Product Price and stock Details</h5>
                  </div>
                  <div>
                    {productDetails && productDetails.stock_details.length > 0 ?
                    <table className='table tabe-bordered'>
                        <thead>
                            <tr className="text-center">
                                <th>SL</th>
                                <th>Size</th>
                                <th>MRP</th>
                                <th>Price</th>
                                <th>Stock</th>
                            </tr>
                        </thead>
                        <tbody>
                        {productDetails && productDetails.stock_details.map((item,index)=>{
                          return(
                            <tr className="text-center" key={index}>
                                <td>{item.id}</td>
                                <td>{item.size}</td>
                                <td>₹ {item.mrp}</td>
                                <td>₹ {item.price}</td>
                                <td>{item.stock}</td>
                            </tr>
                            )
                          })
                        }
                        </tbody>
                    </table>:
                    <div className="d-flex mt-2">
                        <div className="d-flex">
                          <label><strong>MRP : </strong></label>
                          <p className="mb-1" style={{paddingLeft:10}}>₹ {productDetails && productDetails.mrp}</p>
                        </div>

                      &nbsp;&nbsp; | &nbsp;&nbsp; 
                      
                      <div className="d-flex">
                        <label><strong>Price : </strong></label>
                        <p className="mb-1" style={{paddingLeft:10}}>₹ {productDetails && productDetails.price}</p>
                      </div>
                     

                      &nbsp;&nbsp; | &nbsp;&nbsp; 
                      
                      <div className="d-flex">
                          <label><strong>Stock : </strong></label>
                          <p className="mb-1" style={{paddingLeft:10}}>{productDetails && productDetails.stock}</p>
                      </div>
                     </div>
                     }
                  </div>
                </div>
              </div>

            </div>
          </div>
          </>
          }
    </div>

  );

}
export default ProductSingle;

const styles = {
  header: {textTransform: 'uppercase',color:'#149348',marginBottom:0},
  box : {background:'#e1e1e1',borderRadius:10, paddingLeft:10,paddingRight:10,padding:10,flex:1,boxShadow: '0 0 5px 0 #868686',border: '2px dotted #fff'},
  boxHeading : {color:'#333',fontWeight:'900',fontSize:15,marginBottom:0},
  boxNumber : {color:'green',fontWeight:'900',fontSize:25,marginBottom:0},
}
