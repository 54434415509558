import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function DocumentModal({docShow,setDocShow,mailingAddressUpdate ,mail ,setMail}) {

    // console.log(mail && mail , 'compo mail')
    return (
        <div>
            <Modal
                size="lg"
                show={docShow}
                onHide={() => setDocShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton className='py-1'>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Mailing Address
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={mail && mail }
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setMail(data);
                                    console.log( { event, editor, data } );
                                } }
                                onBlur={ ( event, editor ) => {
                                    // console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    // console.log( 'Focus.', editor );
                                } }
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="p-1 justify-content-start">
                        <button onClick={()=>setDocShow(false)} className="btn btn-light btn-sm">
                            Close
                        </button>
                        <button onClick={()=>mailingAddressUpdate()} className="btn btn-primary btn-sm">
                            Update
                        </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default DocumentModal;