import React, { useEffect, useState } from "react";
import { BrowserRouter , Route, Routes,Outlet } from "react-router-dom";
import SellerList from './list';
import SellerSingle from './single';

function Seller() {  
  const [loader,setLoader] = useState(false)
  useEffect (() => {
    window.scrollTo(0, 0);
    setLoader(true);
    setTimeout(() => {      
      setLoader(false);
    }, 500);
  }, []);
  return (
    <div>
      {/* <Routes>   
        <Route exact index path="/*" element={<SellerList />} />
        <Route exact path="/single" element={<SellerSingle />} />
      </Routes> */}
      <Outlet />
    </div>

  );
}

export default Seller;

