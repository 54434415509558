// import React, { useEffect, useState,useRef } from "react";
// import { Outlet , useLocation } from "react-router-dom";
// import { FaPhoneAlt, FaRegEnvelopeOpen, FaRegPaperPlane } from "react-icons/fa";
// import { AiOutlinePoweroff, AiOutlineKey } from "react-icons/ai";
// import { Badge, Button, Table} from 'react-bootstrap';
// import Loader from '../../component/loader';
// import ChangeStatusModal from "../../component/changestatusmodal";
// import axios from "axios";
// import Cookies from "js-cookie";
// import { decrpt } from "../../component/encryption";
// import ReactToPrint  from 'react-to-print';

// function OrderSingle() {
//   let componentRef = useRef();
//   const location = useLocation();  
//   console.log(location.state.item , 'location');
//   const [loader,setLoader] = useState(false)
//   const [show, setShow] = useState(false);  
//   const [orderData , setOrderData ] = useState();
//   const [orderDetails , setOrderDetails ] = useState();


//   console.log(orderData , 'orderData');

//   const handleClose = () => {
//     setShow(false);
//   };
//   const statusModal = () => {
//     setShow(true);
//   };
  
//   let itemid = location.state.item;
//   const orderDetailsFetch = async() => {
//     console.log(itemid , 'jikol');
//     setLoader(true);
//     try {
//       const token = Cookies.get('s_l');  
//       const newToken = decrpt(token);
//       axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
//       const response = await axios.post(`/admin/orders/details` , {order_id: itemid})
//       console.log(response , 'order details list');
//       setOrderData(response.data.order_data);
//       setOrderDetails(response.data.order_details);
//       setLoader(false);
//     } catch (error) {
//       console.error(error);
//       setLoader(false);
//     }
//   }

//   useEffect ( () => {
//     window.scrollTo(0, 0);
//     orderDetailsFetch(itemid);
//   }, []);

//   return (
//     <div  className="main-content">
//       {loader ? 
//         <Loader />
//         :
//         <>
//           <div className="section">
//             <div className="row padding-25" ref={el => (componentRef = el)}>

//               <div className="col-md-12">
//                 <div className="card mb-3 p-2">
//                   <div style={{display : 'flex'}} >
//                       <h3 className="h3 fw-bold" style={styles.header}>{orderData && orderData.order_uuid}</h3>
//                       <div  style={{marginLeft : 'auto'}} className="hidestuff">
//                         <ReactToPrint
//                             trigger={() => (
//                               <button className="btn btn-warning" type="button" >
//                                 Print Receipt
//                               </button>
//                             )}
//                             content={() => componentRef}
//                             />
//                         </div>
//                     </div>
//                   <div className="d-flex">
//                     <label className="mb-2"><strong>Order Status : </strong>
                  
//                     {
//                       orderData && orderData ? 
//                       <>
//                       { orderData.order_status == 1 ? <Badge bg="primary"> New</Badge> : 
//                         (orderData.order_status == 2)  ? <Badge bg="success"> Accepted </Badge> : 
//                         (orderData.order_status == 6)  ? <Badge bg="danger"> Canceled </Badge> : 
//                         (orderData.order_status == 5)  ? <Badge bg="danger"> Delivered </Badge> : 
//                         (orderData.order_status == 3) ? <Badge bg="warning"> Dispatch </Badge> : 
//                         (orderData.order_status == 4) ? <Badge bg="secondary"> Out For Delivered</Badge> : 
//                         <Badge bg="success"> Cancelled By Admin</Badge> }
//                       </>: <></>
//                     }
//                                 </label>
//                     &nbsp;  | &nbsp; 
//                     <label className="mb-2"><strong>Order Date : </strong> {orderData && orderData.order_date}</label>
//                     &nbsp;  | &nbsp; 
//                     <label className="mb-2"><strong>Pickup Date : </strong> {orderData && orderData.delivered_date}</label>
//                   </div>

//                   <div className="d-flex">
//                     <div>
//                       <label><strong>Overall Price</strong></label>
//                       <p className="mb-2">₹ { orderData && orderData.overall_price}.00</p>
//                     </div>
//                     &nbsp;  | &nbsp; 
//                     <div>
//                       <label><strong>Payment Type</strong></label>
//                       <p className="mb-2"><Badge bg="primary" text="light">{orderData && orderData.payment_type == "COD" ? 'Cash on Delivery' : 'Online' }</Badge></p>
//                     </div>
//                     &nbsp;  | &nbsp; 
//                     <div>
//                       <label><strong>Payment Status</strong></label>
//                       <p className="mb-2"><Badge bg="success" text="light">
//                           {orderData && orderData.payment_status == 1 ? 'Pending' : (orderData && orderData.payment_status == 2 ? 'Paid' : 'Failed')}
//                         </Badge></p>
//                     </div>
//                   </div>

//                   <div className="row">
//                     <div className="col-md-6">
//                       {/* Shipping Address */}
//                       <div>
//                         <label className="mb-0 "><strong>Shipping Address</strong> </label> 
//                         {orderData && orderData.shippingAddress ? 
//                         <>
//                         <p className="mb-0 "><FaRegPaperPlane  className="me-1"/>
//                           {  orderData && orderData.shippingAddress ? orderData.shippingAddress.address : ''}  
//                          { orderData.shippingAddress.land_mark !== null ? ( ' , ' + orderData.shippingAddress.land_mark) : '' } 
//                          { orderData.shippingAddress.street !== null ? ( ' , ' + orderData.shippingAddress.street ) : '' } 
//                          {orderData.shippingAddress.city !== null ? ( ' , ' + orderData.shippingAddress.city ) : ''} 
//                          {orderData.shippingAddress.pin !== null ? ( ' - ' + orderData.shippingAddress.pin ) : ''} 
//                          {orderData.shippingAddress.state !== null ? ( ' , ' + orderData.shippingAddress.state ) : ''} 
//                         </p>
//                         </>
//                         :
//                         <>
//                         </>  
//                       }
//                         <div className="d-flex ">
//                           <p> <FaPhoneAlt /> {orderData && orderData.shippingAddress.mobile}</p>
//                           {/* {orderData && orderData.shippingAddress.mobile ?
//                           <p> <FaRegEnvelopeOpen />  orderData.email</p>
//                           : <></>
//                           } */}
                          
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-md-6">
//                       {/* Shipping Address */}
//                       <div>
//                         <label className="mb-0"><strong>Billing Address</strong> </label> 
//                         {orderData && orderData.billingAddress ? 
//                         <>
//                         <p className="mb-0"><FaRegPaperPlane  className="me-1" /> 

//                          {orderData && orderData.billingAddress ? orderData.billingAddress.address : ''}  
//                          { orderData &&  orderData.billingAddress.land_mark !== null ? ( ' , ' + orderData.billingAddress.land_mark) : '' } 
//                          { orderData &&  orderData.billingAddress.street !== null ? ( ' , ' + orderData.billingAddress.street ) : '' } 
//                          { orderData && orderData.billingAddress.city !== null ? ( ' , ' + orderData.billingAddress.city ) : ''} 
//                          { orderData && orderData.billingAddress.pin !== null ? ( ' - ' + orderData.billingAddress.pin ) : ''} 
//                          { orderData && orderData.billingAddress.state !== null ? ( ' , ' + orderData.billingAddress.state ) : ''} 
//                          {/* {orderData.billingAddress.address_type !== null  ? ( ' ( ' + orderData.billingAddress.address_type == 'O'  ? "Office" : "Home " + ')' ) : ''}  */}
//                         </p>
//                         </>
//                         :
//                         <>
//                         </>  
//                       }
//                         <div className="d-flex ">
//                           <p> <FaPhoneAlt /> {orderData && orderData.billingAddress.mobile}</p> &nbsp;   &nbsp; 
//                           {/* <p> <FaRegEnvelopeOpen />  demo@mail.com</p> */}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
                  
//                 </div>
//               </div>

//               {/* Past Order */}
//               <div className="col-md-12">
//                 <div className="card mb-3 p-2">
//                   <div className="card-title d-flex align-items-center justify-content-between">
//                     <h5>All Items</h5>
                   
                   
//                   </div>
//                   <div>
//                     <Table responsive="sm">
//                         <thead>
//                             <tr>
//                                 <th>SL</th>
//                                 <th >Product Image</th>
//                                 <th style={{maxWidth:150}}>Product Name</th>
//                                 <th >Brand</th>
//                                 <th >Quantity</th>
//                                 <th className="text-center hidestuff">Category</th>
//                                 <th className="text-center hidestuff">Sub Category</th>
//                                 <th className="text-center hidestuff">Last Category</th>
//                                 <th className="text-center">MRP</th>
//                                 <th className="text-center">Price</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                           {
//                             orderDetails && orderDetails.map((item,index) => {
//                               return (
//                             <tr key={index}>
//                                 <td>{index+ 1} </td>
//                                 <td> <img src={item.product_image} height="100" /> </td>
//                                 <td>{item.product_name}</td>
//                                 <td>{item.brand}</td>
//                                 <td className="text-center">{item.quantity}</td>
//                                 <td className="text-center hidestuff">{item.category}</td>
//                                 <td className="text-center hidestuff">{item.sub_category}</td>
//                                 <td className="text-center hidestuff">{item.third_category}</td>
//                                 <td className="text-center">₹{item.mrp}.00</td>
//                                 <td className="text-center">₹{item.price}.00</td>
//                             </tr>
//                               );
//                             })
//                           }
//                         </tbody>
//                     </Table>
//                   </div>                                                                                                             
//                   {/* <ChangeStatusModal show={show} handleClose={handleClose} /> */}

//                   <div className="row justify-content-end">
//                     <div className="col-md-4">
//                       <Table bordered variant="light">
//                         <tbody className="text-right">
//                           <tr>
//                             <td>Sub Total</td>
//                             <td>₹ {orderData && orderData.total_price}</td>
//                           </tr>
//                           <tr>
//                             <td>Delivery</td>
//                             <td>₹ {orderData && orderData.shipping_charge}</td>
//                           </tr>
//                           <tr>
//                             <td><strong>Grand Total</strong></td>
//                             <td><strong>₹ {orderData && orderData.overall_price}.00</strong></td>
//                           </tr>
//                         </tbody>
//                       </Table>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//             </div>
//           </div>
//         </>
//       }
//     </div>

//   );
// }


// export default OrderSingle;
// const styles = {
//   header: {textTransform: 'uppercase',color:'#149348',marginBottom:0},
//   box : {background:'#e1e1e1',borderRadius:10, paddingLeft:10,paddingRight:10,padding:10,flex:1,boxShadow: '0 0 5px 0 #868686',border: '2px dotted #fff'},
//   boxHeading : {color:'#333',fontWeight:'900',fontSize:15,marginBottom:0},
//   boxNumber : {color:'green',fontWeight:'900',fontSize:25,marginBottom:0},
// }
import React, { useEffect, useState, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { FaPhoneAlt, FaRegEnvelopeOpen, FaRegPaperPlane } from "react-icons/fa";
import { AiOutlinePoweroff, AiOutlineKey } from "react-icons/ai";
import { Badge, Button, Table } from 'react-bootstrap';
import Loader from '../../component/loader';
import ChangeStatusModal from "../../component/changestatusmodal";
import axios from "axios";
import Cookies from "js-cookie";
import { decrpt } from "../../component/encryption";
import ReactToPrint from 'react-to-print';
import logo from '../../assets/logo2.png'; // Import the logo image

function OrderSingle() {
  let componentRef = useRef();
  const location = useLocation();  
  console.log(location.state.item , 'location');
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);  
  const [orderData, setOrderData] = useState();
  const [orderDetails, setOrderDetails] = useState();

  console.log(orderData, 'orderData');

  const handleClose = () => {
    setShow(false);
  };
  const statusModal = () => {
    setShow(true);
  };

  let itemid = location.state.item;
  const orderDetailsFetch = async() => {
    console.log(itemid, 'jikol');
    setLoader(true);
    try {
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      const response = await axios.post(`/admin/orders/details`, {order_id: itemid})
      console.log(response, 'order details list');
      setOrderData(response.data.order_data);
      setOrderDetails(response.data.order_details);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    orderDetailsFetch(itemid);
  }, []);

  return (
    <div className="main-content">
      {loader ? 
        <Loader />
        :
        <>
          <div className="section">
            <div className="row padding-25" ref={el => (componentRef = el)}>

              <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <div style={{display : 'flex', justifyContent: 'space-between', alignItems: 'center'}} >
                      <img src={logo} alt="Logo" style={{ height: 50 }} /> {/* Add logo here */}
                      <h3 className="h3 fw-bold" style={styles.header}>{orderData && orderData.order_uuid}</h3>
                      <div style={{marginLeft : 'auto'}} className="hidestuff">
                        <ReactToPrint
                            trigger={() => (
                              <button className="btn btn-warning" type="button" >
                                Print Receipt
                              </button>
                            )}
                            content={() => componentRef}
                            />
                      </div>
                    </div>
                  <div className="d-flex">
                    <label className="mb-2"><strong>Order Status : </strong>
                      {orderData && orderData ? 
                      <>
                      { orderData.order_status == 1 ? <Badge bg="primary"> New</Badge> : 
                        (orderData.order_status == 2)  ? <Badge bg="success"> Accepted </Badge> : 
                        (orderData.order_status == 6)  ? <Badge bg="danger"> Canceled </Badge> : 
                        (orderData.order_status == 5)  ? <Badge bg="danger"> Delivered </Badge> : 
                        (orderData.order_status == 3) ? <Badge bg="warning"> Dispatch </Badge> : 
                        (orderData.order_status == 4) ? <Badge bg="secondary"> Out For Delivered</Badge> : 
                        <Badge bg="success"> Cancelled By Admin</Badge> }
                      </>: <></>
                    }
                    </label>
                    &nbsp;  | &nbsp; 
                    <label className="mb-2"><strong>Order Date : </strong> {orderData && orderData.order_date}</label>
                    &nbsp;  | &nbsp; 
                    <label className="mb-2"><strong>Pickup Date : </strong> {orderData && orderData.delivered_date}</label>
                  </div>

                  <div className="d-flex">
                    <div>
                      <label><strong>Overall Price</strong></label>
                      <p className="mb-2">₹ { orderData && orderData.overall_price}.00</p>
                    </div>
                    &nbsp;  | &nbsp; 
                    <div>
                      <label><strong>Payment Type</strong></label>
                      <p className="mb-2"><Badge bg="primary" text="light">{orderData && orderData.payment_type == "COD" ? 'Cash on Delivery' : 'Online' }</Badge></p>
                    </div>
                    &nbsp;  | &nbsp; 
                    <div>
                      <label><strong>Payment Status</strong></label>
                      <p className="mb-2"><Badge bg="success" text="light">
                          {orderData && orderData.payment_status == 1 ? 'Pending' : (orderData && orderData.payment_status == 2 ? 'Paid' : 'Failed')}
                        </Badge></p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      {/* Shipping Address */}
                      <div>
                        <label className="mb-0 "><strong>Shipping Address</strong> </label> 
                        {orderData && orderData.shippingAddress ? 
                        <>
                        <p className="mb-0 "><FaRegPaperPlane  className="me-1"/>
                          {  orderData && orderData.shippingAddress ? orderData.shippingAddress.address : ''}  
                         { orderData.shippingAddress.land_mark !== null ? ( ' , ' + orderData.shippingAddress.land_mark) : '' } 
                         { orderData.shippingAddress.street !== null ? ( ' , ' + orderData.shippingAddress.street ) : '' } 
                         {orderData.shippingAddress.city !== null ? ( ' , ' + orderData.shippingAddress.city ) : ''} 
                         {orderData.shippingAddress.pin !== null ? ( ' - ' + orderData.shippingAddress.pin ) : ''} 
                         {orderData.shippingAddress.state !== null ? ( ' , ' + orderData.shippingAddress.state ) : ''} 
                        </p>
                        </>
                        :
                        <>
                        </>  
                      }
                        <div className="d-flex ">
                          <p> <FaPhoneAlt /> {orderData && orderData.shippingAddress.mobile}</p>
                          {/* {orderData && orderData.shippingAddress.mobile ?
                          <p> <FaRegEnvelopeOpen />  orderData.email</p>
                          : <></>
                          } */}
                          
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      {/* Shipping Address */}
                      <div>
                        <label className="mb-0"><strong>Billing Address</strong> </label> 
                        {orderData && orderData.billingAddress ? 
                        <>
                        <p className="mb-0"><FaRegPaperPlane  className="me-1" /> 
                         {orderData && orderData.billingAddress ? orderData.billingAddress.address : ''}  
                         { orderData &&  orderData.billingAddress.land_mark !== null ? ( ' , ' + orderData.billingAddress.land_mark) : '' } 
                         { orderData &&  orderData.billingAddress.street !== null ? ( ' , ' + orderData.billingAddress.street ) : '' } 
                         { orderData && orderData.billingAddress.city !== null ? ( ' , ' + orderData.billingAddress.city ) : ''} 
                         { orderData && orderData.billingAddress.pin !== null ? ( ' - ' + orderData.billingAddress.pin ) : ''} 
                         { orderData && orderData.billingAddress.state !== null ? ( ' , ' + orderData.billingAddress.state ) : ''} 
                         {/* {orderData.billingAddress.address_type !== null  ? ( ' ( ' + orderData.billingAddress.address_type == 'O'  ? "Office" : "Home " + ')' ) : ''}  */}
                        </p>
                        </>
                        :
                        <>
                        </>  
                      }
                        <div className="d-flex ">
                          <p> <FaPhoneAlt /> {orderData && orderData.billingAddress.mobile}</p> &nbsp;   &nbsp; 
                          {/* <p> <FaRegEnvelopeOpen />  demo@mail.com</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>

              {/* Past Order */}
              <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <h5>All Items</h5>
                  </div>
                  <div>
                    <Table responsive="sm">
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Product Image</th>
                                <th style={{maxWidth:150}}>Product Name</th>
                                <th>Brand</th>
                                <th>Quantity</th>
                                <th className="text-center hidestuff">Category</th>
                                <th className="text-center hidestuff">Sub Category</th>
                                <th className="text-center hidestuff">Last Category</th>
                                <th className="text-center">MRP</th>
                                <th className="text-center">Price</th>
                            </tr>
                        </thead>
                        <tbody>
                          {orderDetails && orderDetails.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1} </td>
                                <td><img src={item.product_image} height="100" /></td>
                                <td>{item.product_name}</td>
                                <td>{item.brand}</td>
                                <td className="text-center">{item.quantity}</td>
                                <td className="text-center hidestuff">{item.category}</td>
                                <td className="text-center hidestuff">{item.sub_category}</td>
                                <td className="text-center hidestuff">{item.third_category}</td>
                                <td className="text-center">₹{item.mrp}.00</td>
                                <td className="text-center">₹{item.price}.00</td>
                            </tr>
                          ))}
                        </tbody>
                    </Table>
                  </div>                                                                                                             
                  {/* <ChangeStatusModal show={show} handleClose={handleClose} /> */}

                  <div className="row justify-content-end">
                    <div className="col-md-4">
                      <Table bordered variant="light">
                        <tbody className="text-right">
                          <tr>
                            <td>Sub Total</td>
                            <td>₹ {orderData && orderData.total_price}</td>
                          </tr>
                          <tr>
                            <td>Delivery</td>
                            <td>₹ {orderData && orderData.shipping_charge}</td>
                          </tr>
                          <tr>
                            <td><strong>Grand Total</strong></td>
                            <td><strong>₹ {orderData && orderData.overall_price}.00</strong></td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </>
      }
    </div>
  );
}

export default OrderSingle;

const styles = {
  header: { textTransform: 'uppercase', color:'#149348', marginBottom: 0 },
  box: { background:'#e1e1e1', borderRadius: 10, paddingLeft: 10, paddingRight: 10, padding: 10, flex: 1, boxShadow: '0 0 5px 0 #868686', border: '2px dotted #fff' },
  boxHeading: { color:'#333', fontWeight: '900', fontSize: 15, marginBottom: 0 },
  boxNumber: { color:'green', fontWeight: '900', fontSize: 25, marginBottom: 0 },
};
