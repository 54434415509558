import React, { useEffect, useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import { BsPlusCircleDotted } from "react-icons/bs";
import { BiCategory, BiFilterAlt } from "react-icons/bi";
import Cookies from "js-cookie";
import Loader from "../../component/loader";
import { Modal } from "react-bootstrap";
import { decrpt } from "../../component/encryption";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Pagination from "react-js-pagination";

function Maping () {
  const [loader,setLoader] = useState(false);
  const [show,setShow] = useState(false);
  const [dashmaster , setDashmaster ] = useState();


  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
  };
  const handleOpen = () => {setShow(true)};


  const fetchMatser = async () => {
    setLoader(true);
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.get(`/admin/master/map/index`)
    .then((response) => {
      setDashmaster(response.data.data);
      // console.log(response.data.data);
    setLoader(false);
    })
  }


  useEffect(() => {
    // filterMasterList();
    fetchMatser();
  },[]);

  return(
    <div className="main-content"> 
    {loader && loader ? 
      <Loader /> 
    :
    <>
    <div className="section">
        <div className="row">

          {/* Category & Sub category */}
          <div className="col-md-12 mb-4">
            <div className="section-title">
              <h5><BiCategory className="me-1" />Mapping Masters</h5>
            </div>
            <div className="count-section d-flex gap-3 mt-2">
              {/* count 1 */}
              <div className="count-block d-flex align-items-center justify-content-between no-divider">
                <div  className="count-figure"><h3>{dashmaster && dashmaster.BrandMap}</h3></div>
                <div className="count-content">
                  <h5>Brand Maping</h5>
                  <Link to="./brandMap" className="link-btn">view</Link>
                </div>
              </div>
              {/* count 2 */}
              <div className="count-block d-flex align-items-center justify-content-between no-divider">
                <div  className="count-figure"><h3>{dashmaster && dashmaster.FilterMap}</h3></div>
                <div className="count-content">
                  <h5>Size Maping</h5>
                  <Link to="sizeMap" className="link-btn">view</Link>
                </div>
              </div>
              <div className="count-block d-flex align-items-center justify-content-between no-divider">
                <div  className="count-figure"><h3>{dashmaster && dashmaster.SizeMap}</h3></div>
                <div className="count-content">
                  <h5>Filter Maping</h5>
                  <Link to="filterMap" className="link-btn">view</Link>
                </div>
              </div>
              {/* count 3 */}
            </div>
          </div>
          {/* modal */}
        </div>
      </div>
    </>
    } 
    </div>
  )
}

export default Maping;
