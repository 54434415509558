import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Loader from '../../component/loader';
import { BiCategory, BiFilterAlt } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import Pagination from "react-js-pagination";
import { decrpt } from "../../component/encryption";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SizeMap() {  
  const [loader,setLoader] = useState(false)
  const [show,setShow] = useState(false)
  const [editShow,setEditShow] = useState(false)
  // const [Eshow,setEShow] = useState(false);
  const [errors , setErrors] = useState();
  const [ toastText,setToastText ] = useState('');
  const [paginate , setPaginate] = useState();
  const [statusShow , setStatusShow] = useState();
  const [newUpdateStatus , setNewUpdateStatus] = useState();
//  
const [sizeList , setSizeList ] = useState();
const [sizeMapList , setSizeMapList ] = useState();
const [ category, setCategory ] = useState();
const [ subCategory, setSubCategory ] = useState();
const [ subCategoryList, setSubCategoryList ] = useState();
const [newLastCategory , setNewLastCategory] = useState();
const [lastCategory , setLastCategory] = useState();
const [addLastCategory , setAddLastCategory] = useState();
const [ addNewSize , setAddNewSize ] = useState();
const [ newSize , setNewSize ] = useState({
  id : '',
  name : '',
  status : ''
});
const [ editNewSize , setEditNewSize ] = useState({
  id : '',
  name : '',
  status : ''
});
const editCall = (item) => { 
  setEditShow(true);
  setEditNewSize({
    ...editNewSize,
    id : item.id,
    name : item.name,
    status : item.name,
  })
  console.log(editNewSize , 'subCategory');
}

const handleStatus = (item)=> {
  setStatusShow(true);
  setNewUpdateStatus(item);
}
const handleStatusClose = () =>{
  setStatusShow(false);
}

const handleAddModal = () => {
  setShow(true);
}
const handleCloseEdit = () => {
  setEditShow(false);
  setShow(false);
}
const handleCategory = (category_id) => {
  console.log(category_id , 'category_id');
  setSubCategory({
    ...subCategory,
    category_id : category_id
  })
  getSubCategoryList(category_id);
  console.log(subCategory , 'subCategory check');
}

const handleSubCategory = (sub_category_id) => {
  console.log(sub_category_id , 'sub_category_id');
  setNewLastCategory({
    ...newLastCategory,
    sub_category_id : sub_category_id,
  })
  getLastCategoryList(sub_category_id);
  console.log(newLastCategory , 'handle sub category fetch category');
}

const fetchSizes = async() => {
  setLoader(true);
  try {
    const token = Cookies.get('s_l');
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.get(`/admin/master/size/dropdown/list`).then((response) => {
      setSizeList(response.data.data);
      console.log(response.data.data);
      setLoader(false);
  })
  }catch(error){
    console.log(error , 'fetchSizeList error');
    setLoader(false);
  }
}
const fetchSizeMapList = async() => {
  setLoader(true);
  try {
    const token = Cookies.get('s_l');
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`/admin/master/size/map/list`).then((response) => {
      setSizeMapList(response.data.data.list);
      setPaginate(response.data.data.pagination);
      console.log(response.data.data);
      setLoader(false);
  })
  }catch(error){
    console.log(error , 'fetchSizeList error');
    setLoader(false);
  }
}
const getCategoryList = async() => {
  try {
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`admin/category/list`, {type:1})
    setCategory(response.data.data.list)
  } catch (error) {
    console.error(error);
  }
}

const getLastCategoryList = async(category_id) => {
  const data = {
    type : 3 ,
    category_id : category_id
  }
  console.log(category_id ,'sub_category_id');
  try {
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`admin/category/dropdown/list`, data)
    console.log('Last Level',response.data);
    setLastCategory(response.data.data)
    setPaginate(response.data.data.pagination);
  } catch (error) {
    console.error(error);
  }
}

const getSubCategoryList = async(category_id ) => {
  const data = {
    type : 2,
    category_id : category_id
  }
  try {
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`admin/category/dropdown/list`, data)
    console.log(response.data.data , ' fetch second cat with respct first');
    setSubCategory(response.data.data);
  } catch (error) {
    console.error(error);
  }
}

const addSize = async() => {
  try {
    setLoader(true);
    const data = {
      category_id : addLastCategory.category_id,
      size_id     : addNewSize.id
    }
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`admin/master/size/map/save` , data)
    .then((response) => {
      let newData = response.data.data;
      sizeMapList.unshift(newData);
    })
  setLoader(false);
  setEditShow(false);
  setShow(false);
  setToastText("Size Added")
  toast.success(toastText,{hideProgressBar:true})
  } catch (error) {
    console.error(error , 'error');
    if(error.response.status == 412){
      setErrors(error.response.data.message);
    }else {
      setErrors('Please fill all the inputs');
    }
    setLoader(false);
    setToastText("Something went wrong")
    toast.error(toastText,{hideProgressBar:true})
  }
}
const updateSize = async() => {
  try {
  setLoader(true);
  const data = {
    id  : editNewSize.id ,
    category_id : addLastCategory.category_id,
    size_id     : addNewSize.id
  }
  console.log(data, 'updateSize data');
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    const response = await axios.post(`/admin/master/size/map/save` , data)
    .then((response) => {
      console.log(response.data)
      let newData = response.data.data;
      console.log(newData , 'newData');
      let tmparray = sizeMapList;
      let obj = tmparray.find((o, i) => {
          if (o.id === newData.id) {
            tmparray[i] = {...sizeMapList[i] ,   ...newData  };
            console.log('true',tmparray[i])
              return true; 
          }
      });
    })
    setShow(false);
  setLoader(false);
  setEditShow(false);
  setToastText("Size Updated")
  toast.success(toastText,{hideProgressBar:true})
  } catch (error) {
    console.error(error , 'error');
    if(error.response.status == 412){
      setErrors(error.response.data.message);
    }else {
      setErrors('Please fill all the inputs');
    }
    setErrors(error.response.data.message);
  }
}
const sizeStatus = async() => {
  setLoader(true);
  try {
    let newstatus = '';
    if (newUpdateStatus.status == 1) {
      newstatus = 2;
    } else {
      newstatus = 1;
    }
    const data = {
      id: newUpdateStatus.id,
      status  : newstatus
    }
    console.log(data , 'data of api getc')
    const token = Cookies.get('s_l');  
    const newToken = decrpt(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
    const response = await axios.post(`admin/master/size/map/status` , data)
    .then((response) => {
      let tmparray = sizeMapList;
      let obj = tmparray.find((o, i) => {
          if (o.id === newUpdateStatus.id) {
            tmparray[i] = {...sizeMapList[i] ,   status : newstatus  };
            console.log('true',tmparray[i])
              return true; 
          }
      });
    })
    setStatusShow(false);
    setToastText("Status Updated");
    toast.success(toastText,{hideProgressBar:true})
  setLoader(false);
  }catch(error) {
      console.log(error, 'error');
      setToastText("Something went wrong")
      toast.success(toastText,{hideProgressBar:true})
      setLoader(false);
  }
}

const handlePageChange = async (pageNumber) => {
  setLoader(true);
  console.log(`active page is ${pageNumber}`);
  const token = Cookies.get('s_l');  
  const newToken = decrpt(token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
  const response = await axios.post(`/admin/master/size/map/list?page=${pageNumber}`, {type: 1} )
  .then((response) => {
    // setSizeList(response.data.data.list);
    setLoader(false);
    console.log(response.data);
  }).catch((err) => {
    console.log('seller list error' , err.response)
    setLoader(false);
  })
}

useEffect(() => {
  getCategoryList();
  fetchSizeMapList();
  fetchSizes();
}, []);


  return (
    <div className="main-content">
      {loader ? 
        <Loader />
        :
        <>
      <ToastContainer position="top-center"  autoClose={3000}  hideProgressBar={false}  newestOnTop={false}  closeOnClick  rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'colored'}/>
          {/* New User Registration */}
          <div className="section">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                  <h5><BiCategory className="me-1"  />All brand Size mapping</h5>
                </div>
                <div className="card p-2 mt-3">
                  <div className="card-title">      
                    <h5>You have { sizeList && sizeList.length} Sizes</h5>            
                    <Button  className="badge btn-xs pt-1" onClick={handleAddModal}>Add New Sizes</Button>
                  </div>
                  <div>
                    <table className='table tabe-bordered'>
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Sizes</th>
                                <th>3rd category</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                          {sizeMapList && sizeMapList.map((item ,index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1} </td>
                                <td>{item.size} </td>
                                <td>{item.category} </td>
                                <td className="text-center">
                                  { item &&  item.status == 1 ?
                                  <Badge bg="success">Enable</Badge>:
                                  <Badge bg="danger">Disable</Badge>}
                                </td>
                                <td className="text-center">
                              <button onClick={()=>editCall(item)} className='btn btn-xs btn-primary text-white'>Edit</button> &nbsp;
                                {item && item.status == 1 ? 
                                <button onClick={()=>handleStatus(item)} className='btn btn-xs btn-danger text-white'>Make Disable</button>:
                                <button onClick={()=>handleStatus(item)} className='btn btn-xs btn-success text-white'>Make Enable</button>}    
                                </td>
                              </tr>
                            )
                          } )}
                        </tbody>
                    </table>
                    { paginate && paginate  ?
                    <div className="col-md-12">
                        <Pagination
                        totalItemsCount={ paginate.total_data}
                        onChange={handlePageChange}
                        activePage={  paginate.current_page}
                        itemsCountPerPage={paginate.per_page}
                        // pageRangeDisplayed={10}
                        />
                    </div>
                : <></>
                }
                  </div>
                  <Modal size="md" show={show} >
                      <Modal.Header className="py-1">
                          <Modal.Title className="h6">Add Size map</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                      {errors && errors ? <p style={{color : 'red' , fontSize : '12px'}}>{errors}</p>  : ''}
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Cartegory name</Form.Label>
                            <Form.Select aria-label="Default select example" 
                              onChange = {category => handleCategory(category.target.value)}
                            >
                              <option selected disabled>----select from below----</option>
                            {
                              category  && category.map((item,index) => {
                                  return (
                                    <option key={index} value={item.id}> {item.name} </option>
                                  )
                              })
                            }
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Select Sub Category</Form.Label>
                                <Form.Select aria-label="Default select example"
                                //  onChange={e => subCategoryHandler(e)}
                                 onChange = {sub_category => handleSubCategory(sub_category.target.value)}
                                 >
                                  {subCategory && subCategory.length > 0 ?
                                  <>
                                      <option selected disabled>----select from below</option>
                                    { subCategory.map((item,index) => {
                                      return <option key={index}  value={item.id}>{item.name}</option>
                                    })}
                                  </>:
                                  <>
                                    <option disabled>Select Category First</option>
                                  </>
                                  }
                                </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Select Last Category</Form.Label>
                                <Form.Select aria-label="Default select example"
                                //  onChange={e => subCategoryHandler(e)}
                                 onChange = {e => setAddLastCategory({...addLastCategory ,  category_id:e.target.value})}
                                 >
                                  {lastCategory && lastCategory.length > 0 ?
                                  <>
                                      <option selected disabled>----select from below</option>
                                    { lastCategory.map((item,index) => {
                                      return <option key={index}  value={item.id}>{item.name}</option>
                                    })}
                                  </>:
                                  <>
                                    <option disabled>Select Category First</option>
                                  </>
                                  }
                                </Form.Select>
                               
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Select Size </Form.Label>
                                <Form.Select aria-label="Default select example"
                                //  onChange={e => subCategoryHandler(e)}
                                 onChange = {e => setAddNewSize({...addNewSize ,  id:e.target.value})}
                                 >
                                  {sizeList && sizeList.length > 0 ?
                                  <>
                                      <option selected disabled>----select from below</option>
                                    { sizeList.map((item,index) => {
                                      return <option key={index}  value={item.id}>{item.name}</option>
                                    })}
                                  </>:
                                  <>
                                    <option disabled>Select Category First</option>
                                  </>
                                  }
                                </Form.Select>
                          </Form.Group>

                      </Modal.Body>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button  className="btn btn-light btn-sm" onClick={handleCloseEdit}>
                              Close
                          </button>
                          <button  className="btn btn-primary btn-sm" onClick={() => addSize()}> submit</button>
                      </Modal.Footer>
                  </Modal> 
                  <Modal size="md" show={editShow} >
                      <Modal.Header className="py-1">
                          <Modal.Title className="h6">Edit Size map</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Edit Cartegory name</Form.Label>
                            <Form.Select aria-label="Default select example" 
                              onChange = {category => handleCategory(category.target.value)}
                            >
                              <option selected disabled>----select from below----</option>
                            {
                              category  && category.map((item,index) => {
                                  return (
                                    <option key={index} value={item.id}> {item.name} </option>
                                  )
                              })
                            }
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Select Sub Category</Form.Label>
                                <Form.Select aria-label="Default select example"
                                //  onChange={e => subCategoryHandler(e)}
                                 onChange = {sub_category => handleSubCategory(sub_category.target.value)}
                                 >
                                  {subCategory && subCategory.length > 0 ?
                                  <>
                                      <option selected disabled>----select from below</option>
                                    { subCategory.map((item,index) => {
                                      return <option key={index}  value={item.id}>{item.name}</option>
                                    })}
                                  </>:
                                  <>
                                    <option disabled>Select Category First</option>
                                  </>
                                  }
                                </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Select Last Category</Form.Label>
                                <Form.Select aria-label="Default select example"
                                //  onChange={e => subCategoryHandler(e)}
                                 onChange = {e => setAddLastCategory({...addLastCategory ,  category_id:e.target.value})}
                                 >
                                  {lastCategory && lastCategory.length > 0 ?
                                  <>
                                      <option selected disabled>----select from below</option>
                                    { lastCategory.map((item,index) => {
                                      return <option key={index}  value={item.id}>{item.name}</option>
                                    })}
                                  </>:
                                  <>
                                    <option disabled>Select Category First</option>
                                  </>
                                  }
                                </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Select Last Category</Form.Label>
                                <Form.Select aria-label="Default select example"
                                //  onChange={e => subCategoryHandler(e)}
                                 onChange = {e => setAddNewSize({...addNewSize ,  id:e.target.value})}
                                 >
                                  {sizeList && sizeList.length > 0 ?
                                  <>
                                      <option selected disabled>----select from below</option>
                                    { sizeList.map((item,index) => {
                                      return <option key={index}  value={item.id}>{item.name}</option>
                                    })}
                                  </>:
                                  <>
                                    <option disabled>Select Category First</option>
                                  </>
                                  }
                                </Form.Select>
                          </Form.Group>
                      </Modal.Body>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button  className="btn btn-light btn-sm" onClick={handleCloseEdit}>
                              Close
                          </button>
                          <button  className="btn btn-primary btn-sm" onClick={() => updateSize()} > submit</button>
                      </Modal.Footer>
                  </Modal> 

                  <Modal size="md" show={statusShow}  centered>
                      <Modal.Header>
                          <Modal.Title className="h6 fw-bolder">Do you want to  {
                          newUpdateStatus && newUpdateStatus.status == 1 ?  'Disable' : 'Enable'
                        }</Modal.Title>
                      </Modal.Header>
                      <Modal.Footer className="p-1 justify-content-start">
                          <button onClick={()=>handleStatusClose()} className="btn btn-light btn-sm">
                              Close
                          </button>
                          <button onClick={()=>sizeStatus()} className="btn btn-primary btn-sm">
                          {newUpdateStatus && newUpdateStatus.status == 1 ?  'Make Disable' : 'Make Enable'}
                          </button>
                      </Modal.Footer>
                  </Modal> 
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default SizeMap;
