import React, { useEffect, useState } from "react";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { decrpt } from "../../component/encryption";
import axios from "axios";
import Cookies from "js-cookie";
import { FormControl, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Loader from "../../component/loader";
// import baseURL from "/src/"

function SettingSlider() {  
  const [loader,setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [error , setError ] = useState();
  const [addShow, setAddShow] = useState(false);
  const [sliderList , setSliderList] = useState();
  const [sliderPreview , setSliderPreview] = useState();
  const [newSliderPreview , setNewSliderPreview] = useState();
  console.log(sliderList , 'sliderPreview');
  const [ addSlider , setAddSlider] = useState();
  const [slider , setSlider ] = useState({
    id  :  '',
    image :  '',
    heading : '',
    type : 1
  });
  console.log(slider , 'check slider');
  const [sliderSelected , setSliderSelected ] = useState({
    id  :  '',
    image :  '',
    heading : '',
    type : 1
  });
  // console.log(slider, 'slider data');
  // console.log(sliderSelected, 'sliderSelected');
  const handleClose = () => {
    setAddShow(false);
  }


const sliderUpdate = async (e,baseURL) => {
  e.preventDefault();
  setLoader(true);
  try {
    const formData = new FormData();
    formData.append("id", sliderSelected && sliderSelected.id);
    formData.append("image", sliderSelected && sliderSelected.image);
    formData.append("heading", sliderSelected && sliderSelected.heading  ? sliderSelected.heading : '');
    formData.append("type" , 1 )
    for (const value of formData.values()) {
      console.log(value , 'check valuye');
    }
      const token = Cookies.get('s_l');  
      const newToken = decrpt(token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      // const response = await axios({
      //    method : 'POST', 
      //     url : `/admin/slider/save` ,
      //     data :  formData})
       const response = await axios.post(`/admin/slider/save` , formData)
       .then((response) => {
        setSlider( response.data.data , 'setslider');
        console.log(response.data.data,'data');
        // let newdata = response.data.data;
        let tmparrays = sliderList;
        let objs = tmparrays.find((o, i) => {
            if (o.id === slider.id) {
              tmparrays[i] = {...sliderList[i] ,  ...slider};
                setLoader(false);
                return true; 
              }
            });
        setLoader(false);
        setShow(false);
      })
  }catch (error) {
    console.log(error , 'check');
    setError(error.response.data.errors)
    setLoader(false);
    setShow(true);
  }
}


//   {
      //     header : {
      //       "Access-Control-Allow-Origin" : axios.defaults.baseURL,
      //       "Content-Type" : "application/json"
      //     }
      //   }
      // )


      
const slideradd = async (e) => {
  e.preventDefault();
  setLoader(true);
  try {
    const formData = new FormData();
    // formData.append("id", addSlider.id);
    formData.append("image", addSlider.image);
    formData.append("heading", addSlider.heading ? addSlider.heading : '' );
    formData.append("type", 1 );
    console.log(`slider update data`, formData );
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/admin/slider/save` , formData)
      .then((response) => {
        let newdata = response.data.data.image;
        if(response.data.success == true ) {
          fetchSliderList();
        }
        setAddShow(false);
          console.log(sliderList , 'sliderlist');
        setLoader(false);
        setShow(false);
      })
  }catch (error) {
    console.log(error);
    setLoader(false);
    setShow(false);
  }
}

const sliderStatusUpdate = async(item) => {
  setLoader(true);
  let newstatus = '';
  if (item.status == 1) {
    newstatus = 2;
  } else {
    newstatus = 1;
  }
  const data = {
    id: item.id,
    status : newstatus
  }
  try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/admin/slider/status` , data).then((response) => {
        console.log(response.data, 'update status');
        let tmparray = sliderList;
        let obj = tmparray.find((o, i) => {
            if (o.id === item.id) {
                tmparray[i] = {...sliderList[i] ,   status : newstatus  };
                setLoader(false);
                return true; 
            }
        });
      })
  }catch (error) {
    setLoader(false);
  }
}

const fetchSliderList = async () => {
  setLoader(true);
  try {
    // const formData = new FormData();
    // formData.append("image", slider.image);
    // formData.append("heading", slider.heading);
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/admin/slider/list` , {status : 1})
      .then((response) => {
        console.log(`slider list data`, response.data );
        setSliderList(response.data.data);
      })
  setLoader(false);
  }catch (error) {
    console.log(error);
  }
}
const handleEdit = (item) => {
  setShow(true);
  setSlider(item)
  setSliderSelected({
    id  :  item.id,
    image :  item.image,
    heading : item.heading
  })
  setSliderPreview(item)
  console.log(item , 'seller item');
};

function handlePreview(e) {
  setSliderPreview({...sliderPreview , image:URL.createObjectURL(e.target.files[0])});
  setSliderSelected({...sliderSelected, image:e.target.files[0]});
  }

  function handlePreviewadd(e) {
    setNewSliderPreview({...newSliderPreview , image:URL.createObjectURL(e.target.files[0])});
    setAddSlider({...addSlider , image:e.target.files[0]});
  }

  useEffect (() => {
    window.scrollTo(0, 0);
    fetchSliderList(); 
    // setLoader(true);
    // setTimeout(() => {      
    //   setLoader(false);
    // }, 500);
  }, []);
//   useEffect(() => {
//     setSliderList({
//       ...slider
//     })
// },[]);
  return (
    <div className="main-content">
      {loader ? 
       <Loader /> 
       :
       <>
      <div className="section">
        <div className="card p-2">
          <div className="card-title d-flex align-items-center justify-content-between">
            <h5>Website Slider <small className="text-danger">(4 slider current)</small></h5>
            {
              sliderList && sliderList.length == 4 ? '' :
              <button   className="btn btn-xs btn-primary " onClick={() =>   setAddShow(true)}><AiFillEdit />Add New </button>
            }
          </div>
          <div className="row">
            {  sliderList && sliderList.map((item,index) => {
              return (
                <div  key={index} className="col-md-3 my-2">
                <div>
                  <div style={{height : 150 , overflow : 'hidden'}}>
                    <img src={item && item.image}  />
                  </div>
                  <p>{item.heading}</p>
                  <div className="d-flex justify-content-center pt-2 gap-1">
                    {
                      item && item.status == 1 ? <button className="btn btn-xs btn-success" onClick={() => sliderStatusUpdate(item)} >Make Enable</button> :
                      <button className="btn btn-xs btn-danger" onClick={() => sliderStatusUpdate(item)} >Make Disable</button>
                    }
                    <button className="btn btn-xs btn-primary" onClick={() => handleEdit(item)}><AiFillEdit /></button>
                    {/* <button className="btn btn-xs btn-danger"><AiFillDelete /></button> */}
                  </div>
                </div>
              </div>
              );
            })
            }
          </div>
        </div>
      </div>
          <Modal  show={show} onHide={() => setShow(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Slider Update </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form  method="POST" onSubmit={sliderUpdate}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Image Caption</Form.Label>
                           <FormControl type="text" 
                            defaultValue={sliderSelected && sliderSelected.heading } 
                            onChange={e => setSliderSelected({...sliderSelected , heading:e.target.value}) } placeholder="type your image heading" 
                            />
                            {error && error }
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Image upload</Form.Label>
                           {/* <Form.Control type="file"   onChange={e => setSlider({...slider, image:e.target.files[0]})  } /> */}
                           <Form.Control type="file" onChange={handlePreview} />
                        </Form.Group>
                        <div className="d-flex w-100 align-items-center ">
                          <div>
                            <Button className="saveChangesBtn  h-100" type="submit">Save Changes</Button>
                          </div>
                          <div className="ms-auto">
                            <img src={sliderPreview && sliderPreview.image !== '' ? sliderPreview.image: '/folder.png'} style={{ width: 100,height: 100 }}/>
                        </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
          <Modal  show={addShow} onHide={() => setShow(false)} >
                <Modal.Header >
                    <Modal.Title>Add New Slider  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form  method="POST" onSubmit={slideradd}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Image Caption</Form.Label>
                           <FormControl type="text" 
                            defaultValue={sliderSelected && sliderSelected.heading } 
                            onChange={e => setAddSlider({...addSlider , heading:e.target.value}) } 
                            placeholder="type your image heading" 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Image upload</Form.Label>
                           {/* <Form.Control type="file"   onChange={e => setSlider({...slider, image:e.target.files[0]})  } /> */}
                           <Form.Control type="file" onChange={handlePreviewadd} />
                        </Form.Group>
                        <div className="d-flex w-100 align-items-center ">
                          <div>
                            <Button type="button" onClick={()=> handleClose()} className="btn-light me-1  h-100">Close</Button>
                          </div>
                          <div>
                            <Button className="saveChangesBtn  h-100" type="submit">Save Changes</Button>
                          </div>
                          <div className="ms-auto">
                            <img src={newSliderPreview && newSliderPreview.image !== '' ? newSliderPreview.image: '/folder.png'} style={{ width: 100,height: 100 }}/>
                        </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            </>
      }
    </div>

  );
}

export default SettingSlider;
const styles = {
}
